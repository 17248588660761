<template>
  <HeadlessTransitionRoot appear as="template" :show="dialog">
    <HeadlessDialog
      static
      class="fixed inset-0 z-[9999] w-screen overflow-y-auto"
      @keydown.esc="closeDialog"
    >
      <div
        class="flex h-full items-center justify-center lg:h-auto lg:min-h-screen"
      >
        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          leave="duration-200 ease-in"
          leave-to="opacity-0"
        >
          <div
            :class="[
              'fixed inset-0 hidden bg-[#777777]/50 backdrop-blur-lg lg:block',
            ]"
            @click="closeDialog"
          />
        </HeadlessTransitionChild>

        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-in lg:ease-out"
          enter-from="translate-y-full lg:translate-y-0 lg:opacity-0 lg:scale-95"
          leave="duration-200 ease-out lg:ease-in"
          leave-to="translate-y-full lg:translate-y-0 lg:opacity-0 lg:scale-95"
        >
          <HeadlessDialogPanel
            class="lg:shadow-small relative mx-auto h-full w-full overflow-y-auto bg-white p-6 lg:my-6 lg:rounded-2xl lg:p-8"
            :class="isFormSubmitted ? 'lg:max-w-[477px]' : 'lg:max-w-[697px]'"
          >
            <div
              class="relative space-y-6"
              :class="{ 'flex h-full flex-col': isFormSubmitted || isNotified }"
            >
              <div class="flex items-center justify-between gap-x-3">
                <HeadlessDialogTitle
                  as="div"
                  class="text-1 lg:text-heading-2 font-semibold"
                >
                  <span v-if="isFormSubmitted" />
                  <span v-else-if="isNotified"
                    >Вы уже подписаны на&nbsp;товар</span
                  >
                  <span v-else> Сообщить о&nbsp;поступлении&nbsp;товара </span>
                </HeadlessDialogTitle>

                <Btn
                  class="shadow-container flex h-11 w-11 shrink-0 items-center justify-center rounded-xl text-gray-500 hover:text-gray-900"
                  tabindex="-1"
                  @click="closeDialog"
                >
                  <Icon name="close" class="h-6 w-6" />
                </Btn>
              </div>

              <div v-if="isFormSubmitted" class="flex grow flex-col space-y-6">
                <div
                  class="flex grow flex-col items-center justify-center space-y-3.5 text-center"
                >
                  <svg
                    width="82"
                    height="82"
                    viewBox="0 0 82 82"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-primary"
                  >
                    <rect
                      x="13.6667"
                      y="20.5"
                      width="54.6667"
                      height="41"
                      rx="6.83333"
                      stroke="currentColor"
                      stroke-width="6.83333"
                    />
                    <path
                      d="M13.6667 30.75L37.9441 42.8887C39.8678 43.8506 42.1322 43.8506 44.056 42.8887L68.3334 30.75"
                      stroke="currentColor"
                      stroke-width="6.83333"
                    />
                  </svg>

                  <div class="text-heading-4 text-center font-medium">
                    Мы пришлем вам сообщение, когда товар появится у нас в
                    наличии.
                  </div>
                </div>

                <div class="pt-8">
                  <Btn primary lg class="w-full" @click="closeDialog">
                    Закрыть
                  </Btn>
                </div>
              </div>

              <div v-else-if="isNotified" class="flex grow flex-col">
                <div class="text-4 lg:text-1">
                  Мы сообщим о его наличии при поступлении
                </div>

                <div class="grow">
                  <ProductNotifyCard
                    :product="product"
                    :hide-product-navigate="hideProductNavigate"
                    class="mt-13"
                  />
                </div>

                <Btn primary lg class="mt-6 w-full" @click="closeDialog">
                  Продолжить
                </Btn>
              </div>

              <div v-else class="flex flex-col space-y-6">
                <ProductNotifyCard
                  :product="product"
                  :hide-product-navigate="hideProductNavigate"
                />

                <hr class="border-gray-100" />

                <ProductNotifyForm
                  v-model:loading="formLoading"
                  :product-id="product?.id"
                  @submitted="onSubmitted"
                />
              </div>
            </div>
          </HeadlessDialogPanel>
        </HeadlessTransitionChild>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>

  <slot
    name="activator"
    v-bind="{
      buttonAttrs: { onClick: toggleDialog, disabled: formLoading },
      loading: formLoading,
      isNotified,
    }"
  />
</template>

<script setup lang="ts">
import type { Product, ProductCatalog } from '@/openapi_fetch'

const props = defineProps<{
  modelValue?: boolean
  product?: Product | ProductCatalog
  hideProductNavigate?: boolean
}>()
const emit = defineEmits(['update:modelValue'])

const dialog = useVModel(props, 'modelValue', emit, { passive: true })

const profileStore = useProfileStore()

const isFormSubmitted = ref(false)
const formLoading = ref(false)

const { data: feedbackProductsIdsData, refresh: feedbackProductsIdsRefresh } =
  useLazyAsyncData<number[]>(
    `/feedback/stock-product/${props.product?.id ?? 'product-id-null'}`,
    // TODO заменить на запрос апи по готовности
    () => Promise.resolve([]),
    {
      server: false,
    },
  )

const isNotified = computed(() =>
  Boolean(
    feedbackProductsIdsData.value?.find((id) => props.product?.id === id),
  ),
)

watch(dialog, (val) => {
  if (val) {
    isFormSubmitted.value = false
  }
})

if (
  profileStore.hasDeliveryAddress &&
  profileStore.productIdNotifyDialogOpener === props.product?.id
) {
  dialog.value = true
  profileStore.productIdNotifyDialogOpener = null
}

async function toggleDialog() {
  if (!profileStore.hasDeliveryAddress) {
    profileStore.deliveryAddressSelectDialogOpener = true
    // запуск промиса закрытия модального окна адреса доставки
    await profileStore.waitDeliveryAddressSelectDialogClose()
    // после закрытия модального окна адреса доставки проверка адрес доставки
    if (!profileStore.hasDeliveryAddress) {
      return
    }
    profileStore.productIdNotifyDialogOpener = props.product?.id
  } else {
    dialog.value = !dialog.value
  }
}

function closeDialog() {
  dialog.value = false
}

function onSubmitted() {
  isFormSubmitted.value = true
  feedbackProductsIdsRefresh()
}
</script>
