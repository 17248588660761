export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()
  const { SeoApi } = useOpenApi()

  const url = trailingSlash(to.path)

  // ошибка запроса должна быть проигнорирована
  try {
    await useAsyncData(
      `/data/seo/seo-tag/?url=${url}`,
      () => SeoApi.seoSeoTagList({ url }),
      {
        getCachedData(key) {
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
      },
    )
  } catch (error) {} // eslint-disable-line no-empty
})
