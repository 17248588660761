/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `minutes` - Минуты
 * * `seconds` - Секунды
 * @export
 */
export const PeriodEnum = {
    Minutes: 'minutes',
    Seconds: 'seconds'
} as const;
export type PeriodEnum = typeof PeriodEnum[keyof typeof PeriodEnum];


export function PeriodEnumFromJSON(json: any): PeriodEnum {
    return PeriodEnumFromJSONTyped(json, false);
}

export function PeriodEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodEnum {
    return json as PeriodEnum;
}

export function PeriodEnumToJSON(value?: PeriodEnum | null): any {
    return value as any;
}

