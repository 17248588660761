/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SameProduct } from './SameProduct';
import {
    SameProductFromJSON,
    SameProductFromJSONTyped,
    SameProductToJSON,
} from './SameProduct';

/**
 * 
 * @export
 * @interface CharacteristicSameProduct
 */
export interface CharacteristicSameProduct {
    /**
     * 
     * @type {string}
     * @memberof CharacteristicSameProduct
     */
    name: string;
    /**
     * 
     * @type {Array<SameProduct>}
     * @memberof CharacteristicSameProduct
     */
    products: Array<SameProduct>;
}

/**
 * Check if a given object implements the CharacteristicSameProduct interface.
 */
export function instanceOfCharacteristicSameProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "products" in value;

    return isInstance;
}

export function CharacteristicSameProductFromJSON(json: any): CharacteristicSameProduct {
    return CharacteristicSameProductFromJSONTyped(json, false);
}

export function CharacteristicSameProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharacteristicSameProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'products': ((json['products'] as Array<any>).map(SameProductFromJSON)),
    };
}

export function CharacteristicSameProductToJSON(value?: CharacteristicSameProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'products': ((value.products as Array<any>).map(SameProductToJSON)),
    };
}

