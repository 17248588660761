/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Reviewer
 */
export interface Reviewer {
    /**
     * 
     * @type {number}
     * @memberof Reviewer
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof Reviewer
     */
    userAvatar: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reviewer
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof Reviewer
     */
    lastName: string | null;
}

/**
 * Check if a given object implements the Reviewer interface.
 */
export function instanceOfReviewer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userAvatar" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function ReviewerFromJSON(json: any): Reviewer {
    return ReviewerFromJSONTyped(json, false);
}

export function ReviewerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reviewer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'userAvatar': json['user_avatar'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
    };
}

export function ReviewerToJSON(value?: Reviewer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'user_avatar': value.userAvatar,
        'first_name': value.firstName,
        'last_name': value.lastName,
    };
}

