<template>
  <div :class="['relative flex-1', { 'rounded-[9px] bg-blue-50': disabled }]">
    <HeadlessCombobox v-slot="{ open }" v-model="selectedItem">
      <slot name="label" />
      <HeadlessComboboxInput
        ref="inputRef"
        :display-value="(item) => (item as DeliveryAddress)?.address || ''"
        :class="[
          'input input_outlined input_error h-[42px] bg-transparent text-[#2C3D57] focus:bg-transparent',
          { 'lg:h-[52px]': lg },
          inputClass,
        ]"
        :required="required"
        :placeholder="placeholder"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        @focus="emit('focus', $event)"
        @blur="emit('blur', $event)"
        @change="query = $event.target.value"
        @keydown.enter="open ? select() : undefined"
      />
      <HeadlessComboboxOptions
        :class="[
          'absolute inset-x-0 z-50',
          'mt-2 list-none rounded-xl bg-white',
          'text-4 text-general font-medium',
          'overflow-y-auto shadow transition-shadow',
          { 'py-2.5': items.length > 0 },
        ]"
        :style="{ maxHeight: optionsMaxHeight }"
      >
        <HeadlessComboboxOption
          v-for="(item, i) in items"
          :key="i"
          v-slot="{ active, selected }"
          as="template"
          :value="item"
        >
          <li
            :class="[
              'text-secondary hover:text-primary-hover flex min-h-[30px] cursor-pointer items-center px-4 py-1',
              { 'bg-primary-50': active, 'text-primary': selected },
            ]"
            @click="select"
          >
            <div :class="{ truncate: optionsTruncate }" :title="item.address">
              {{ item.address }}
            </div>
          </li>
        </HeadlessComboboxOption>
      </HeadlessComboboxOptions>
    </HeadlessCombobox>
    <div
      v-if="checkAddressCompleted && !isCompleted && isFirstSelect"
      class="mt-1 text-sm"
    >
      Укажите адрес до дома, чтобы продолжить
    </div>

    <div
      v-if="searchLoading || loading"
      :class="[
        'absolute right-2 flex items-center',
        lg ? 'bottom-4.5' : 'bottom-[13px]',
      ]"
    >
      <Loading />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue'
import type { DeliveryAddress, DeliveryAddressRequest } from '@/openapi_fetch'

const props = withDefaults(
  defineProps<{
    modelValue?: DeliveryAddress | DeliveryAddressRequest | null
    lg?: boolean
    disabled?: boolean
    required?: boolean
    placeholder?: string
    debounce?: number
    inputClass?: string
    dadataParams?: Record<string, any>
    checkAddressCompleted?: boolean
    completed?: boolean
    optionsTruncate?: boolean
    optionsMaxHeight?: string
    loading?: boolean
  }>(),
  {
    debounce: 250,
    completed: false,
    checkAddressCompleted: true,
  },
)

const emit = defineEmits([
  'focus',
  'blur',
  'update:modelValue',
  'update:completed',
])

const query = ref('')
const searchLoading = ref(false)
const items = ref<DeliveryAddressRequest[]>([])
const inputRef = ref<ComponentPublicInstance>()
const selectedItem = ref(props.modelValue)
const isCompleted = ref(props.completed)
const isFirstSelect = ref(false)

const debouncedSearch = useDebounceFn(search, props.debounce)
const { DeliveryApi } = useOpenApi()

watch(
  () => props.completed,
  (val) => {
    isCompleted.value = val
  },
)

watch(isCompleted, (val) => {
  emit('update:completed', val)
})

watch(query, (val) => {
  const v = selectedItem.value?.address
  if (val === v) {
    return
  }
  debouncedSearch()
})

watch(
  () => props.modelValue,
  (val) => {
    selectedItem.value = val
  },
)

async function search() {
  try {
    if (!query.value) {
      items.value = []
      return
    }

    searchLoading.value = true

    items.value = await DeliveryApi.deliverySuggestionsAddressList(
      Object.assign({ query: query.value }, props.dadataParams),
    )
  } catch (error) {
    items.value = []
  } finally {
    searchLoading.value = false
  }
}

function select() {
  isFirstSelect.value = true

  nextTick(() => {
    const data = selectedItem.value
      ? {
          ...selectedItem.value,
          id: (props.modelValue as DeliveryAddress)?.id,
        }
      : selectedItem.value

    emit('update:modelValue', data)

    isCompleted.value = !!(
      selectedItem.value?.house || selectedItem.value?.stead
    )
  })
}

function focus() {
  inputRef.value?.$el?.focus()
}

function blur() {
  inputRef.value?.$el?.blur()
}

function reset() {
  isFirstSelect.value = false
  isCompleted.value = false
  selectedItem.value = props.modelValue
}

defineExpose({
  focus,
  blur,
  reset,
})
</script>
