/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestReview,
  PageNotFound,
  PaginatedReviewList,
  Review,
  ReviewRatingParams,
} from '../models';
import {
    BadRequestReviewFromJSON,
    BadRequestReviewToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PaginatedReviewListFromJSON,
    PaginatedReviewListToJSON,
    ReviewFromJSON,
    ReviewToJSON,
    ReviewRatingParamsFromJSON,
    ReviewRatingParamsToJSON,
} from '../models';

export interface ReviewsCreateRequest {
    productId: number;
    siteId?: number;
    eatability?: number;
    taste?: number;
    packageQuality?: number;
    petGameInvolvementRate?: number;
    strengthRate?: number;
    designRate?: number;
    easeOfUseRate?: number;
    materialQualityRate?: number;
    materialFittingsQualityRate?: number;
    petUseComfortRate?: number;
    text?: string | null;
    approved?: boolean;
    images?: Array<Blob>;
    videos?: Array<Blob>;
}

export interface ReviewsDestroyRequest {
    id: number;
}

export interface ReviewsListRequest {
    page?: number;
    pageSize?: number;
}

export interface ReviewsProductListRequest {
    productId: number;
    page?: number;
    pageSize?: number;
}

export interface ReviewsProductRatingRetrieveRequest {
    productId: number;
}

export interface ReviewsRemoveImageDestroyRequest {
    integrationId: string;
}

export interface ReviewsRemoveVideoDestroyRequest {
    integrationId: string;
}

export interface ReviewsRetrieveRequest {
    id: number;
}

export interface ReviewsUpdateRequest {
    id: number;
    productId: number;
    siteId?: number;
    eatability?: number;
    taste?: number;
    packageQuality?: number;
    petGameInvolvementRate?: number;
    strengthRate?: number;
    designRate?: number;
    easeOfUseRate?: number;
    materialQualityRate?: number;
    materialFittingsQualityRate?: number;
    petUseComfortRate?: number;
    text?: string | null;
    approved?: boolean;
    images?: Array<Blob>;
    videos?: Array<Blob>;
}

/**
 * 
 */
export class ReviewsApi extends runtime.BaseAPI {

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на создание сущности (отзыв).
     */
    async reviewsCreateRaw(requestParameters: ReviewsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling reviewsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.siteId !== undefined) {
            formParams.append('site_id', requestParameters.siteId as any);
        }

        if (requestParameters.productId !== undefined) {
            formParams.append('product_id', requestParameters.productId as any);
        }

        if (requestParameters.eatability !== undefined) {
            formParams.append('eatability', requestParameters.eatability as any);
        }

        if (requestParameters.taste !== undefined) {
            formParams.append('taste', requestParameters.taste as any);
        }

        if (requestParameters.packageQuality !== undefined) {
            formParams.append('package_quality', requestParameters.packageQuality as any);
        }

        if (requestParameters.petGameInvolvementRate !== undefined) {
            formParams.append('pet_game_involvement_rate', requestParameters.petGameInvolvementRate as any);
        }

        if (requestParameters.strengthRate !== undefined) {
            formParams.append('strength_rate', requestParameters.strengthRate as any);
        }

        if (requestParameters.designRate !== undefined) {
            formParams.append('design_rate', requestParameters.designRate as any);
        }

        if (requestParameters.easeOfUseRate !== undefined) {
            formParams.append('ease_of_use_rate', requestParameters.easeOfUseRate as any);
        }

        if (requestParameters.materialQualityRate !== undefined) {
            formParams.append('material_quality_rate', requestParameters.materialQualityRate as any);
        }

        if (requestParameters.materialFittingsQualityRate !== undefined) {
            formParams.append('material_fittings_quality_rate', requestParameters.materialFittingsQualityRate as any);
        }

        if (requestParameters.petUseComfortRate !== undefined) {
            formParams.append('pet_use_comfort_rate', requestParameters.petUseComfortRate as any);
        }

        if (requestParameters.text !== undefined) {
            formParams.append('text', requestParameters.text as any);
        }

        if (requestParameters.approved !== undefined) {
            formParams.append('approved', requestParameters.approved as any);
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        if (requestParameters.videos) {
            requestParameters.videos.forEach((element) => {
                formParams.append('videos', element as any);
            })
        }

        const response = await this.request({
            path: `/data/reviews/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на создание сущности (отзыв).
     */
    async reviewsCreate(requestParameters: ReviewsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
          try{
        const response = await this.reviewsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     *  Удалить отзыв и связанные вложения из БД и из бакета. fixme высокая когнетивная сложность
     * Запрос на удаление сущности (отзыв).
     */
    async reviewsDestroyRaw(requestParameters: ReviewsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reviewsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     *  Удалить отзыв и связанные вложения из БД и из бакета. fixme высокая когнетивная сложность
     * Запрос на удаление сущности (отзыв).
     */
    async reviewsDestroy(requestParameters: ReviewsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reviewsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на получение списка сущностей (отзыв).
     */
    async reviewsListRaw(requestParameters: ReviewsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedReviewList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedReviewListFromJSON(jsonValue));
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на получение списка сущностей (отзыв).
     */
    async reviewsList(requestParameters: ReviewsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedReviewList> {
          try{
        const response = await this.reviewsListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на получение отзывов конкретного товара.
     */
    async reviewsProductListRaw(requestParameters: ReviewsProductListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedReviewList>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling reviewsProductList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/product/{product_id}/`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedReviewListFromJSON(jsonValue));
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на получение отзывов конкретного товара.
     */
    async reviewsProductList(requestParameters: ReviewsProductListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedReviewList> {
          try{
        const response = await this.reviewsProductListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдает отзывы пользователей на товары
     */
    async reviewsProductRatingRetrieveRaw(requestParameters: ReviewsProductRatingRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewRatingParams>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling reviewsProductRatingRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/product/{product_id}/rating/`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewRatingParamsFromJSON(jsonValue));
    }

    /**
     * Отдает отзывы пользователей на товары
     */
    async reviewsProductRatingRetrieve(requestParameters: ReviewsProductRatingRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewRatingParams> {
          try{
        const response = await this.reviewsProductRatingRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Метод удаляет изображение
     * Запрос на удаление картинки.
     */
    async reviewsRemoveImageDestroyRaw(requestParameters: ReviewsRemoveImageDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
            throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling reviewsRemoveImageDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/remove-image/{integration_id}/`.replace(`{${"integration_id"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод удаляет изображение
     * Запрос на удаление картинки.
     */
    async reviewsRemoveImageDestroy(requestParameters: ReviewsRemoveImageDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reviewsRemoveImageDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Метод удаляет видео
     * Запрос на удаление видео.
     */
    async reviewsRemoveVideoDestroyRaw(requestParameters: ReviewsRemoveVideoDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
            throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling reviewsRemoveVideoDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/remove-video/{integration_id}/`.replace(`{${"integration_id"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод удаляет видео
     * Запрос на удаление видео.
     */
    async reviewsRemoveVideoDestroy(requestParameters: ReviewsRemoveVideoDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reviewsRemoveVideoDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на получение экземпляра сущности (отзыв).
     */
    async reviewsRetrieveRaw(requestParameters: ReviewsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reviewsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/reviews/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на получение экземпляра сущности (отзыв).
     */
    async reviewsRetrieve(requestParameters: ReviewsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
          try{
        const response = await this.reviewsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на обновление сущности (отзыв).
     */
    async reviewsUpdateRaw(requestParameters: ReviewsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling reviewsUpdate.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling reviewsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.siteId !== undefined) {
            formParams.append('site_id', requestParameters.siteId as any);
        }

        if (requestParameters.productId !== undefined) {
            formParams.append('product_id', requestParameters.productId as any);
        }

        if (requestParameters.eatability !== undefined) {
            formParams.append('eatability', requestParameters.eatability as any);
        }

        if (requestParameters.taste !== undefined) {
            formParams.append('taste', requestParameters.taste as any);
        }

        if (requestParameters.packageQuality !== undefined) {
            formParams.append('package_quality', requestParameters.packageQuality as any);
        }

        if (requestParameters.petGameInvolvementRate !== undefined) {
            formParams.append('pet_game_involvement_rate', requestParameters.petGameInvolvementRate as any);
        }

        if (requestParameters.strengthRate !== undefined) {
            formParams.append('strength_rate', requestParameters.strengthRate as any);
        }

        if (requestParameters.designRate !== undefined) {
            formParams.append('design_rate', requestParameters.designRate as any);
        }

        if (requestParameters.easeOfUseRate !== undefined) {
            formParams.append('ease_of_use_rate', requestParameters.easeOfUseRate as any);
        }

        if (requestParameters.materialQualityRate !== undefined) {
            formParams.append('material_quality_rate', requestParameters.materialQualityRate as any);
        }

        if (requestParameters.materialFittingsQualityRate !== undefined) {
            formParams.append('material_fittings_quality_rate', requestParameters.materialFittingsQualityRate as any);
        }

        if (requestParameters.petUseComfortRate !== undefined) {
            formParams.append('pet_use_comfort_rate', requestParameters.petUseComfortRate as any);
        }

        if (requestParameters.text !== undefined) {
            formParams.append('text', requestParameters.text as any);
        }

        if (requestParameters.approved !== undefined) {
            formParams.append('approved', requestParameters.approved as any);
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        if (requestParameters.videos) {
            requestParameters.videos.forEach((element) => {
                formParams.append('videos', element as any);
            })
        }

        const response = await this.request({
            path: `/data/reviews/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Отдает отзывы пользователей на товары
     * Запрос на обновление сущности (отзыв).
     */
    async reviewsUpdate(requestParameters: ReviewsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
          try{
        const response = await this.reviewsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
