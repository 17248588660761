/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthRequest,
  CasAuthRequest,
  CasExtendTokensRequest,
  CasTicketTokenRequest,
  CasTokensResponse,
  CheckCasAuth,
  RegisterRequest,
  Status,
} from '../models';
import {
    AuthRequestFromJSON,
    AuthRequestToJSON,
    CasAuthRequestFromJSON,
    CasAuthRequestToJSON,
    CasExtendTokensRequestFromJSON,
    CasExtendTokensRequestToJSON,
    CasTicketTokenRequestFromJSON,
    CasTicketTokenRequestToJSON,
    CasTokensResponseFromJSON,
    CasTokensResponseToJSON,
    CheckCasAuthFromJSON,
    CheckCasAuthToJSON,
    RegisterRequestFromJSON,
    RegisterRequestToJSON,
    StatusFromJSON,
    StatusToJSON,
} from '../models';

export interface AuthCasCreateRequest {
    casAuthRequest: CasAuthRequest;
}

export interface AuthCasTokenCreateRequest {
    casTicketTokenRequest: CasTicketTokenRequest;
}

export interface AuthCasTokenRefreshCreateRequest {
    casExtendTokensRequest: CasExtendTokensRequest;
}

export interface AuthConfirmCreateRequest {
    authRequest: AuthRequest;
}

export interface AuthRegisterCreateRequest {
    registerRequest: RegisterRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Путь для редиректа в cas.
     * Запрос актуальной CAS ссылки на редирект
     */
    async authCasCheckAuthRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckCasAuth>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/auth/cas/check-auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckCasAuthFromJSON(jsonValue));
    }

    /**
     * Путь для редиректа в cas.
     * Запрос актуальной CAS ссылки на редирект
     */
    async authCasCheckAuthRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckCasAuth> {
          try{
        const response = await this.authCasCheckAuthRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Обновление данных пользователя из cas.
     * Запрос через CAS на авторизацию пользователя.
     */
    async authCasCreateRaw(requestParameters: AuthCasCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.casAuthRequest === null || requestParameters.casAuthRequest === undefined) {
            throw new runtime.RequiredError('casAuthRequest','Required parameter requestParameters.casAuthRequest was null or undefined when calling authCasCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/auth/cas/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CasAuthRequestToJSON(requestParameters.casAuthRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление данных пользователя из cas.
     * Запрос через CAS на авторизацию пользователя.
     */
    async authCasCreate(requestParameters: AuthCasCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authCasCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Получить access, refresh токены по внешнему тикету.
     * Получить токены через внешний тикет.
     */
    async authCasTokenCreateRaw(requestParameters: AuthCasTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CasTokensResponse>> {
        if (requestParameters.casTicketTokenRequest === null || requestParameters.casTicketTokenRequest === undefined) {
            throw new runtime.RequiredError('casTicketTokenRequest','Required parameter requestParameters.casTicketTokenRequest was null or undefined when calling authCasTokenCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/auth/cas/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CasTicketTokenRequestToJSON(requestParameters.casTicketTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CasTokensResponseFromJSON(jsonValue));
    }

    /**
     * Получить access, refresh токены по внешнему тикету.
     * Получить токены через внешний тикет.
     */
    async authCasTokenCreate(requestParameters: AuthCasTokenCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CasTokensResponse> {
          try{
        const response = await this.authCasTokenCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Продлить JWT токены.
     */
    async authCasTokenRefreshCreateRaw(requestParameters: AuthCasTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CasTokensResponse>> {
        if (requestParameters.casExtendTokensRequest === null || requestParameters.casExtendTokensRequest === undefined) {
            throw new runtime.RequiredError('casExtendTokensRequest','Required parameter requestParameters.casExtendTokensRequest was null or undefined when calling authCasTokenRefreshCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/auth/cas/token/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CasExtendTokensRequestToJSON(requestParameters.casExtendTokensRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CasTokensResponseFromJSON(jsonValue));
    }

    /**
     * Продлить JWT токены.
     */
    async authCasTokenRefreshCreate(requestParameters: AuthCasTokenRefreshCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CasTokensResponse> {
          try{
        const response = await this.authCasTokenRefreshCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async authConfirmCreateRaw(requestParameters: AuthConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authRequest === null || requestParameters.authRequest === undefined) {
            throw new runtime.RequiredError('authRequest','Required parameter requestParameters.authRequest was null or undefined when calling authConfirmCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/auth/confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthRequestToJSON(requestParameters.authRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authConfirmCreate(requestParameters: AuthConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authConfirmCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async authRegisterCreateRaw(requestParameters: AuthRegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registerRequest === null || requestParameters.registerRequest === undefined) {
            throw new runtime.RequiredError('registerRequest','Required parameter requestParameters.registerRequest was null or undefined when calling authRegisterCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/auth/register/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestToJSON(requestParameters.registerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authRegisterCreate(requestParameters: AuthRegisterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authRegisterCreateRaw(requestParameters, initOverrides);
    }

}
