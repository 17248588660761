/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
} from './GenderEnum';

/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    middleName?: string | null;
    /**
     * 
     * @type {GenderEnum}
     * @memberof UserRequest
     */
    gender: GenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserRequest
     */
    birthday?: Date | null;
}

/**
 * Check if a given object implements the UserRequest interface.
 */
export function instanceOfUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gender" in value;

    return isInstance;
}

export function UserRequestFromJSON(json: any): UserRequest {
    return UserRequestFromJSONTyped(json, false);
}

export function UserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'gender': GenderEnumFromJSON(json['gender']),
        'birthday': !exists(json, 'birthday') ? undefined : (json['birthday'] === null ? null : new Date(json['birthday'])),
    };
}

export function UserRequestToJSON(value?: UserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'middle_name': value.middleName,
        'gender': GenderEnumToJSON(value.gender),
        'birthday': value.birthday === undefined ? undefined : (value.birthday === null ? null : value.birthday.toISOString().substr(0,10)),
    };
}

