/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestPicture,
  BadRequestVideo,
  PageNotFound,
  Picture,
  Status,
  Video,
} from '../models';
import {
    BadRequestPictureFromJSON,
    BadRequestPictureToJSON,
    BadRequestVideoFromJSON,
    BadRequestVideoToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PictureFromJSON,
    PictureToJSON,
    StatusFromJSON,
    StatusToJSON,
    VideoFromJSON,
    VideoToJSON,
} from '../models';

export interface FilesPicturesCreateRequest {
    images: Array<Blob>;
}

export interface FilesPicturesDestroyRequest {
    id: number;
}

export interface FilesPicturesPartialUpdateRequest {
    id: number;
    integrationId?: string;
    image?: Blob;
}

export interface FilesPicturesRetrieveRequest {
    id: number;
}

export interface FilesPicturesUpdateRequest {
    id: number;
    image: Blob;
    integrationId?: string;
}

export interface FilesVideosCreateRequest {
    videos: Array<Blob>;
}

export interface FilesVideosDestroyRequest {
    id: number;
}

export interface FilesVideosPartialUpdateRequest {
    id: number;
    integrationId?: string;
    videoFile?: Blob;
}

export interface FilesVideosRetrieveRequest {
    id: number;
}

export interface FilesVideosUpdateRequest {
    id: number;
    videoFile: Blob;
    integrationId?: string;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Запрос на создание сущности (изображение).
     */
    async filesPicturesCreateRaw(requestParameters: FilesPicturesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Picture>>> {
        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling filesPicturesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/data/files/pictures/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PictureFromJSON));
    }

    /**
     * Запрос на создание сущности (изображение).
     */
    async filesPicturesCreate(requestParameters: FilesPicturesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Picture>> {
          try{
        const response = await this.filesPicturesCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на удаление нескольких изображений.
     */
    async filesPicturesDeleteCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/pictures/delete/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление нескольких изображений.
     */
    async filesPicturesDeleteCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.filesPicturesDeleteCreateRaw(initOverrides);
    }

    /**
     * Запрос на удаление сущности (изображение).
     */
    async filesPicturesDestroyRaw(requestParameters: FilesPicturesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesPicturesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/pictures/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление сущности (изображение).
     */
    async filesPicturesDestroy(requestParameters: FilesPicturesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.filesPicturesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на получение списка сущностей (изображение).
     */
    async filesPicturesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Picture>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/pictures/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PictureFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (изображение).
     */
    async filesPicturesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Picture>> {
          try{
        const response = await this.filesPicturesListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление полей сущности (изображение).
     */
    async filesPicturesPartialUpdateRaw(requestParameters: FilesPicturesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Picture>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesPicturesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.integrationId !== undefined) {
            formParams.append('integration_id', requestParameters.integrationId as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/data/files/pictures/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление полей сущности (изображение).
     */
    async filesPicturesPartialUpdate(requestParameters: FilesPicturesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Picture> {
          try{
        const response = await this.filesPicturesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (изображение).
     */
    async filesPicturesRetrieveRaw(requestParameters: FilesPicturesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Picture>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesPicturesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/pictures/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (изображение).
     */
    async filesPicturesRetrieve(requestParameters: FilesPicturesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Picture> {
          try{
        const response = await this.filesPicturesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление сущности (изображение).
     */
    async filesPicturesUpdateRaw(requestParameters: FilesPicturesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Picture>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesPicturesUpdate.');
        }

        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling filesPicturesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.integrationId !== undefined) {
            formParams.append('integration_id', requestParameters.integrationId as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/data/files/pictures/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PictureFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление сущности (изображение).
     */
    async filesPicturesUpdate(requestParameters: FilesPicturesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Picture> {
          try{
        const response = await this.filesPicturesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на создание сущности (видео).
     */
    async filesVideosCreateRaw(requestParameters: FilesVideosCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Video>>> {
        if (requestParameters.videos === null || requestParameters.videos === undefined) {
            throw new runtime.RequiredError('videos','Required parameter requestParameters.videos was null or undefined when calling filesVideosCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.videos) {
            requestParameters.videos.forEach((element) => {
                formParams.append('videos', element as any);
            })
        }

        const response = await this.request({
            path: `/data/files/videos/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VideoFromJSON));
    }

    /**
     * Запрос на создание сущности (видео).
     */
    async filesVideosCreate(requestParameters: FilesVideosCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Video>> {
          try{
        const response = await this.filesVideosCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на удаление нескольких видео.
     */
    async filesVideosDeleteCreateRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/videos/delete/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление нескольких видео.
     */
    async filesVideosDeleteCreate(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.filesVideosDeleteCreateRaw(initOverrides);
    }

    /**
     * Запрос на удаление сущности (видео).
     */
    async filesVideosDestroyRaw(requestParameters: FilesVideosDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesVideosDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/videos/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление сущности (видео).
     */
    async filesVideosDestroy(requestParameters: FilesVideosDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.filesVideosDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на получение списка сущностей (видео).
     */
    async filesVideosListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Video>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/videos/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VideoFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (видео).
     */
    async filesVideosList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Video>> {
          try{
        const response = await this.filesVideosListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление полей сущности (видео).
     */
    async filesVideosPartialUpdateRaw(requestParameters: FilesVideosPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Video>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesVideosPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.integrationId !== undefined) {
            formParams.append('integration_id', requestParameters.integrationId as any);
        }

        if (requestParameters.videoFile !== undefined) {
            formParams.append('video_file', requestParameters.videoFile as any);
        }

        const response = await this.request({
            path: `/data/files/videos/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление полей сущности (видео).
     */
    async filesVideosPartialUpdate(requestParameters: FilesVideosPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Video> {
          try{
        const response = await this.filesVideosPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (видео).
     */
    async filesVideosRetrieveRaw(requestParameters: FilesVideosRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Video>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesVideosRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/files/videos/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (видео).
     */
    async filesVideosRetrieve(requestParameters: FilesVideosRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Video> {
          try{
        const response = await this.filesVideosRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление сущности (видео).
     */
    async filesVideosUpdateRaw(requestParameters: FilesVideosUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Video>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling filesVideosUpdate.');
        }

        if (requestParameters.videoFile === null || requestParameters.videoFile === undefined) {
            throw new runtime.RequiredError('videoFile','Required parameter requestParameters.videoFile was null or undefined when calling filesVideosUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.integrationId !== undefined) {
            formParams.append('integration_id', requestParameters.integrationId as any);
        }

        if (requestParameters.videoFile !== undefined) {
            formParams.append('video_file', requestParameters.videoFile as any);
        }

        const response = await this.request({
            path: `/data/files/videos/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VideoFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление сущности (видео).
     */
    async filesVideosUpdate(requestParameters: FilesVideosUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Video> {
          try{
        const response = await this.filesVideosUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
