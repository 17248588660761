/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PeriodEnum } from './PeriodEnum';
import {
    PeriodEnumFromJSON,
    PeriodEnumFromJSONTyped,
    PeriodEnumToJSON,
} from './PeriodEnum';

/**
 * 
 * @export
 * @interface ViewedProductsRule
 */
export interface ViewedProductsRule {
    /**
     * 
     * @type {number}
     * @memberof ViewedProductsRule
     */
    number?: number;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof ViewedProductsRule
     */
    period?: PeriodEnum;
}

/**
 * Check if a given object implements the ViewedProductsRule interface.
 */
export function instanceOfViewedProductsRule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewedProductsRuleFromJSON(json: any): ViewedProductsRule {
    return ViewedProductsRuleFromJSONTyped(json, false);
}

export function ViewedProductsRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewedProductsRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'period': !exists(json, 'period') ? undefined : PeriodEnumFromJSON(json['period']),
    };
}

export function ViewedProductsRuleToJSON(value?: ViewedProductsRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'period': PeriodEnumToJSON(value.period),
    };
}

