/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageFormatEnum } from './ImageFormatEnum';
import {
    ImageFormatEnumFromJSON,
    ImageFormatEnumFromJSONTyped,
    ImageFormatEnumToJSON,
} from './ImageFormatEnum';
import type { SizeEnum } from './SizeEnum';
import {
    SizeEnumFromJSON,
    SizeEnumFromJSONTyped,
    SizeEnumToJSON,
} from './SizeEnum';

/**
 * 
 * @export
 * @interface ImageS3
 */
export interface ImageS3 {
    /**
     * 
     * @type {string}
     * @memberof ImageS3
     */
    readonly url: string;
    /**
     * 
     * @type {SizeEnum}
     * @memberof ImageS3
     */
    size: SizeEnum;
    /**
     * 
     * @type {ImageFormatEnum}
     * @memberof ImageS3
     */
    imageFormat: ImageFormatEnum;
}

/**
 * Check if a given object implements the ImageS3 interface.
 */
export function instanceOfImageS3(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "imageFormat" in value;

    return isInstance;
}

export function ImageS3FromJSON(json: any): ImageS3 {
    return ImageS3FromJSONTyped(json, false);
}

export function ImageS3FromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageS3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'size': SizeEnumFromJSON(json['size']),
        'imageFormat': ImageFormatEnumFromJSON(json['image_format']),
    };
}

export function ImageS3ToJSON(value?: ImageS3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': SizeEnumToJSON(value.size),
        'image_format': ImageFormatEnumToJSON(value.imageFormat),
    };
}

