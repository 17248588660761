<template>
  <Teleport to="body">
    <div class="pointer-events-none fixed inset-0 z-[100] lg:hidden">
      <transition
        enter-active-class="transition duration-300 ease-out"
        enter-from-class="opacity-0"
        leave-active-class="transition duration-300 ease-in"
        leave-to-class="opacity-0"
      >
        <div
          v-if="isOpenedMenu"
          class="pointer-events-auto absolute inset-0 bg-black/40 backdrop-blur-[2px]"
          @click="emit('close')"
        />
      </transition>

      <transition
        enter-active-class="transition duration-500 ease-in-out"
        enter-from-class="transform -translate-x-full"
        leave-active-class="transition duration-500 ease-in-out"
        leave-to-class="transform -translate-x-full"
      >
        <div
          v-if="isOpenedMenu"
          class="pointer-events-auto relative flex h-full w-full flex-col overflow-y-auto bg-white"
          :class="menuClass"
        >
          <slot name="logo-top" />

          <nav class="grow" :class="navClass">
            <ul class="text-secondary text-base leading-6">
              <slot name="address" />

              <li
                v-for="(item, i) in items"
                :key="i"
                class="border-b border-b-gray-100 last:border-b-0"
                :class="navItemClass"
              >
                <a
                  v-if="item.auth"
                  :href="item.to"
                  class="active:text-primary block p-[14px]"
                  @click.prevent="emit('goToAuthBefore', item.to)"
                >
                  {{ item.text }}
                </a>

                <NuxtLink
                  v-else
                  :to="item.to"
                  :class="[item?.class]"
                  class="active:text-primary block p-[14px]"
                  @click="emit('close')"
                >
                  {{ item.text }}
                  <Icon v-if="item.external" name="out" class="ml-2 inline" />
                </NuxtLink>
              </li>
            </ul>

            <slot name="phone-nav" />
          </nav>

          <slot name="phone-bottom" />

          <slot name="logo-bottom" />
        </div>
      </transition>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const isOpenedMenu = defineModel({ type: Boolean })
defineProps<{
  addressText?: string
  addressFullText?: string
  phoneTo: string
  menuClass: string
  navClass: string
  navItemClass: string
  items?: [
    {
      auth?: boolean
      mobileOnly?: boolean
      to: string
      text: string
      class?: string
      external?: boolean
    },
  ]
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'goToAuthBefore', url: string): void
}>()
</script>
