/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    Order,
    instanceOfOrder,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import {
    OrderHistory,
    instanceOfOrderHistory,
    OrderHistoryFromJSON,
    OrderHistoryFromJSONTyped,
    OrderHistoryToJSON,
} from './OrderHistory';

/**
 * @type MetaOrdersList
 * 
 * @export
 */
export type MetaOrdersList = { type: 'draft' } & OrderHistory | { type: 'origin' } & Order;

export function MetaOrdersListFromJSON(json: any): MetaOrdersList {
    return MetaOrdersListFromJSONTyped(json, false);
}

export function MetaOrdersListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaOrdersList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'draft':
            return {...OrderHistoryFromJSONTyped(json, true), type: 'draft'};
        case 'origin':
            return {...OrderFromJSONTyped(json, true), type: 'origin'};
        default:
            throw new Error(`No variant of MetaOrdersList exists with 'type=${json['type']}'`);
    }
}

export function MetaOrdersListToJSON(value?: MetaOrdersList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'draft':
            return OrderHistoryToJSON(value);
        case 'origin':
            return OrderToJSON(value);
        default:
            throw new Error(`No variant of MetaOrdersList exists with 'type=${value['type']}'`);
    }

}

