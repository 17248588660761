/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Сериализация информации об изображении на главной странице
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly desktopImage: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly mobileImage: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly desktopImageWebp: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly desktopImagePng: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly mobileImageWebp: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly mobileImagePng: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly title: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly description: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly color: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly buttonText: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly buttonUrl: string;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    readonly row: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    readonly startAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Banner
     */
    readonly finishAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly tag: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly tagColour: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    readonly tagTextColour: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    blockAd?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Banner
     */
    isAd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Banner
     */
    readonly isActive: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    viewAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    readonly companySite: number | null;
}

/**
 * Check if a given object implements the Banner interface.
 */
export function instanceOfBanner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "desktopImage" in value;
    isInstance = isInstance && "mobileImage" in value;
    isInstance = isInstance && "desktopImageWebp" in value;
    isInstance = isInstance && "desktopImagePng" in value;
    isInstance = isInstance && "mobileImageWebp" in value;
    isInstance = isInstance && "mobileImagePng" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "buttonText" in value;
    isInstance = isInstance && "buttonUrl" in value;
    isInstance = isInstance && "row" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "startAt" in value;
    isInstance = isInstance && "finishAt" in value;
    isInstance = isInstance && "tag" in value;
    isInstance = isInstance && "tagColour" in value;
    isInstance = isInstance && "tagTextColour" in value;
    isInstance = isInstance && "isActive" in value;
    isInstance = isInstance && "companySite" in value;

    return isInstance;
}

export function BannerFromJSON(json: any): Banner {
    return BannerFromJSONTyped(json, false);
}

export function BannerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Banner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'desktopImage': json['desktop_image'],
        'mobileImage': json['mobile_image'],
        'desktopImageWebp': json['desktop_image_webp'],
        'desktopImagePng': json['desktop_image_png'],
        'mobileImageWebp': json['mobile_image_webp'],
        'mobileImagePng': json['mobile_image_png'],
        'title': json['title'],
        'description': json['description'],
        'color': json['color'],
        'buttonText': json['button_text'],
        'buttonUrl': json['button_url'],
        'row': json['row'],
        'updatedAt': (new Date(json['updated_at'])),
        'startAt': (json['start_at'] === null ? null : new Date(json['start_at'])),
        'finishAt': (json['finish_at'] === null ? null : new Date(json['finish_at'])),
        'tag': json['tag'],
        'tagColour': json['tag_colour'],
        'tagTextColour': json['tag_text_colour'],
        'blockAd': !exists(json, 'block_ad') ? undefined : json['block_ad'],
        'isAd': !exists(json, 'is_ad') ? undefined : json['is_ad'],
        'isActive': json['is_active'],
        'viewAmount': !exists(json, 'view_amount') ? undefined : json['view_amount'],
        'companySite': json['company_site'],
    };
}

export function BannerToJSON(value?: Banner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block_ad': value.blockAd,
        'is_ad': value.isAd,
        'view_amount': value.viewAmount,
    };
}

