/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeInterval
 */
export interface TimeInterval {
    /**
     * 
     * @type {number}
     * @memberof TimeInterval
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof TimeInterval
     */
    timeFrom: string;
    /**
     * 
     * @type {string}
     * @memberof TimeInterval
     */
    timeTill: string;
}

/**
 * Check if a given object implements the TimeInterval interface.
 */
export function instanceOfTimeInterval(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "timeFrom" in value;
    isInstance = isInstance && "timeTill" in value;

    return isInstance;
}

export function TimeIntervalFromJSON(json: any): TimeInterval {
    return TimeIntervalFromJSONTyped(json, false);
}

export function TimeIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'timeFrom': json['time_from'],
        'timeTill': json['time_till'],
    };
}

export function TimeIntervalToJSON(value?: TimeInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time_from': value.timeFrom,
        'time_till': value.timeTill,
    };
}

