/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleProductBanner
 */
export interface SimpleProductBanner {
    /**
     * 
     * @type {number}
     * @memberof SimpleProductBanner
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    readonly image: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    title: string;
    /**
     * Дополнительная возможность задать/изменить процент скидки в иконке
     * @type {string}
     * @memberof SimpleProductBanner
     */
    imageText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    urlText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    tag?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    tagColour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleProductBanner
     */
    tagTextColour?: string | null;
}

/**
 * Check if a given object implements the SimpleProductBanner interface.
 */
export function instanceOfSimpleProductBanner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function SimpleProductBannerFromJSON(json: any): SimpleProductBanner {
    return SimpleProductBannerFromJSONTyped(json, false);
}

export function SimpleProductBannerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleProductBanner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'image': json['image'],
        'title': json['title'],
        'imageText': !exists(json, 'image_text') ? undefined : json['image_text'],
        'urlText': !exists(json, 'url_text') ? undefined : json['url_text'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'tagColour': !exists(json, 'tag_colour') ? undefined : json['tag_colour'],
        'tagTextColour': !exists(json, 'tag_text_colour') ? undefined : json['tag_text_colour'],
    };
}

export function SimpleProductBannerToJSON(value?: SimpleProductBanner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'image_text': value.imageText,
        'url_text': value.urlText,
        'url': value.url,
        'tag': value.tag,
        'tag_colour': value.tagColour,
        'tag_text_colour': value.tagTextColour,
    };
}

