import type {
  IViewProduct,
  IProduct,
  IAddToCartProduct,
  IRemoveFromCartProduct,
  IBeginCheckout,
  IPurchase,
  IPurchaseModel,
  ISelectProduct,
} from './models'

// TODO перенести в composables, чтобы можно было использовать другие composables
class eCommerce {
  /**
   * Send products data when open page Cart
   * @param data
   */
  viewCart(data: IProduct[]) {
    this.pushData<IBeginCheckout>('view_cart', {
      items: data,
    })
  }

  /**
   * Send product data by open product in catalog page
   * @param data
   */
  viewProduct(data: IProduct) {
    this.pushData<IViewProduct>('view_item', {
      items: [data],
    })
  }

  /**
   * Send product data by select product in catalog page
   * @param data
   */
  selectProduct(data: IProduct) {
    this.pushData<ISelectProduct>('select_item', {
      items: [data],
    })
  }

  /**
   * Send product data when product add in cart
   * @param data
   */
  addToCardProduct(data: IProduct) {
    this.pushData<IAddToCartProduct>('add_to_cart', {
      items: [data],
    })
  }

  /**
   * Send product data when remove product from cart
   * @param data
   */
  removeFromCardProduct(data: IProduct) {
    this.pushData<IRemoveFromCartProduct>('remove_from_cart', {
      items: [data],
    })
  }

  /**
   * Send products data when open page checkout
   * @param data
   */
  beginCheckout(data: IProduct[]) {
    this.pushData<IBeginCheckout>('begin_checkout', {
      items: data,
    })
  }

  /**
   * Send products data and order data when buy order
   * @param data
   */
  purchase(data: IPurchaseModel) {
    this.pushData<IPurchase>('purchase', {
      items: data.products,
      value: data.totalOrderPrice,
      shipping: data.deliveryPrice,
      currency: data.currency,
      transaction_id: data.orderId,
      coupon: data.coupon,
    })
  }

  /**
   * Цели для авторизации
   * @param type
   */
  successAuth(
    type:
      | 'email_pass'
      | 'phone_pass'
      | 'phone_sms'
      | 'email_code'
      | 'register'
      | 'vk_auth'
      | 'google_auth'
      | 'social_undefined'
      | 'password_less',
  ) {
    const { $i18n } = useNuxtApp()

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'auth_success',
        auth_data: {
          site_name: $i18n.t('app.name'),
          auth_type: type,
        },
      })
    }
  }

  /**
   * Method by send data in DATA LAYER by GTM
   * @param eventName
   * @param data
   */
  pushData<IDataEcommerce>(eventName: string, data: IDataEcommerce) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: eventName,
        ecommerce: data,
      })
    }
  }
}

// eslint-disable-next-line new-cap
const ecommerce = new eCommerce()

export default ecommerce
export { ecommerce }
