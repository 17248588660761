/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderItemCartReverse } from './OrderItemCartReverse';
import {
    OrderItemCartReverseFromJSON,
    OrderItemCartReverseFromJSONTyped,
    OrderItemCartReverseToJSON,
} from './OrderItemCartReverse';

/**
 * 
 * @export
 * @interface CartReserveItemsChanged
 */
export interface CartReserveItemsChanged {
    /**
     * 
     * @type {number}
     * @memberof CartReserveItemsChanged
     */
    productId: number;
    /**
     * 
     * @type {OrderItemCartReverse}
     * @memberof CartReserveItemsChanged
     */
    orderItem: OrderItemCartReverse;
    /**
     * 
     * @type {number}
     * @memberof CartReserveItemsChanged
     */
    newAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CartReserveItemsChanged
     */
    newQuantity?: number | null;
}

/**
 * Check if a given object implements the CartReserveItemsChanged interface.
 */
export function instanceOfCartReserveItemsChanged(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "orderItem" in value;

    return isInstance;
}

export function CartReserveItemsChangedFromJSON(json: any): CartReserveItemsChanged {
    return CartReserveItemsChangedFromJSONTyped(json, false);
}

export function CartReserveItemsChangedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartReserveItemsChanged {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'orderItem': OrderItemCartReverseFromJSON(json['order_item']),
        'newAmount': !exists(json, 'new_amount') ? undefined : json['new_amount'],
        'newQuantity': !exists(json, 'new_quantity') ? undefined : json['new_quantity'],
    };
}

export function CartReserveItemsChangedToJSON(value?: CartReserveItemsChanged | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'order_item': OrderItemCartReverseToJSON(value.orderItem),
        'new_amount': value.newAmount,
        'new_quantity': value.newQuantity,
    };
}

