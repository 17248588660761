/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CrossBannerTemplate } from './CrossBannerTemplate';
import {
    CrossBannerTemplateFromJSON,
    CrossBannerTemplateFromJSONTyped,
    CrossBannerTemplateToJSON,
} from './CrossBannerTemplate';

/**
 * 
 * @export
 * @interface CrossBanner
 */
export interface CrossBanner {
    /**
     * 
     * @type {number}
     * @memberof CrossBanner
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    readonly image: string;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    buttonColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    buttonText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    buttonTextColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    backgroundColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    titleColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CrossBanner
     */
    buttonHreff?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CrossBanner
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CrossBanner
     */
    endDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CrossBanner
     */
    isActive?: boolean;
    /**
     * 
     * @type {CrossBannerTemplate}
     * @memberof CrossBanner
     */
    template: CrossBannerTemplate;
    /**
     * 
     * @type {Array<number>}
     * @memberof CrossBanner
     */
    sites: Array<number>;
}

/**
 * Check if a given object implements the CrossBanner interface.
 */
export function instanceOfCrossBanner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "template" in value;
    isInstance = isInstance && "sites" in value;

    return isInstance;
}

export function CrossBannerFromJSON(json: any): CrossBanner {
    return CrossBannerFromJSONTyped(json, false);
}

export function CrossBannerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CrossBanner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'image': json['image'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'buttonColor': !exists(json, 'button_color') ? undefined : json['button_color'],
        'buttonText': !exists(json, 'button_text') ? undefined : json['button_text'],
        'buttonTextColor': !exists(json, 'button_text_color') ? undefined : json['button_text_color'],
        'backgroundColor': !exists(json, 'background_color') ? undefined : json['background_color'],
        'titleColor': !exists(json, 'title_color') ? undefined : json['title_color'],
        'buttonHreff': !exists(json, 'button_hreff') ? undefined : json['button_hreff'],
        'startDate': (new Date(json['start_date'])),
        'endDate': (new Date(json['end_date'])),
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'template': CrossBannerTemplateFromJSON(json['template']),
        'sites': json['sites'],
    };
}

export function CrossBannerToJSON(value?: CrossBanner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'button_color': value.buttonColor,
        'button_text': value.buttonText,
        'button_text_color': value.buttonTextColor,
        'background_color': value.backgroundColor,
        'title_color': value.titleColor,
        'button_hreff': value.buttonHreff,
        'start_date': (value.startDate.toISOString()),
        'end_date': (value.endDate.toISOString()),
        'is_active': value.isActive,
        'template': CrossBannerTemplateToJSON(value.template),
        'sites': value.sites,
    };
}

