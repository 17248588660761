const cookieKeysMap = [
  ['google_client_id', '_ga'],
  ['yandex_client_id', '_ym_uid'],
  ['utm_source', 'utm_source'],
  ['utm_medium', 'utm_medium'],
  ['utm_campaign', 'utm_campaign'],
  ['utm_content', 'utm_content'],
  ['utm_term', 'utm_term'],
]

export default function useApimonster() {
  const { APIMONSTER_URL } = useRuntimeConfig().public

  async function send(params: Record<string, string | undefined>) {
    if (APIMONSTER_URL) {
      await $fetch(APIMONSTER_URL, {
        method: 'POST',
        body: Object.assign(getCookieMap(), params),
      })
    }
  }

  function getCookieMap() {
    return cookieKeysMap.reduce<Record<string, string | undefined>>(
      (acc, [key, cookieKey]) => {
        acc[key] = getCookie(cookieKey)
        return acc
      },
      {},
    )
  }

  function getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') +
          '=([^;]*)',
      ),
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  return {
    send,
  }
}
