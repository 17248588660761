/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestPhrase,
  PageNotFound,
  Phrase,
  PhraseRequest,
} from '../models';
import {
    BadRequestPhraseFromJSON,
    BadRequestPhraseToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PhraseFromJSON,
    PhraseToJSON,
    PhraseRequestFromJSON,
    PhraseRequestToJSON,
} from '../models';

export interface AnalyticsPhrasesCreateRequest {
    phraseRequest: PhraseRequest;
}

export interface AnalyticsPhrasesDestroyRequest {
    id: number;
}

export interface AnalyticsPhrasesListRequest {
    ordering?: string;
    search?: string;
}

/**
 * 
 */
export class AnalyticsApi extends runtime.BaseAPI {

    /**
     * Запрос на создание сущности (поисковые запросы пользователей).
     */
    async analyticsPhrasesCreateRaw(requestParameters: AnalyticsPhrasesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Phrase>> {
        if (requestParameters.phraseRequest === null || requestParameters.phraseRequest === undefined) {
            throw new runtime.RequiredError('phraseRequest','Required parameter requestParameters.phraseRequest was null or undefined when calling analyticsPhrasesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/analytics/phrases/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhraseRequestToJSON(requestParameters.phraseRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhraseFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (поисковые запросы пользователей).
     */
    async analyticsPhrasesCreate(requestParameters: AnalyticsPhrasesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Phrase> {
          try{
        const response = await this.analyticsPhrasesCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на удаление сущности (поисковые запросы пользователей).
     */
    async analyticsPhrasesDestroyRaw(requestParameters: AnalyticsPhrasesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling analyticsPhrasesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/analytics/phrases/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление сущности (поисковые запросы пользователей).
     */
    async analyticsPhrasesDestroy(requestParameters: AnalyticsPhrasesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.analyticsPhrasesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на получение списка сущностей (поисковые запросы пользователей).
     */
    async analyticsPhrasesListRaw(requestParameters: AnalyticsPhrasesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Phrase>>> {
        const queryParameters: any = {};

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/analytics/phrases/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhraseFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (поисковые запросы пользователей).
     */
    async analyticsPhrasesList(requestParameters: AnalyticsPhrasesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Phrase>> {
          try{
        const response = await this.analyticsPhrasesListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
