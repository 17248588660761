/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompareItemProduct } from './CompareItemProduct';
import {
    CompareItemProductFromJSON,
    CompareItemProductFromJSONTyped,
    CompareItemProductToJSON,
} from './CompareItemProduct';

/**
 * 
 * @export
 * @interface CompareProductItem
 */
export interface CompareProductItem {
    /**
     * 
     * @type {number}
     * @memberof CompareProductItem
     */
    readonly id: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CompareProductItem
     */
    readonly characteristics: { [key: string]: any; };
    /**
     * 
     * @type {CompareItemProduct}
     * @memberof CompareProductItem
     */
    readonly product: CompareItemProduct;
    /**
     * 
     * @type {number}
     * @memberof CompareProductItem
     */
    compare: number;
}

/**
 * Check if a given object implements the CompareProductItem interface.
 */
export function instanceOfCompareProductItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "characteristics" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "compare" in value;

    return isInstance;
}

export function CompareProductItemFromJSON(json: any): CompareProductItem {
    return CompareProductItemFromJSONTyped(json, false);
}

export function CompareProductItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompareProductItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'characteristics': json['characteristics'],
        'product': CompareItemProductFromJSON(json['product']),
        'compare': json['compare'],
    };
}

export function CompareProductItemToJSON(value?: CompareProductItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compare': value.compare,
    };
}

