import type { MaskaDetail } from 'maska'

export default function (mode: string) {
  const inputMaskDetail = ref<MaskaDetail>({
    masked: '',
    unmasked: '',
    completed: false,
  })
  const codeMaskDetail = ref<MaskaDetail>({
    masked: '',
    unmasked: '',
    completed: false,
  })

  const phonePattern = computed(() =>
    inputMaskDetail.value.masked.startsWith('8')
      ? '8\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}'
      : '\\+7\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}',
  )
  const mailPattern = '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'

  const phoneMaskOptions = reactive({
    mask: (value: string) =>
      value.startsWith('8') ? '8 (###) ###-##-##' : '+7 (###) ###-##-##',
    onMaska: (detail: MaskaDetail) => {
      inputMaskDetail.value = detail
    },
  })
  const codeMask = reactive({
    mask: () => '####',
    onMaska: (detail: MaskaDetail) => {
      codeMaskDetail.value = detail
    },
  })
  const mailMaskOptions = reactive({
    mask: () => '*',
    tokens: {
      '*': {
        pattern: /[\w\d@._-]+/g,
        transform: (chr: string) => chr,
        repeated: true,
      },
    },
    onMaska: (detail: MaskaDetail) => {
      inputMaskDetail.value = detail
    },
  })
  const multipleMaskOptions = reactive({
    mask: (value: string) => {
      if (
        (/^[\d]*$/.test(inputMaskDetail.value.unmasked) &&
          !/[a-zA-Zа-яА-Я@.]+/.test(value) &&
          mode !== 'email') ||
        mode === 'tel'
      ) {
        return value.startsWith('8')
          ? '8 (###) ###-##-##'
          : '+7 (###) ###-##-##'
      }
      return '*'
    },
    tokens: {
      '*': {
        pattern: /[a-zA-zа-яА-я\d@._-]+/g,
        transform: (chr: string) => chr,
        repeated: true,
      },
    },
    onMaska: (detail: MaskaDetail) => {
      inputMaskDetail.value = detail
    },
  })

  return {
    inputMaskDetail,
    codeMask,
    multipleMaskOptions,
    phoneMaskOptions,
    mailMaskOptions,
    phonePattern,
    mailPattern,
  }
}
