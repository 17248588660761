import revive_payload_client_ltaLOrJFLx from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ZtRFyvWxkT from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HWedqOg74E from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RKKoaqyqqu from "/app/node_modules/.pnpm/nuxt-site-config@2.2.7_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.38_nuxt@3.9.3_postcss@8.4._g5e6camxmmpf7u6ektwvyyerqa/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_tG0OBr2K8p from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_A9Jqs43tCp from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Fv7LKLpHYq from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.21.1_typescript@5.3.3_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/monge/.nuxt/components.plugin.mjs";
import prefetch_client_lIECGXMFqT from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_yP3bGc00NO from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.21.1_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_B2bVXhFCsM from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@4.21.1_vue-router@4.4.3_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_mVjGYqBV5S from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.0_rollup@4.21.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_KltYn9IniU from "/app/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.6_rollup@4.21.1_typescript@5.3.3_vue@3.4.38/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_HMZ09TN9R7 from "/app/packages/shared/modules/firebase/runtime/plugin.ts";
import chunk_reload_client_BcieQbgpeC from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_V2p5rJU6wH from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import plugin_EoGIQo7o5i from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.2_@pinia+nuxt@0.4.11_pinia@2.1.6_rollup@4.21.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import auth_ZfWcKdsxoP from "/app/packages/shared/plugins/auth.ts";
import errorHandling_lsLZ6BYEpW from "/app/packages/shared/plugins/errorHandling.ts";
import gtm_client_ebnBchNkh5 from "/app/packages/shared/plugins/gtm.client.ts";
import maska_ZkrgfuXpAa from "/app/packages/shared/plugins/maska.ts";
import pikaday_client_1uXBfWe2k8 from "/app/packages/shared/plugins/pikaday.client.ts";
import sanitizeHtml_tV4htHVjiU from "/app/packages/shared/plugins/sanitizeHtml.ts";
import sentry_client_uLTgB6jkEr from "/app/packages/shared/plugins/sentry.client.ts";
export default [
  revive_payload_client_ltaLOrJFLx,
  unhead_ZtRFyvWxkT,
  router_HWedqOg74E,
  _0_siteConfig_RKKoaqyqqu,
  payload_client_tG0OBr2K8p,
  check_outdated_build_client_A9Jqs43tCp,
  plugin_vue3_Fv7LKLpHYq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lIECGXMFqT,
  composition_yP3bGc00NO,
  i18n_B2bVXhFCsM,
  plugin_mVjGYqBV5S,
  plugin_KltYn9IniU,
  plugin_HMZ09TN9R7,
  chunk_reload_client_BcieQbgpeC,
  cross_origin_prefetch_client_V2p5rJU6wH,
  plugin_EoGIQo7o5i,
  auth_ZfWcKdsxoP,
  errorHandling_lsLZ6BYEpW,
  gtm_client_ebnBchNkh5,
  maska_ZkrgfuXpAa,
  pikaday_client_1uXBfWe2k8,
  sanitizeHtml_tV4htHVjiU,
  sentry_client_uLTgB6jkEr
]