/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestSubscriber,
  PageNotFound,
  PaginatedSubscriberList,
  PatchedSubscriberRequest,
  Subscriber,
  SubscriberRequest,
} from '../models';
import {
    BadRequestSubscriberFromJSON,
    BadRequestSubscriberToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PaginatedSubscriberListFromJSON,
    PaginatedSubscriberListToJSON,
    PatchedSubscriberRequestFromJSON,
    PatchedSubscriberRequestToJSON,
    SubscriberFromJSON,
    SubscriberToJSON,
    SubscriberRequestFromJSON,
    SubscriberRequestToJSON,
} from '../models';

export interface SubscribersCreateRequest {
    subscriberRequest: SubscriberRequest;
}

export interface SubscribersDestroyRequest {
    id: number;
}

export interface SubscribersListRequest {
    page?: number;
    pageSize?: number;
}

export interface SubscribersPartialUpdateRequest {
    id: number;
    patchedSubscriberRequest?: PatchedSubscriberRequest;
}

export interface SubscribersRetrieveRequest {
    id: number;
}

export interface SubscribersUpdateRequest {
    id: number;
    subscriberRequest: SubscriberRequest;
}

/**
 * 
 */
export class SubscribersApi extends runtime.BaseAPI {

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на создание сущности (подписка на рассылку).
     */
    async subscribersCreateRaw(requestParameters: SubscribersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscriber>> {
        if (requestParameters.subscriberRequest === null || requestParameters.subscriberRequest === undefined) {
            throw new runtime.RequiredError('subscriberRequest','Required parameter requestParameters.subscriberRequest was null or undefined when calling subscribersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/subscribers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriberRequestToJSON(requestParameters.subscriberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriberFromJSON(jsonValue));
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на создание сущности (подписка на рассылку).
     */
    async subscribersCreate(requestParameters: SubscribersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscriber> {
          try{
        const response = await this.subscribersCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на удаление сущности (подписка на рассылку).
     */
    async subscribersDestroyRaw(requestParameters: SubscribersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling subscribersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/subscribers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на удаление сущности (подписка на рассылку).
     */
    async subscribersDestroy(requestParameters: SubscribersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.subscribersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на получение списка сущностей (подписка на рассылку).
     */
    async subscribersListRaw(requestParameters: SubscribersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedSubscriberList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/subscribers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSubscriberListFromJSON(jsonValue));
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на получение списка сущностей (подписка на рассылку).
     */
    async subscribersList(requestParameters: SubscribersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedSubscriberList> {
          try{
        const response = await this.subscribersListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     */
    async subscribersPartialUpdateRaw(requestParameters: SubscribersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscriber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling subscribersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/subscribers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedSubscriberRequestToJSON(requestParameters.patchedSubscriberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriberFromJSON(jsonValue));
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     */
    async subscribersPartialUpdate(requestParameters: SubscribersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscriber> {
          try{
        const response = await this.subscribersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на получение экземпляра сущности (подписка на рассылку).
     */
    async subscribersRetrieveRaw(requestParameters: SubscribersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscriber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling subscribersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/subscribers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriberFromJSON(jsonValue));
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     * Запрос на получение экземпляра сущности (подписка на рассылку).
     */
    async subscribersRetrieve(requestParameters: SubscribersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscriber> {
          try{
        const response = await this.subscribersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     */
    async subscribersUpdateRaw(requestParameters: SubscribersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Subscriber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling subscribersUpdate.');
        }

        if (requestParameters.subscriberRequest === null || requestParameters.subscriberRequest === undefined) {
            throw new runtime.RequiredError('subscriberRequest','Required parameter requestParameters.subscriberRequest was null or undefined when calling subscribersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/subscribers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriberRequestToJSON(requestParameters.subscriberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriberFromJSON(jsonValue));
    }

    /**
     * Управляет подписчиками на рассылку. - `GET /subscribers/`: Возвращает список подписчиков, доступен только для администраторов. - `POST /subscribers/`: Позволяет любому пользователю (в том числе неавторизованному) оформить подписку.
     */
    async subscribersUpdate(requestParameters: SubscribersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Subscriber> {
          try{
        const response = await this.subscribersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
