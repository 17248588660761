/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetSetDeliveryInfoAddress } from './GetSetDeliveryInfoAddress';
import {
    GetSetDeliveryInfoAddressFromJSON,
    GetSetDeliveryInfoAddressFromJSONTyped,
    GetSetDeliveryInfoAddressToJSON,
} from './GetSetDeliveryInfoAddress';

/**
 * 
 * @export
 * @interface GetDeliveryAddressInfo
 */
export interface GetDeliveryAddressInfo {
    /**
     * 
     * @type {Array<GetSetDeliveryInfoAddress>}
     * @memberof GetDeliveryAddressInfo
     */
    deliveryAddress: Array<GetSetDeliveryInfoAddress>;
}

/**
 * Check if a given object implements the GetDeliveryAddressInfo interface.
 */
export function instanceOfGetDeliveryAddressInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryAddress" in value;

    return isInstance;
}

export function GetDeliveryAddressInfoFromJSON(json: any): GetDeliveryAddressInfo {
    return GetDeliveryAddressInfoFromJSONTyped(json, false);
}

export function GetDeliveryAddressInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliveryAddressInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryAddress': ((json['delivery_address'] as Array<any>).map(GetSetDeliveryInfoAddressFromJSON)),
    };
}

export function GetDeliveryAddressInfoToJSON(value?: GetDeliveryAddressInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_address': ((value.deliveryAddress as Array<any>).map(GetSetDeliveryInfoAddressToJSON)),
    };
}

