/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Article,
  ArticleRequest,
  BadRequestArticle,
  PageNotFound,
  PatchedArticleRequest,
} from '../models';
import {
    ArticleFromJSON,
    ArticleToJSON,
    ArticleRequestFromJSON,
    ArticleRequestToJSON,
    BadRequestArticleFromJSON,
    BadRequestArticleToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PatchedArticleRequestFromJSON,
    PatchedArticleRequestToJSON,
} from '../models';

export interface ArticleCreateRequest {
    articleRequest?: ArticleRequest;
}

export interface ArticleDestroyRequest {
    id: number;
}

export interface ArticlePartialUpdateRequest {
    id: number;
    patchedArticleRequest?: PatchedArticleRequest;
}

export interface ArticleRetrieveRequest {
    id: number;
}

export interface ArticleUpdateRequest {
    id: number;
    articleRequest?: ArticleRequest;
}

/**
 * 
 */
export class ArticleApi extends runtime.BaseAPI {

    /**
     * Запрос на создание сущности (статья).
     */
    async articleCreateRaw(requestParameters: ArticleCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/article/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ArticleRequestToJSON(requestParameters.articleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (статья).
     */
    async articleCreate(requestParameters: ArticleCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
          try{
        const response = await this.articleCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на удаление сущности (статья).
     */
    async articleDestroyRaw(requestParameters: ArticleDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/article/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление сущности (статья).
     */
    async articleDestroy(requestParameters: ArticleDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.articleDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на получение списка сущностей (статья).
     */
    async articleListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Article>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/article/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (статья).
     */
    async articleList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Article>> {
          try{
        const response = await this.articleListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление полей сущности (статья).
     */
    async articlePartialUpdateRaw(requestParameters: ArticlePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articlePartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/article/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedArticleRequestToJSON(requestParameters.patchedArticleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление полей сущности (статья).
     */
    async articlePartialUpdate(requestParameters: ArticlePartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
          try{
        const response = await this.articlePartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (статья).
     */
    async articleRetrieveRaw(requestParameters: ArticleRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/article/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (статья).
     */
    async articleRetrieve(requestParameters: ArticleRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
          try{
        const response = await this.articleRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление сущности (статья).
     */
    async articleUpdateRaw(requestParameters: ArticleUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/article/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArticleRequestToJSON(requestParameters.articleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление сущности (статья).
     */
    async articleUpdate(requestParameters: ArticleUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
          try{
        const response = await this.articleUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
