<template>
  <div class="mt-3">
    <ul
      itemscope
      class="flex flex-wrap"
      itemtype="https://schema.org/BreadcrumbList"
    >
      <li
        v-for="(item, index) in breadcrumbs"
        :key="index"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
        class="text-4 text-txt-secondary flex items-center leading-4"
      >
        <NuxtLink
          :to="item.link"
          :title="item.name"
          itemprop="item"
          class="hover:text-btn-hover"
        >
          <span itemprop="name">{{ item.name }}</span>
          <meta itemprop="position" :content="String(index)" />
        </NuxtLink>
        <Icon
          v-if="index !== breadcrumbs.length - 1"
          name="expand-right"
          class="mx-1.5 h-4 w-3"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const { breadcrumb } = usePageBreadcrumb()

const breadcrumbs = computed(() => {
  const crumbsArr: {
    name?: string
    link?: string
  }[] = [
    {
      name: 'Главная страница',
      link: '/',
    },
  ]

  const articles = {
    name: 'Статьи',
    link: '/articles',
  }

  const catalog = {
    name: 'Каталог',
    link: '/catalog/',
  }

  const giftshop = {
    name: 'Giftshop',
    link: '/giftshop/',
  }

  const dailyCatalog = {
    name: 'Каталог',
    link: '/catalog/daily/',
  }

  const orders = {
    name: 'Заказы',
    link: '/orders/',
  }

  const autoorders = {
    name: 'Автозаказы',
    link: '/orders/autoorders/',
  }

  const def = {
    name: breadcrumb.value,
  }

  const profile = {
    name: 'Личный кабинет',
    link: '/profile/',
  }

  const bwild = {
    name: 'Monge Bwild',
    link: '/bwild/',
  }
  const gift = {
    name: 'Monge gift',
    link: '/gift/',
  }
  const map = {
    name: 'Где купить',
    link: '/map/',
  }

  const kranch = {
    name: 'Mr. Kranch',
    link: '/mrkranch/',
  }

  switch (route.name) {
    case 'map-type':
      crumbsArr.push(map, def)
      break

    case 'articles':
      crumbsArr.push(articles)
      break

    case 'articles-slug':
      crumbsArr.push(articles, def)
      break

    case 'catalog':
    case 'catalog-characteristic':
      crumbsArr.push(catalog)
      break

    case 'product-id':
    case 'catalog-speciality':
      crumbsArr.push(catalog, def)
      break

    case 'catalog-bwild':
      crumbsArr.push(bwild, def)
      break
    case 'catalog-gift':
      crumbsArr.push(gift, def)
      break
    case 'profile-edit':
    case 'profile-pets-create':
    case 'profile-pets-id':
    case 'profile-pets-create-result':
      crumbsArr.push(profile, def)
      break

    case 'catalog-giftshop-characteristic':
      crumbsArr.push(giftshop, def)
      break

    case 'catalog-daily':
      crumbsArr.push(dailyCatalog, def)
      break

    case 'orders-id':
    case 'orders-autoorders':
      crumbsArr.push(orders, def)
      break

    case 'orders-templates-id':
    case 'orders-templates-id-edit':
      crumbsArr.push(orders, autoorders, def)
      break

    case 'mrkranch':
      crumbsArr.push(kranch)
      break
    case 'catalog-awardshop-characteristic':
      crumbsArr.push(catalog, def)
      break
    default:
      crumbsArr.push(def)
      break
  }

  return crumbsArr
})
</script>
