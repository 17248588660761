/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetSetDeliveryInfoDeliverAddress } from './GetSetDeliveryInfoDeliverAddress';
import {
    GetSetDeliveryInfoDeliverAddressFromJSON,
    GetSetDeliveryInfoDeliverAddressFromJSONTyped,
    GetSetDeliveryInfoDeliverAddressToJSON,
} from './GetSetDeliveryInfoDeliverAddress';
import type { GetSetDeliveryInfoPickupAddress } from './GetSetDeliveryInfoPickupAddress';
import {
    GetSetDeliveryInfoPickupAddressFromJSON,
    GetSetDeliveryInfoPickupAddressFromJSONTyped,
    GetSetDeliveryInfoPickupAddressToJSON,
} from './GetSetDeliveryInfoPickupAddress';

/**
 * 
 * @export
 * @interface GetLastOrderDeliveryAddressInfo
 */
export interface GetLastOrderDeliveryAddressInfo {
    /**
     * 
     * @type {GetSetDeliveryInfoPickupAddress}
     * @memberof GetLastOrderDeliveryAddressInfo
     */
    lastOrderPickupAddress: GetSetDeliveryInfoPickupAddress | null;
    /**
     * 
     * @type {GetSetDeliveryInfoDeliverAddress}
     * @memberof GetLastOrderDeliveryAddressInfo
     */
    lastOrderDeliveryAddress: GetSetDeliveryInfoDeliverAddress | null;
}

/**
 * Check if a given object implements the GetLastOrderDeliveryAddressInfo interface.
 */
export function instanceOfGetLastOrderDeliveryAddressInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastOrderPickupAddress" in value;
    isInstance = isInstance && "lastOrderDeliveryAddress" in value;

    return isInstance;
}

export function GetLastOrderDeliveryAddressInfoFromJSON(json: any): GetLastOrderDeliveryAddressInfo {
    return GetLastOrderDeliveryAddressInfoFromJSONTyped(json, false);
}

export function GetLastOrderDeliveryAddressInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLastOrderDeliveryAddressInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastOrderPickupAddress': GetSetDeliveryInfoPickupAddressFromJSON(json['last_order_pickup_address']),
        'lastOrderDeliveryAddress': GetSetDeliveryInfoDeliverAddressFromJSON(json['last_order_delivery_address']),
    };
}

export function GetLastOrderDeliveryAddressInfoToJSON(value?: GetLastOrderDeliveryAddressInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_order_pickup_address': GetSetDeliveryInfoPickupAddressToJSON(value.lastOrderPickupAddress),
        'last_order_delivery_address': GetSetDeliveryInfoDeliverAddressToJSON(value.lastOrderDeliveryAddress),
    };
}

