import type {
  Product,
  ProductImage,
  ChildProductCharacteristic,
  SeoMetadata,
} from '@/openapi_fetch'
import { findIndex } from 'lodash-es'

enum CatalogFilterKeysEnum {
  typePet = 'typePet',
  age = 'age',
  typeFeed = 'typeFeed',
  taste = 'taste',
  size = 'size',
}
type CatalogFilterItem = Omit<ChildProductCharacteristic, 'children'> & {
  level?: number
  children?: ChildProductCharacteristic['children']
  parent?: CatalogFilterItem
  key?: CatalogFilterKeysEnum
}
type CatalogFilterOptions = Record<CatalogFilterKeysEnum, CatalogFilterItem>
type CatalogFilterValues = Record<CatalogFilterKeysEnum, number>
type OpenGraphImage = {
  url: string
  type: 'image/jpeg' | 'image/png' | 'image/gif'
  width: string
  height: string
  alt?: string
}
type UsePageHeadOptions = {
  image: OpenGraphImage
}

export function usePageSeoTag() {
  const app = useNuxtApp()
  const route = useRoute()

  const seoTag = computed(() => {
    const url = `/data/seo/seo-tag/?url=${trailingSlash(route.path)}`
    // в computed контекст требуется восстанавливать
    // запрос делается в middleware packages/shared/middleware/seo-tag.global.ts
    const { data: seoTagData } = app.runWithContext(() =>
      useNuxtData(url),
    ) as ReturnType<typeof useNuxtData<SeoMetadata[]>>

    return seoTagData?.value?.[0]
  })

  return {
    seoTag,
  }
}

export function usePageTitle() {
  const route = useRoute()
  const { t, te } = useI18n()
  const { seoTag } = usePageSeoTag()

  const title = computed(() => {
    if (seoTag.value?.title) {
      return seoTag.value.title
    }
    const trKey = `pages.${String(route.name)}.title`
    const value = te(trKey) ? t(trKey) : ''
    return getTitleWithTemplate(value)
  })

  function getTitleWithTemplate(value?: string) {
    const templateTrKey = `pages.${String(route.name)}.titleTemplate`
    return value
      ? te(templateTrKey)
        ? t(templateTrKey, [value])
        : te('app.titleTemplate')
          ? t('app.titleTemplate', [value])
          : value
      : ''
  }

  return {
    title,
    getTitleWithTemplate,
  }
}

export function usePageDescription() {
  const route = useRoute()
  const { t, te } = useI18n()
  const { seoTag } = usePageSeoTag()

  const description = computed(() => {
    if (seoTag.value?.description) {
      return seoTag.value.description
    }
    const trKey = `pages.${String(route.name)}.description`
    return te(trKey) ? t(trKey) : ''
  })

  return {
    description,
  }
}

export function usePageHeading() {
  const route = useRoute()
  const { t, te } = useI18n()
  const { seoTag } = usePageSeoTag()

  const heading = computed(() => {
    if (seoTag.value?.heading) {
      return seoTag.value.heading
    }
    return te(`pages.${String(route.name)}.heading`)
      ? t(`pages.${String(route.name)}.heading`)
      : te(`pages.${String(route.name)}.title`)
        ? t(`pages.${String(route.name)}.title`)
        : ''
  })

  return {
    heading,
  }
}

export function usePageBreadcrumb() {
  const route = useRoute()
  const { t, te } = useI18n()
  const { seoTag } = usePageSeoTag()

  const breadcrumb = computed(() => {
    if (seoTag.value?.title) {
      return seoTag.value.title
    }
    if (route.meta.breadcrumb) {
      return route.meta.breadcrumb as string
    }
    return te(`pages.${String(route.name)}.breadcrumb`)
      ? t(`pages.${String(route.name)}.breadcrumb`)
      : te(`pages.${String(route.name)}.title`)
        ? t(`pages.${String(route.name)}.title`)
        : ''
  })

  return {
    breadcrumb,
  }
}

export function usePageHead(
  options: UsePageHeadOptions = {
    image: {
      url: '/logo.png',
      type: 'image/png',
      width: '200',
      height: '200',
    },
  },
) {
  const url = useRequestURL()
  const route = useRoute()
  const { t, te } = useI18n()
  const { title } = usePageTitle()
  const { description } = usePageDescription()
  const { image } = options

  const siteName = te('app.name') ? t('app.name') : ''

  useSeoMeta({
    title,
    description,
    ogLocale: 'ru_RU',
    ogUrl: () => `${url.origin}${route.fullPath}`,
    ogSiteName: () => siteName || url.hostname,
    ogTitle: title,
    ogDescription: description,
    ogType: 'website',
    ogImageType: image.type,
    ogImageWidth: image.width,
    ogImageHeight: image.height,
    ogImageAlt: () =>
      image.alt ? image.alt : siteName ? `логотип ${siteName}` : 'логотип',
    ogImage: () => `${url.origin}${image.url}`,
  })
}

export function useArticlesPageHead(
  _article: Ref<{ title?: string } | null | undefined>,
) {
  const route = useRoute()
  const { seoTag } = usePageSeoTag()
  const { getTitleWithTemplate } = usePageTitle()

  const title = computed(() =>
    seoTag.value?.title
      ? seoTag.value.title
      : _article.value?.title
        ? getTitleWithTemplate(_article.value.title)
        : '',
  )

  watchEffect(() => {
    route.meta.breadcrumb =
      seoTag.value?.title || _article.value?.title || 'Загружается...'
  })

  useSeoMeta({
    title,
    ogTitle: title,
  })
}

export function useCatalogPageHead(
  _filter: Ref<{
    values: CatalogFilterValues
    options: CatalogFilterOptions
  }>,
) {
  const route = useRoute()
  const { t, te } = useI18n()
  const { seoTag } = usePageSeoTag()
  const { title: _title } = usePageTitle()
  const { heading: _heading } = usePageHeading()
  const { description: _description } = usePageDescription()

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  function findFilterByIdWithParents(
    item: CatalogFilterItem,
    id: number,
    parent?: CatalogFilterItem,
    level = 1,
  ): CatalogFilterItem | undefined {
    const children = item.children ? item.children.slice() : []
    // добавляется поле `level` и `parent`(если имеется), удаляется `children`
    const _item = Object.assign({}, item, {
      level,
      parent,
      children: undefined,
    } as Partial<CatalogFilterItem>)
    if (_item.id === id) {
      return _item
    }
    level++
    for (const child of children) {
      const result = findFilterByIdWithParents(child, id, _item, level)
      if (result) {
        return result
      }
    }
  }

  const filtersTitle = computed(() => {
    const _values = Object.assign({}, _filter.value.values)
    const _options = Object.assign({}, _filter.value.options)
    // значения выбранных фильтров, по ключам фильтров
    // `size` - children typePet
    const values = [
      CatalogFilterKeysEnum.typePet,
      CatalogFilterKeysEnum.age,
      CatalogFilterKeysEnum.typeFeed,
      CatalogFilterKeysEnum.taste,
    ].reduce((acc, k) => {
      const val = _values[k]
      if (val) {
        acc[k] = val
      }
      return acc
    }, {} as CatalogFilterValues)
    let options: CatalogFilterItem[] = []
    // формирование опций фильтров
    for (const [_key, id] of Object.entries(values)) {
      const key = _key as CatalogFilterKeysEnum
      const filter = findFilterByIdWithParents(
        _options[key as CatalogFilterKeysEnum],
        id,
      )
      if (filter) {
        // если выбран размер питомца, то `typePet` разделяется на два фильтра: `typePet` и `size`
        if (key === CatalogFilterKeysEnum.typePet && (filter.level ?? 0) > 2) {
          options.push(
            {
              ...filter.parent!,
              key: CatalogFilterKeysEnum.typePet,
            },
            {
              ...filter,
              key: CatalogFilterKeysEnum.size,
            },
          )
        } else {
          filter.key = key as CatalogFilterKeysEnum
          options.push(filter)
        }
      }
    }
    // фильтрация, сортировка по `position` и замена `tegTitle`, если имеется
    options = options
      .filter((item) => item.position !== null || item.position !== undefined)
      .sort((a, b) => a.position - b.position)
      .map((item) => ({
        ...item,
        name: item.tegTitle ? item.tegTitle : item.name,
      }))
    // тексты с отрицательным `position` должны быть слева, остальные справа
    // индекс начала правой стороны
    const rightStart = findIndex(options, (item) => item.position >= 0)
    // формирование левой и правой стороны
    // если начало правой стороны не найдено, то все попадают в левую сторону
    const texts = [
      options
        .slice(0, rightStart === -1 ? undefined : rightStart)
        .map((item) => (item.tegTitle ? item.tegTitle : item.name))
        .join(' '),
      rightStart === -1
        ? ''
        : options
            .slice(rightStart)
            .map((item) => (item.tegTitle ? item.tegTitle : item.name))
            .join(' '),
    ]
    // добавляем "для" в правую сторону, если имеется выбранный фильтр
    if (texts[1]) {
      texts[1] = `для ${texts[1]}`
    }
    // только при наличии выбранных фильтров
    if (options.length) {
      return texts
    }
  })

  const title = computed(() => {
    if (seoTag.value?.title) {
      return seoTag.value.title
    }
    if (filtersTitle.value?.length) {
      const trTemplate = `pages.${String(route.name)}.optionalTitleTemplate`
      return te(trTemplate)
        ? t(trTemplate, filtersTitle.value)
        : filtersTitle.value.join(' ')
    }
    return _title.value
  })

  const heading = computed(() => {
    if (seoTag.value?.heading) {
      return seoTag.value.heading
    }
    if (filtersTitle.value?.length) {
      const texts = filtersTitle.value.slice()
      texts[0] = capitalizeFirstLetter(texts[0])
      const trTemplate = `pages.${String(route.name)}.optionalHeadingTemplate`
      return te(trTemplate) ? t(trTemplate, texts) : texts.join(' ')
    }
    return _heading.value
  })

  const description = computed(() => {
    if (seoTag.value?.description) {
      return seoTag.value.description
    }
    if (filtersTitle.value?.length) {
      const trTemplate = `pages.${String(route.name)}.optionalTitleTemplate`
      const title = te(trTemplate)
        ? t(trTemplate, filtersTitle.value)
        : filtersTitle.value.join(' ')
      return `${title} в Москве с Доставкой по всей РФ. Выгодные цены и акции.`
    }
    return _description.value
  })

  useSeoMeta({
    title,
    ogTitle: title,
    description,
    ogDescription: description,
  })

  return {
    title,
    description,
    heading,
  }
}

export function useProductPageHead(_product: Ref<Product | null | undefined>) {
  const route = useRoute()
  const { t, te } = useI18n()
  const { seoTag } = usePageSeoTag()
  const { getDesciption } = useProductDesciption()

  const title = computed(() => {
    if (seoTag.value?.title) {
      return seoTag.value.title
    }
    if (_product.value?.name) {
      const trTemplate = `pages.${String(route.name)}.titleTemplate`
      return te(trTemplate)
        ? t(trTemplate, [_product.value.name])
        : _product.value.name
    }
    return ''
  })

  const description = computed(() => {
    if (seoTag.value?.description) {
      return seoTag.value.description
    }
    if (_product.value?.description) {
      return getDesciption(_product.value.description)
    }
    return ''
  })

  const productImage = computed(() => {
    if (_product.value?.productImages?.length) {
      const mainProductImage = _product.value.productImages.find(
        (item) => item.main,
      )
      if (mainProductImage) {
        return getOpenGraphImage(mainProductImage, _product.value.name)
      }
      const [firstImage] = _product.value.productImages
      return getOpenGraphImage(firstImage, _product.value.name)
    }
  })

  watchEffect(() => {
    route.meta.breadcrumb =
      seoTag.value?.title || _product.value?.name || 'Загружается...'
  })

  function getOpenGraphImage(
    productImage: ProductImage,
    alt: string,
  ): OpenGraphImage {
    const firstLargeJpg = productImage.imageS3.find(
      (item) => item.size === 'large' && item.imageFormat === 'jpeg',
    )
    const url = firstLargeJpg ? firstLargeJpg.url : productImage.url
    return {
      url,
      type: 'image/jpeg',
      width: '1200',
      height: '1200',
      alt,
    }
  }

  useSeoMeta({
    title,
    description,
    ogTitle: title,
    ogDescription: description,
    ogImage: () => productImage.value?.url,
    ogImageType: () => productImage.value?.type,
    ogImageAlt: () => productImage.value?.alt,
    ogImageWidth: () => productImage.value?.width,
    ogImageHeight: () => productImage.value?.height,
  })
}
