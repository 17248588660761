/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryCityArea
 */
export interface DeliveryCityArea {
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityArea
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityArea
     */
    integrationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityArea
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityArea
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityArea
     */
    freeAmount?: number;
    /**
     * Строка с координатами через ;
     * @type {string}
     * @memberof DeliveryCityArea
     */
    coordinates?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityArea
     */
    color?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityArea
     */
    region?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityArea
     */
    beltwayDistanceFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityArea
     */
    beltwayDistanceTo?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryCityArea
     */
    isIntervalForDistance?: boolean;
    /**
     * Если не отмечено, значит снаружи кольцевой OUT_MKAD/OUT_KAD
     * @type {boolean}
     * @memberof DeliveryCityArea
     */
    isInBeltway?: boolean;
}

/**
 * Check if a given object implements the DeliveryCityArea interface.
 */
export function instanceOfDeliveryCityArea(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function DeliveryCityAreaFromJSON(json: any): DeliveryCityArea {
    return DeliveryCityAreaFromJSONTyped(json, false);
}

export function DeliveryCityAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryCityArea {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'freeAmount': !exists(json, 'free_amount') ? undefined : json['free_amount'],
        'coordinates': !exists(json, 'coordinates') ? undefined : json['coordinates'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'beltwayDistanceFrom': !exists(json, 'beltway_distance_from') ? undefined : json['beltway_distance_from'],
        'beltwayDistanceTo': !exists(json, 'beltway_distance_to') ? undefined : json['beltway_distance_to'],
        'isIntervalForDistance': !exists(json, 'is_interval_for_distance') ? undefined : json['is_interval_for_distance'],
        'isInBeltway': !exists(json, 'is_in_beltway') ? undefined : json['is_in_beltway'],
    };
}

export function DeliveryCityAreaToJSON(value?: DeliveryCityArea | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'name': value.name,
        'price': value.price,
        'free_amount': value.freeAmount,
        'coordinates': value.coordinates,
        'color': value.color,
        'region': value.region,
        'beltway_distance_from': value.beltwayDistanceFrom,
        'beltway_distance_to': value.beltwayDistanceTo,
        'is_interval_for_distance': value.isIntervalForDistance,
        'is_in_beltway': value.isInBeltway,
    };
}

