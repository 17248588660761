/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PetType } from './PetType';
import {
    PetTypeFromJSON,
    PetTypeFromJSONTyped,
    PetTypeToJSON,
} from './PetType';

/**
 * Сериализация информации о породе
 * @export
 * @interface Breed
 */
export interface Breed {
    /**
     * 
     * @type {number}
     * @memberof Breed
     */
    readonly id: number;
    /**
     * 
     * @type {PetType}
     * @memberof Breed
     */
    readonly petType: PetType;
    /**
     * 
     * @type {string}
     * @memberof Breed
     */
    integrationId: string;
    /**
     * 
     * @type {string}
     * @memberof Breed
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Breed
     */
    annualConsumptionKg?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Breed
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Breed
     */
    characteristic?: number | null;
}

/**
 * Check if a given object implements the Breed interface.
 */
export function instanceOfBreed(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "petType" in value;
    isInstance = isInstance && "integrationId" in value;

    return isInstance;
}

export function BreedFromJSON(json: any): Breed {
    return BreedFromJSONTyped(json, false);
}

export function BreedFromJSONTyped(json: any, ignoreDiscriminator: boolean): Breed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'petType': PetTypeFromJSON(json['pet_type']),
        'integrationId': json['integration_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'annualConsumptionKg': !exists(json, 'annual_consumption_kg') ? undefined : json['annual_consumption_kg'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'characteristic': !exists(json, 'characteristic') ? undefined : json['characteristic'],
    };
}

export function BreedToJSON(value?: Breed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'name': value.name,
        'annual_consumption_kg': value.annualConsumptionKg,
        'active': value.active,
        'characteristic': value.characteristic,
    };
}

