/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryAddressTo1c
 */
export interface DeliveryAddressTo1c {
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly deliveryAddressIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly contactIntegrationId: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly address: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly country: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly region: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly regionName: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly street: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly house: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly block: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly apartment: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly zip: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly fias: string;
    /**
     * 
     * @type {any}
     * @memberof DeliveryAddressTo1c
     */
    readonly lastModified: any | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly city: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly comment: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly addressLon: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly addressLat: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly beltwayHit: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressTo1c
     */
    readonly beltwayDistance: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryAddressTo1c
     */
    readonly isActive: boolean;
}

/**
 * Check if a given object implements the DeliveryAddressTo1c interface.
 */
export function instanceOfDeliveryAddressTo1c(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryAddressIntegrationId" in value;
    isInstance = isInstance && "contactIntegrationId" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "regionName" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "house" in value;
    isInstance = isInstance && "block" in value;
    isInstance = isInstance && "apartment" in value;
    isInstance = isInstance && "zip" in value;
    isInstance = isInstance && "fias" in value;
    isInstance = isInstance && "lastModified" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "addressLon" in value;
    isInstance = isInstance && "addressLat" in value;
    isInstance = isInstance && "beltwayHit" in value;
    isInstance = isInstance && "beltwayDistance" in value;
    isInstance = isInstance && "isActive" in value;

    return isInstance;
}

export function DeliveryAddressTo1cFromJSON(json: any): DeliveryAddressTo1c {
    return DeliveryAddressTo1cFromJSONTyped(json, false);
}

export function DeliveryAddressTo1cFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryAddressTo1c {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryAddressIntegrationId': json['DeliveryAddressIntegrationId'],
        'contactIntegrationId': json['ContactIntegrationId'],
        'address': json['Address'],
        'country': json['Country'],
        'region': json['Region'],
        'regionName': json['RegionName'],
        'street': json['Street'],
        'house': json['House'],
        'block': json['Block'],
        'apartment': json['Apartment'],
        'zip': json['Zip'],
        'fias': json['Fias'],
        'lastModified': json['LastModified'],
        'city': json['City'],
        'comment': json['Comment'],
        'addressLon': json['AddressLon'],
        'addressLat': json['AddressLat'],
        'beltwayHit': json['beltway_hit'],
        'beltwayDistance': json['beltway_distance'],
        'isActive': json['IsActive'],
    };
}

export function DeliveryAddressTo1cToJSON(value?: DeliveryAddressTo1c | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

