/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArticleRequest
 */
export interface ArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    imageId?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ArticleRequest
     */
    content?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof ArticleRequest
     */
    isPinned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleRequest
     */
    isEditing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ArticleRequest
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleRequest
     */
    isArchive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArticleRequest
     */
    site?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ArticleRequest
     */
    editor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleRequest
     */
    editorUuid?: string | null;
}

/**
 * Check if a given object implements the ArticleRequest interface.
 */
export function instanceOfArticleRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ArticleRequestFromJSON(json: any): ArticleRequest {
    return ArticleRequestFromJSONTyped(json, false);
}

export function ArticleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'isPinned': !exists(json, 'is_pinned') ? undefined : json['is_pinned'],
        'isEditing': !exists(json, 'is_editing') ? undefined : json['is_editing'],
        'publishedAt': !exists(json, 'published_at') ? undefined : (json['published_at'] === null ? null : new Date(json['published_at'])),
        'isArchive': !exists(json, 'is_archive') ? undefined : json['is_archive'],
        'site': !exists(json, 'site') ? undefined : json['site'],
        'editor': !exists(json, 'editor') ? undefined : json['editor'],
        'editorUuid': !exists(json, 'editor_uuid') ? undefined : json['editor_uuid'],
    };
}

export function ArticleRequestToJSON(value?: ArticleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'title': value.title,
        'description': value.description,
        'image_id': value.imageId,
        'content': value.content,
        'is_pinned': value.isPinned,
        'is_editing': value.isEditing,
        'published_at': value.publishedAt === undefined ? undefined : (value.publishedAt === null ? null : value.publishedAt.toISOString()),
        'is_archive': value.isArchive,
        'site': value.site,
        'editor': value.editor,
        'editor_uuid': value.editorUuid,
    };
}

