<template>
  <div
    class="fixed bottom-0 z-30 w-full bg-white shadow-[0px_0px_44px_0px_rgb(0_0_0/0.1)] md:hidden"
    :class="!showAction && 'h-[66px]'"
  >
    <Btn
      v-if="false && cart?.totalProductCount && !isCart"
      primary
      to="/cart/"
      class="absolute bottom-[78px] right-2 z-10 flex-col px-4 py-6"
    >
      <div class="text-3">
        {{ n(cart?.totalOrderUserPrice ?? 0, 'currency') }}
      </div>
      <div class="text-4">{{ cart?.totalProductCount }} шт.</div>
    </Btn>
    <slot name="action_cart" />
    <div
      class="mx-auto flex h-full max-w-[345px] items-center justify-around py-2"
    >
      <Btn
        v-for="item in tabItems"
        :key="item.path"
        :to="item.auth ? undefined : item.path"
        secondary
        :class="[
          'w-[42px] p-0',
          $route.path === item.path
            ? 'text-icon-accent'
            : 'text-icon-secondary',
        ]"
        @click="item.auth && goToAuthPage(item.path)"
      >
        <div class="flex flex-col items-center">
          <Icon :name="item.icon" class="h-6 w-6" />
          <div v-if="!hideTitle" class="text-[10px]">
            {{ item.title }}
          </div>
        </div>

        <div
          v-if="item.path === '/favorites/' && favoriteIds.length > 0"
          class="bg-primary absolute -right-2 -top-2 flex h-4 min-w-[1rem] items-center justify-center rounded-full px-1 text-white shadow-[0px_4px_4px_0px_rgb(0_0_0/0.1)]"
        >
          <span class="text-xs leading-none">{{ favoriteIds.length }}</span>
        </div>
        <div
          v-else-if="item.path === '/compare/' && compareIds.length > 0"
          class="bg-primary absolute -right-2 -top-2 flex h-4 min-w-[1rem] items-center justify-center rounded-full px-1 text-white shadow-[0px_4px_4px_0px_rgb(0_0_0/0.1)]"
        >
          <span class="text-xs leading-none">{{ compareIds.length }}</span>
        </div>
        <div
          v-else-if="item.path === '/cart/' && cart?.totalProductCount > 0"
          class="bg-primary absolute -right-2 -top-2 flex h-4 min-w-[1rem] items-center justify-center rounded-full px-1 text-white shadow-[0px_4px_4px_0px_rgb(0_0_0/0.1)]"
        >
          <span class="text-xs leading-none">{{
            cart?.totalProductCount
          }}</span>
        </div>
      </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
type TabItems = { path: string; icon: string; title: string; auth?: boolean }

const props = defineProps<{
  hideCompare?: boolean
  hideCart?: boolean
  hideTitle?: boolean
  showAction?: boolean
  homeLogo?: string
}>()

const route = useRoute()
const { n } = useI18n()
const { loggedIn, login } = useAuth()
const profileStore = useProfileStore()
const cartStore = useCartStore()

const isCart = computed(() => route.name === 'cart')
const tabItems = computed(
  () =>
    [
      {
        path: '/',
        icon: props.homeLogo || 'home',
        title: 'Главная',
      },
      {
        path: '/catalog/',
        icon: 'catalog',
        title: 'Каталог',
      },
      !props.hideCompare && {
        path: '/compare/',
        icon: 'compare',
        title: 'Сравнение',
      },
      {
        path: '/favorites/',
        icon: 'favorite-rounded',
        title: 'Избранное',
      },
      {
        auth: true,
        path: '/profile/',
        icon: 'user-1',
        title: 'Профиль',
      },
      !props.hideCart && {
        path: '/cart/',
        icon: 'cart',
        title: 'Корзина',
      },
    ].filter(Boolean) as TabItems[],
)
const favoriteIds = computed(() => profileStore.favoriteIds)
const compareIds = computed(() => profileStore.compareIds)
const cart = computed(() => cartStore.cart)

async function goToAuthPage(path: string) {
  if (loggedIn.value) {
    await navigateTo(path)
  } else {
    await login(path)
  }
}
</script>
