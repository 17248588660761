/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpecialNeed
 */
export interface SpecialNeed {
    /**
     * 
     * @type {number}
     * @memberof SpecialNeed
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SpecialNeed
     */
    integrationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecialNeed
     */
    name?: string;
}

/**
 * Check if a given object implements the SpecialNeed interface.
 */
export function instanceOfSpecialNeed(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function SpecialNeedFromJSON(json: any): SpecialNeed {
    return SpecialNeedFromJSONTyped(json, false);
}

export function SpecialNeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialNeed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function SpecialNeedToJSON(value?: SpecialNeed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'name': value.name,
    };
}

