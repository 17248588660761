/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MinMaxValue } from './MinMaxValue';
import {
    MinMaxValueFromJSON,
    MinMaxValueFromJSONTyped,
    MinMaxValueToJSON,
} from './MinMaxValue';

/**
 * 
 * @export
 * @interface BaseFilter
 */
export interface BaseFilter {
    /**
     * 
     * @type {MinMaxValue}
     * @memberof BaseFilter
     */
    weight: MinMaxValue;
    /**
     * 
     * @type {MinMaxValue}
     * @memberof BaseFilter
     */
    price: MinMaxValue;
}

/**
 * Check if a given object implements the BaseFilter interface.
 */
export function instanceOfBaseFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function BaseFilterFromJSON(json: any): BaseFilter {
    return BaseFilterFromJSONTyped(json, false);
}

export function BaseFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weight': MinMaxValueFromJSON(json['weight']),
        'price': MinMaxValueFromJSON(json['price']),
    };
}

export function BaseFilterToJSON(value?: BaseFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weight': MinMaxValueToJSON(value.weight),
        'price': MinMaxValueToJSON(value.price),
    };
}

