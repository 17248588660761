/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuccessResponsePostFilial
 */
export interface SuccessResponsePostFilial {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponsePostFilial
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponsePostFilial
     */
    regionName: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponsePostFilial
     */
    filial: string;
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponsePostFilial
     */
    defaultRegion: boolean;
}

/**
 * Check if a given object implements the SuccessResponsePostFilial interface.
 */
export function instanceOfSuccessResponsePostFilial(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "regionName" in value;
    isInstance = isInstance && "filial" in value;
    isInstance = isInstance && "defaultRegion" in value;

    return isInstance;
}

export function SuccessResponsePostFilialFromJSON(json: any): SuccessResponsePostFilial {
    return SuccessResponsePostFilialFromJSONTyped(json, false);
}

export function SuccessResponsePostFilialFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponsePostFilial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['region'],
        'regionName': json['region_name'],
        'filial': json['filial'],
        'defaultRegion': json['default_region'],
    };
}

export function SuccessResponsePostFilialToJSON(value?: SuccessResponsePostFilial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'region_name': value.regionName,
        'filial': value.filial,
        'default_region': value.defaultRegion,
    };
}

