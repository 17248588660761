// @ts-ignore
import Pikaday from 'pikaday'

export default defineNuxtPlugin(() => {
  const ru = {
    previousMonth: 'Предыдущий месяц',
    nextMonth: 'Следующий месяц',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
    weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  }
  return {
    provide: {
      pikaday: (opt: any, locale = 'ru') => {
        if (locale === 'ru') {
          opt.i18n = ru
        }
        return new Pikaday(opt)
      },
    },
  }
})
