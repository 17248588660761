<template>
  <div>
    <h2 class="text-blue-gray-600 my-3 text-lg font-semibold">
      История поиска
    </h2>
    <div
      v-for="phrase in props.phrases"
      :key="phrase.phrase"
      class="grid max-w-full cursor-pointer grid-cols-[auto_80%_auto] items-center [&:not(:last-child)]:mb-2"
    >
      <Icon name="clock-rewind" class="text-blue-gray-400" />
      <span
        class="text-blue-gray-600 ml-3 mr-7 overflow-hidden text-ellipsis"
        @click="emit('select', phrase.phrase)"
        >{{ phrase.phrase }}</span
      >
      <Icon
        name="close"
        class="text-blue-gray-400 h-6 w-6 justify-self-end"
        @click="emit('delete', phrase.id)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface Phrase {
  id: number
  phrase: string
}

const props = defineProps<{ phrases: Phrase[] }>()
const emit = defineEmits<{
  (e: 'select', phrase: string): void
  (e: 'delete', id: number): void
}>()
</script>
