/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Picture
 */
export interface Picture {
    /**
     * 
     * @type {number}
     * @memberof Picture
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Picture
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Picture
     */
    image: string;
    /**
     * 
     * @type {string}
     * @memberof Picture
     */
    readonly webp: string;
    /**
     * 
     * @type {string}
     * @memberof Picture
     */
    readonly png: string;
}

/**
 * Check if a given object implements the Picture interface.
 */
export function instanceOfPicture(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "webp" in value;
    isInstance = isInstance && "png" in value;

    return isInstance;
}

export function PictureFromJSON(json: any): Picture {
    return PictureFromJSONTyped(json, false);
}

export function PictureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Picture {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'image': json['image'],
        'webp': json['webp'],
        'png': json['png'],
    };
}

export function PictureToJSON(value?: Picture | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'image': value.image,
    };
}

