/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `manual` - Ручное
 * * `first_order_promo_code` - Промокод после первого заказа
 * * `register_guest` - Регистрация гостя
 * * `greetings` - Приветственное
 * * `order_status` - Статус заказа
 * * `favorite_product_state` - Состояние товара в избранном
 * * `viewed_product` - Просмотренные товары
 * * `cart_await` - Брошенная корзина
 * * `product_stock` - Остаток товара
 * @export
 */
export const NotificationDistributorTypeEnum = {
    Manual: 'manual',
    FirstOrderPromoCode: 'first_order_promo_code',
    RegisterGuest: 'register_guest',
    Greetings: 'greetings',
    OrderStatus: 'order_status',
    FavoriteProductState: 'favorite_product_state',
    ViewedProduct: 'viewed_product',
    CartAwait: 'cart_await',
    ProductStock: 'product_stock'
} as const;
export type NotificationDistributorTypeEnum = typeof NotificationDistributorTypeEnum[keyof typeof NotificationDistributorTypeEnum];


export function NotificationDistributorTypeEnumFromJSON(json: any): NotificationDistributorTypeEnum {
    return NotificationDistributorTypeEnumFromJSONTyped(json, false);
}

export function NotificationDistributorTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDistributorTypeEnum {
    return json as NotificationDistributorTypeEnum;
}

export function NotificationDistributorTypeEnumToJSON(value?: NotificationDistributorTypeEnum | null): any {
    return value as any;
}

