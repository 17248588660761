<template>
  <IconComp />
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed } from '#imports'

const props = defineProps<{
  name: IconName
}>()

// dynamic import with glob example
// https://github.com/jpkleemans/vite-svg-loader/issues/66#issuecomment-1248958470
const modules = import.meta.glob<any>('../assets/*.svg', {
  query: '?component',
})

const IconComp = computed(() =>
  defineAsyncComponent({
    loader: modules[`../assets/${props.name}.svg`],
    suspensible: false,
    onError(error) {
      // eslint-disable-next-line no-console
      console.error(
        `[@valta/icons]: Файл иконки '${props.name}'не найден в папке 'packages/icons/assets'\n`,
        error,
      )
    },
  }),
)
</script>
