/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadRequest
 */
export interface LeadRequest {
    /**
     * 
     * @type {string}
     * @memberof LeadRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof LeadRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LeadRequest
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadRequest
     */
    formName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadRequest
     */
    region?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LeadRequest
     */
    site?: number | null;
}

/**
 * Check if a given object implements the LeadRequest interface.
 */
export function instanceOfLeadRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function LeadRequestFromJSON(json: any): LeadRequest {
    return LeadRequestFromJSONTyped(json, false);
}

export function LeadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['full_name'],
        'email': json['email'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'formName': !exists(json, 'form_name') ? undefined : json['form_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'site': !exists(json, 'site') ? undefined : json['site'],
    };
}

export function LeadRequestToJSON(value?: LeadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
        'email': value.email,
        'message': value.message,
        'form_name': value.formName,
        'phone': value.phone,
        'region': value.region,
        'site': value.site,
    };
}

