/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CdekCity
 */
export interface CdekCity {
    /**
     * 
     * @type {string}
     * @memberof CdekCity
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof CdekCity
     */
    cityCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CdekCity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CdekCity
     */
    workTime?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CdekCity
     */
    phones?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CdekCity
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof CdekCity
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof CdekCity
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof CdekCity
     */
    addressFull?: string;
}

/**
 * Check if a given object implements the CdekCity interface.
 */
export function instanceOfCdekCity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CdekCityFromJSON(json: any): CdekCity {
    return CdekCityFromJSONTyped(json, false);
}

export function CdekCityFromJSONTyped(json: any, ignoreDiscriminator: boolean): CdekCity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'cityCode': !exists(json, 'city_code') ? undefined : json['city_code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workTime': !exists(json, 'work_time') ? undefined : json['work_time'],
        'phones': !exists(json, 'phones') ? undefined : json['phones'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'addressFull': !exists(json, 'address_full') ? undefined : json['address_full'],
    };
}

export function CdekCityToJSON(value?: CdekCity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'city_code': value.cityCode,
        'name': value.name,
        'work_time': value.workTime,
        'phones': value.phones,
        'type': value.type,
        'longitude': value.longitude,
        'latitude': value.latitude,
        'address_full': value.addressFull,
    };
}

