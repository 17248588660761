<template>
  <picture class="flex h-full items-center justify-center">
    <source
      v-for="(item, i) in sources"
      :key="i"
      :srcset="encodeURI(item.srcset)"
      :type="item.type"
      :media="item.media"
    />
    <img v-if="src" v-bind="$attrs" :src="src" />
    <div v-else class="w-full">
      <ProductImageEmpty />
    </div>
  </picture>
</template>

<script setup lang="ts">
import type { ImageS3 } from '@/openapi_fetch'

type ImageSize = 'large' | 'medium' | 'small'

const props = withDefaults(
  defineProps<{
    items?: ImageS3[]
    sizes?: ImageSize[]
    fallbackImageUrl?: string
  }>(),
  {
    sizes: () => ['large', 'medium'],
  },
)

const src = computed(
  () =>
    props.fallbackImageUrl ||
    (props.items
      ? props.items.find((item) => item.imageFormat !== 'webp')?.url
      : null),
)

const sources = computed(() => {
  const items = props.items || []

  return props.sizes
    .reduce<ImageS3[]>((acc, size) => {
      acc.push(...getSizeImages(items, size))
      return acc
    }, [])
    .map((item) => ({
      srcset: item.url,
      type: `image/${item.imageFormat}`,
      media: undefined && getSizeMedia(item.size), // TODO rm undefined
    }))
})

function getSizeImages(items: ImageS3[], size: ImageSize) {
  return items
    .filter((item) => item.size === size)
    .sort((a, b) =>
      a.imageFormat === 'webp' ? -1 : b.imageFormat === 'webp' ? 1 : 0,
    )
}

function getSizeMedia(size: ImageSize) {
  switch (size) {
    case 'large':
      return '(min-width: 1280px)'
    case 'medium':
      return '(min-width: 1024px)'
    default:
      return undefined
  }
}
</script>
