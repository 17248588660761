<template>
  <Dialog v-model="dialog" fullscreen-on-mobile>
    <FeedbackForm
      :title="title"
      :form-name="formName"
      classes="px-4"
      :is-region="isRegion"
      is-modal
      @close="emit('update:modelValue', false)"
    />

    <Btn
      class="hover:text-primary-hover absolute right-6 top-6 lg:right-8 lg:top-8"
      tabindex="-1"
      @click="close"
    >
      <Icon name="close" class="h-6 w-6 lg:h-8 lg:w-8" />
    </Btn>
  </Dialog>
</template>

<script setup lang="ts">
interface Props {
  modelValue?: boolean
  title?: string
  formName?: string
  isRegion?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  title: 'Задать вопрос',
  formName: 'modal',
  isRegion: true,
})

const emit = defineEmits(['update:modelValue'])

const dialog = useVModel(props, 'modelValue', emit, { passive: true })

function close() {
  dialog.value = false
}
</script>
