/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddProductToCompareRequest,
  AddProductToFeaturedRequest,
  BadRequestAddProductToCompare,
  BadRequestAddProductToFeatured,
  BadRequestCreateTemplate,
  BadRequestOrder,
  BadRequestTemplateOrder,
  Cart,
  CartReserveResult,
  CompareProduct,
  CompareProductItem,
  CreateTemplateRequest,
  FeaturedProducts,
  FeaturedProductsItem,
  MetaOrdersList,
  Order,
  OrderItemAddDeleteRequest,
  OrderRequest,
  OrderStatus,
  PageNotFound,
  PatchedOrderRequest,
  PatchedTemplateOrderRequest,
  Status,
  TemplateOrder,
  TemplateOrderRequest,
} from '../models';
import {
    AddProductToCompareRequestFromJSON,
    AddProductToCompareRequestToJSON,
    AddProductToFeaturedRequestFromJSON,
    AddProductToFeaturedRequestToJSON,
    BadRequestAddProductToCompareFromJSON,
    BadRequestAddProductToCompareToJSON,
    BadRequestAddProductToFeaturedFromJSON,
    BadRequestAddProductToFeaturedToJSON,
    BadRequestCreateTemplateFromJSON,
    BadRequestCreateTemplateToJSON,
    BadRequestOrderFromJSON,
    BadRequestOrderToJSON,
    BadRequestTemplateOrderFromJSON,
    BadRequestTemplateOrderToJSON,
    CartFromJSON,
    CartToJSON,
    CartReserveResultFromJSON,
    CartReserveResultToJSON,
    CompareProductFromJSON,
    CompareProductToJSON,
    CompareProductItemFromJSON,
    CompareProductItemToJSON,
    CreateTemplateRequestFromJSON,
    CreateTemplateRequestToJSON,
    FeaturedProductsFromJSON,
    FeaturedProductsToJSON,
    FeaturedProductsItemFromJSON,
    FeaturedProductsItemToJSON,
    MetaOrdersListFromJSON,
    MetaOrdersListToJSON,
    OrderFromJSON,
    OrderToJSON,
    OrderItemAddDeleteRequestFromJSON,
    OrderItemAddDeleteRequestToJSON,
    OrderRequestFromJSON,
    OrderRequestToJSON,
    OrderStatusFromJSON,
    OrderStatusToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PatchedOrderRequestFromJSON,
    PatchedOrderRequestToJSON,
    PatchedTemplateOrderRequestFromJSON,
    PatchedTemplateOrderRequestToJSON,
    StatusFromJSON,
    StatusToJSON,
    TemplateOrderFromJSON,
    TemplateOrderToJSON,
    TemplateOrderRequestFromJSON,
    TemplateOrderRequestToJSON,
} from '../models';

export interface ManagmentCartCreateRequest {
    orderItemAddDeleteRequest: Array<OrderItemAddDeleteRequest>;
}

export interface ManagmentCartDestroyRequest {
    orderId?: number;
    productId?: Array<number>;
    quantity?: Array<number>;
}

export interface ManagmentCompareCreateRequest {
    addProductToCompareRequest: AddProductToCompareRequest;
}

export interface ManagmentCompareDestroyRequest {
    id: number;
}

export interface ManagmentFeaturedCreateRequest {
    addProductToFeaturedRequest: AddProductToFeaturedRequest;
}

export interface ManagmentFeaturedDestroyRequest {
    id: number;
}

export interface ManagmentNotificationRetrieveRequest {
    operation?: string;
    orderNumber?: string;
    status?: number;
}

export interface ManagmentOrderB4pRetrieveRequest {
    id: number;
}

export interface ManagmentOrderCreateRequest {
    orderRequest: OrderRequest;
}

export interface ManagmentOrderListRequest {
    delivering?: boolean;
    draft?: boolean;
    id?: number;
    ordering?: string;
    statusIntegrationId?: string;
    template?: boolean;
}

export interface ManagmentOrderPartialUpdateRequest {
    id: number;
    patchedOrderRequest?: PatchedOrderRequest;
}

export interface ManagmentOrderPaymentCreateRequest {
    id: number;
}

export interface ManagmentOrderRetrieveRequest {
    id: number;
}

export interface ManagmentOrderTemplateCreateRequest {
    id: number;
    createTemplateRequest: CreateTemplateRequest;
}

export interface ManagmentOrderUpdateRequest {
    id: number;
    orderRequest: OrderRequest;
}

export interface ManagmentReserveCreateRequest {
    orderId?: number;
}

export interface ManagmentTemplatesCancelCreateRequest {
    id: number;
}

export interface ManagmentTemplatesCreateRequest {
    templateOrderRequest?: TemplateOrderRequest;
}

export interface ManagmentTemplatesDestroyRequest {
    id: number;
}

export interface ManagmentTemplatesPartialUpdateRequest {
    id: number;
    patchedTemplateOrderRequest?: PatchedTemplateOrderRequest;
}

export interface ManagmentTemplatesRetrieveRequest {
    id: number;
}

export interface ManagmentTemplatesUpdateRequest {
    id: number;
    templateOrderRequest?: TemplateOrderRequest;
}

/**
 * 
 */
export class ManagmentApi extends runtime.BaseAPI {

    /**
     * Корзина заказ предоставление для работы с корзиной. на страницах каталога, товара и корзине. параметр user_price расчитывается на лету. скидка с купона записывается в объект заказа - manual_discount автоматическая скидка записывается в товар корзины - automatic_discount скрываем оюъект товара если есть в заказе после резерва.  Корзина шаблон автозаказа В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа, если такой создан.  - добавить (POST) товар  - удалить (DELETE) товар
     * Запрос на добавление товара в корзину.
     */
    async managmentCartCreateRaw(requestParameters: ManagmentCartCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cart>> {
        if (requestParameters.orderItemAddDeleteRequest === null || requestParameters.orderItemAddDeleteRequest === undefined) {
            throw new runtime.RequiredError('orderItemAddDeleteRequest','Required parameter requestParameters.orderItemAddDeleteRequest was null or undefined when calling managmentCartCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/cart/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.orderItemAddDeleteRequest.map(OrderItemAddDeleteRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * Корзина заказ предоставление для работы с корзиной. на страницах каталога, товара и корзине. параметр user_price расчитывается на лету. скидка с купона записывается в объект заказа - manual_discount автоматическая скидка записывается в товар корзины - automatic_discount скрываем оюъект товара если есть в заказе после резерва.  Корзина шаблон автозаказа В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа, если такой создан.  - добавить (POST) товар  - удалить (DELETE) товар
     * Запрос на добавление товара в корзину.
     */
    async managmentCartCreate(requestParameters: ManagmentCartCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cart> {
          try{
        const response = await this.managmentCartCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Корзина заказ предоставление для работы с корзиной. на страницах каталога, товара и корзине. параметр user_price расчитывается на лету. скидка с купона записывается в объект заказа - manual_discount автоматическая скидка записывается в товар корзины - automatic_discount скрываем оюъект товара если есть в заказе после резерва.  Корзина шаблон автозаказа В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа, если такой создан.  - добавить (POST) товар  - удалить (DELETE) товар
     * Запрос на удаление товара из корзины.
     */
    async managmentCartDestroyRaw(requestParameters: ManagmentCartDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cart>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        if (requestParameters.productId) {
            queryParameters['product_id'] = requestParameters.productId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.quantity) {
            queryParameters['quantity'] = requestParameters.quantity.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/cart/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * Корзина заказ предоставление для работы с корзиной. на страницах каталога, товара и корзине. параметр user_price расчитывается на лету. скидка с купона записывается в объект заказа - manual_discount автоматическая скидка записывается в товар корзины - automatic_discount скрываем оюъект товара если есть в заказе после резерва.  Корзина шаблон автозаказа В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа, если такой создан.  - добавить (POST) товар  - удалить (DELETE) товар
     * Запрос на удаление товара из корзины.
     */
    async managmentCartDestroy(requestParameters: ManagmentCartDestroyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cart> {
          try{
        const response = await this.managmentCartDestroyRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Корзина заказ предоставление для работы с корзиной. на страницах каталога, товара и корзине. параметр user_price расчитывается на лету. скидка с купона записывается в объект заказа - manual_discount автоматическая скидка записывается в товар корзины - automatic_discount скрываем оюъект товара если есть в заказе после резерва.  Корзина шаблон автозаказа В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа, если такой создан.  - добавить (POST) товар  - удалить (DELETE) товар
     * Запрос на получение товаров из корзины.
     */
    async managmentCartRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Cart>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/cart/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartFromJSON(jsonValue));
    }

    /**
     * Корзина заказ предоставление для работы с корзиной. на страницах каталога, товара и корзине. параметр user_price расчитывается на лету. скидка с купона записывается в объект заказа - manual_discount автоматическая скидка записывается в товар корзины - automatic_discount скрываем оюъект товара если есть в заказе после резерва.  Корзина шаблон автозаказа В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа, если такой создан.  - добавить (POST) товар  - удалить (DELETE) товар
     * Запрос на получение товаров из корзины.
     */
    async managmentCartRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Cart> {
          try{
        const response = await this.managmentCartRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Товары для сравнения
     * Запрос на добавление товара в сравнение.
     */
    async managmentCompareCreateRaw(requestParameters: ManagmentCompareCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompareProduct>> {
        if (requestParameters.addProductToCompareRequest === null || requestParameters.addProductToCompareRequest === undefined) {
            throw new runtime.RequiredError('addProductToCompareRequest','Required parameter requestParameters.addProductToCompareRequest was null or undefined when calling managmentCompareCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/compare/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProductToCompareRequestToJSON(requestParameters.addProductToCompareRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompareProductFromJSON(jsonValue));
    }

    /**
     * Товары для сравнения
     * Запрос на добавление товара в сравнение.
     */
    async managmentCompareCreate(requestParameters: ManagmentCompareCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompareProduct> {
          try{
        const response = await this.managmentCompareCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Товары для сравнения
     * Запрос на удаление товара из списка сравнения.
     */
    async managmentCompareDestroyRaw(requestParameters: ManagmentCompareDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompareProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentCompareDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/compare/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompareProductFromJSON(jsonValue));
    }

    /**
     * Товары для сравнения
     * Запрос на удаление товара из списка сравнения.
     */
    async managmentCompareDestroy(requestParameters: ManagmentCompareDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompareProduct> {
          try{
        const response = await this.managmentCompareDestroyRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Товары для сравнения
     * Запрос на получение списка фильтров для сравнения товаров.
     */
    async managmentCompareFiltersListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/compare/filters/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Товары для сравнения
     * Запрос на получение списка фильтров для сравнения товаров.
     */
    async managmentCompareFiltersList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
          try{
        const response = await this.managmentCompareFiltersListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Товары для сравнения
     * Запрос на получение списка товаров для сравнения.
     */
    async managmentCompareListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompareProductItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/compare/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompareProductItemFromJSON));
    }

    /**
     * Товары для сравнения
     * Запрос на получение списка товаров для сравнения.
     */
    async managmentCompareList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompareProductItem>> {
          try{
        const response = await this.managmentCompareListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Избранные товары
     * Запрос на добавление товара в избранное.
     */
    async managmentFeaturedCreateRaw(requestParameters: ManagmentFeaturedCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeaturedProducts>> {
        if (requestParameters.addProductToFeaturedRequest === null || requestParameters.addProductToFeaturedRequest === undefined) {
            throw new runtime.RequiredError('addProductToFeaturedRequest','Required parameter requestParameters.addProductToFeaturedRequest was null or undefined when calling managmentFeaturedCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/featured/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddProductToFeaturedRequestToJSON(requestParameters.addProductToFeaturedRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeaturedProductsFromJSON(jsonValue));
    }

    /**
     * Избранные товары
     * Запрос на добавление товара в избранное.
     */
    async managmentFeaturedCreate(requestParameters: ManagmentFeaturedCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeaturedProducts> {
          try{
        const response = await this.managmentFeaturedCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Избранные товары
     * Запрос на удаление всех товаров из избранной корзины.
     */
    async managmentFeaturedDeleteAllDestroyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/featured/delete-all/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Избранные товары
     * Запрос на удаление всех товаров из избранной корзины.
     */
    async managmentFeaturedDeleteAllDestroy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.managmentFeaturedDeleteAllDestroyRaw(initOverrides);
    }

    /**
     * Избранные товары
     * Запрос на удаление товара из списка избранных.
     */
    async managmentFeaturedDestroyRaw(requestParameters: ManagmentFeaturedDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeaturedProducts>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentFeaturedDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/featured/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeaturedProductsFromJSON(jsonValue));
    }

    /**
     * Избранные товары
     * Запрос на удаление товара из списка избранных.
     */
    async managmentFeaturedDestroy(requestParameters: ManagmentFeaturedDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeaturedProducts> {
          try{
        const response = await this.managmentFeaturedDestroyRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Избранные товары
     * Запрос на получение списка избранной корзины
     */
    async managmentFeaturedListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FeaturedProductsItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/featured/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeaturedProductsItemFromJSON));
    }

    /**
     * Избранные товары
     * Запрос на получение списка избранной корзины
     */
    async managmentFeaturedList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FeaturedProductsItem>> {
          try{
        const response = await this.managmentFeaturedListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async managmentNotificationRetrieveRaw(requestParameters: ManagmentNotificationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.operation !== undefined) {
            queryParameters['operation'] = requestParameters.operation;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/notification/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async managmentNotificationRetrieve(requestParameters: ManagmentNotificationRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.managmentNotificationRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Перекинуть товара с бренд сайта на сайт Brand For Pets.
     * Запрос на отправку брендовой корзины в B4P.
     */
    async managmentOrderB4pRetrieveRaw(requestParameters: ManagmentOrderB4pRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentOrderB4pRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/{id}/b4p/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Перекинуть товара с бренд сайта на сайт Brand For Pets.
     * Запрос на отправку брендовой корзины в B4P.
     */
    async managmentOrderB4pRetrieve(requestParameters: ManagmentOrderB4pRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.managmentOrderB4pRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на создание сущности (заказ).
     */
    async managmentOrderCreateRaw(requestParameters: ManagmentOrderCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.orderRequest === null || requestParameters.orderRequest === undefined) {
            throw new runtime.RequiredError('orderRequest','Required parameter requestParameters.orderRequest was null or undefined when calling managmentOrderCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRequestToJSON(requestParameters.orderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на создание сущности (заказ).
     */
    async managmentOrderCreate(requestParameters: ManagmentOrderCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
          try{
        const response = await this.managmentOrderCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Если есть query параметр draft, выдает OrderHistory.
     * Запрос на получение списка сущностей (заказ).
     */
    async managmentOrderListRaw(requestParameters: ManagmentOrderListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MetaOrdersList>>> {
        const queryParameters: any = {};

        if (requestParameters.delivering !== undefined) {
            queryParameters['delivering'] = requestParameters.delivering;
        }

        if (requestParameters.draft !== undefined) {
            queryParameters['draft'] = requestParameters.draft;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.statusIntegrationId !== undefined) {
            queryParameters['status_integration_id'] = requestParameters.statusIntegrationId;
        }

        if (requestParameters.template !== undefined) {
            queryParameters['template'] = requestParameters.template;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MetaOrdersListFromJSON));
    }

    /**
     * Если есть query параметр draft, выдает OrderHistory.
     * Запрос на получение списка сущностей (заказ).
     */
    async managmentOrderList(requestParameters: ManagmentOrderListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MetaOrdersList>> {
          try{
        const response = await this.managmentOrderListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на обновление полей сущности (заказ).
     */
    async managmentOrderPartialUpdateRaw(requestParameters: ManagmentOrderPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentOrderPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedOrderRequestToJSON(requestParameters.patchedOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на обновление полей сущности (заказ).
     */
    async managmentOrderPartialUpdate(requestParameters: ManagmentOrderPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
          try{
        const response = await this.managmentOrderPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на оплату заказа.
     */
    async managmentOrderPaymentCreateRaw(requestParameters: ManagmentOrderPaymentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentOrderPaymentCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/{id}/payment/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на оплату заказа.
     */
    async managmentOrderPaymentCreate(requestParameters: ManagmentOrderPaymentCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
          try{
        const response = await this.managmentOrderPaymentCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на получение экземпляра сущности (заказ).
     */
    async managmentOrderRetrieveRaw(requestParameters: ManagmentOrderRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentOrderRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на получение экземпляра сущности (заказ).
     */
    async managmentOrderRetrieve(requestParameters: ManagmentOrderRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
          try{
        const response = await this.managmentOrderRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на получение списка статусов.
     */
    async managmentOrderStatusListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderStatus>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/status/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderStatusFromJSON));
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на получение списка статусов.
     */
    async managmentOrderStatusList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderStatus>> {
          try{
        const response = await this.managmentOrderStatusListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на создание шаблона.
     */
    async managmentOrderTemplateCreateRaw(requestParameters: ManagmentOrderTemplateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentOrderTemplateCreate.');
        }

        if (requestParameters.createTemplateRequest === null || requestParameters.createTemplateRequest === undefined) {
            throw new runtime.RequiredError('createTemplateRequest','Required parameter requestParameters.createTemplateRequest was null or undefined when calling managmentOrderTemplateCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/{id}/template/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTemplateRequestToJSON(requestParameters.createTemplateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на создание шаблона.
     */
    async managmentOrderTemplateCreate(requestParameters: ManagmentOrderTemplateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.managmentOrderTemplateCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на обновление сущности (заказ).
     */
    async managmentOrderUpdateRaw(requestParameters: ManagmentOrderUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Order>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentOrderUpdate.');
        }

        if (requestParameters.orderRequest === null || requestParameters.orderRequest === undefined) {
            throw new runtime.RequiredError('orderRequest','Required parameter requestParameters.orderRequest was null or undefined when calling managmentOrderUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/order/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRequestToJSON(requestParameters.orderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderFromJSON(jsonValue));
    }

    /**
     * Заказ на странице оформления заказа. После резерва добавляется объект доставки для 1с. стоимость доставки для объекта берётся из поля delivery_price. объект товара скрывается на сайте, сумма доставки передается в поле заказа delivery_price.258e6f1b0b6148a3d48ecf714685e9d32a56d9de заказ оформляется через  update метод. для финального подтверждения заказа передается параметр &confirmed=true параметр is_changed передает состояние заказа после резерва: - после резерва is_changed=False - после люого изменения заказа is_changed=True оформить заказ можно только с is_changed=False  Шаблон автозаказа. Чтобы обычный заказ превратился в шаблон автозаказа, следует заполнить поля:  - delivery_regularity со занчением не меньше 5 дней  - delivery_date с датой не меньше, чтобы отняв от даты 3 дня было сегодня (так шаблон формирует заказы)  В запросе добавьте флаг (?template=true), чтобы:  - получить (GET) шаблон автозаказа  - изменить (PUT) шаблон автозаказа
     * Запрос на обновление сущности (заказ).
     */
    async managmentOrderUpdate(requestParameters: ManagmentOrderUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Order> {
          try{
        const response = await this.managmentOrderUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Метод передает заказ в 1с и обрабатывает ответ. после передачи заказа в 1с в заказе могут быть изменены данные. -количество товара, стоимость, скидка. если при резерве данные заказа не изменены то в заказ добавляем объект доставки. стоимость доставки берется из поля заказа delivery_price. если данные были изменены то в заказе меняется is_changed=True и заказ должен повторно пройти резерв.
     * Запрос на создание резервного заказа.
     */
    async managmentReserveCreateRaw(requestParameters: ManagmentReserveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CartReserveResult>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['order_id'] = requestParameters.orderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/reserve/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CartReserveResultFromJSON(jsonValue));
    }

    /**
     * Метод передает заказ в 1с и обрабатывает ответ. после передачи заказа в 1с в заказе могут быть изменены данные. -количество товара, стоимость, скидка. если при резерве данные заказа не изменены то в заказ добавляем объект доставки. стоимость доставки берется из поля заказа delivery_price. если данные были изменены то в заказе меняется is_changed=True и заказ должен повторно пройти резерв.
     * Запрос на создание резервного заказа.
     */
    async managmentReserveCreate(requestParameters: ManagmentReserveCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CartReserveResult> {
          try{
        const response = await this.managmentReserveCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на отмену авто заказа.
     */
    async managmentTemplatesCancelCreateRaw(requestParameters: ManagmentTemplatesCancelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentTemplatesCancelCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/{id}/cancel/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на отмену авто заказа.
     */
    async managmentTemplatesCancelCreate(requestParameters: ManagmentTemplatesCancelCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
          try{
        const response = await this.managmentTemplatesCancelCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на создание сущности (заказ).
     */
    async managmentTemplatesCreateRaw(requestParameters: ManagmentTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateOrder>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateOrderRequestToJSON(requestParameters.templateOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateOrderFromJSON(jsonValue));
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на создание сущности (заказ).
     */
    async managmentTemplatesCreate(requestParameters: ManagmentTemplatesCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateOrder> {
          try{
        const response = await this.managmentTemplatesCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на удаление сущности (заказ).
     */
    async managmentTemplatesDestroyRaw(requestParameters: ManagmentTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentTemplatesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на удаление сущности (заказ).
     */
    async managmentTemplatesDestroy(requestParameters: ManagmentTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.managmentTemplatesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на получение списка сущностей (заказ).
     */
    async managmentTemplatesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TemplateOrder>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TemplateOrderFromJSON));
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на получение списка сущностей (заказ).
     */
    async managmentTemplatesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TemplateOrder>> {
          try{
        const response = await this.managmentTemplatesListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на обновление полей сущности (заказ).
     */
    async managmentTemplatesPartialUpdateRaw(requestParameters: ManagmentTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentTemplatesPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTemplateOrderRequestToJSON(requestParameters.patchedTemplateOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateOrderFromJSON(jsonValue));
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на обновление полей сущности (заказ).
     */
    async managmentTemplatesPartialUpdate(requestParameters: ManagmentTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateOrder> {
          try{
        const response = await this.managmentTemplatesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на получение экземпляра сущности (заказ).
     */
    async managmentTemplatesRetrieveRaw(requestParameters: ManagmentTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentTemplatesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateOrderFromJSON(jsonValue));
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на получение экземпляра сущности (заказ).
     */
    async managmentTemplatesRetrieve(requestParameters: ManagmentTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateOrder> {
          try{
        const response = await this.managmentTemplatesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на обновление сущности (заказ).
     */
    async managmentTemplatesUpdateRaw(requestParameters: ManagmentTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TemplateOrder>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling managmentTemplatesUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/managment/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TemplateOrderRequestToJSON(requestParameters.templateOrderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TemplateOrderFromJSON(jsonValue));
    }

    /**
     * Ручка для списка шаблонов заказа с вложенным в них последним автозаказом
     * Запрос на обновление сущности (заказ).
     */
    async managmentTemplatesUpdate(requestParameters: ManagmentTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TemplateOrder> {
          try{
        const response = await this.managmentTemplatesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
