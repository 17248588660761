import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { SENTRY_DSN, SENTRY_ENVIRONMENT, APP_NAME, APP_VERSION } =
    useRuntimeConfig().public

  if (SENTRY_DSN && SENTRY_ENVIRONMENT) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      release:
        APP_NAME && APP_VERSION ? `${APP_NAME}@${APP_VERSION}` : undefined,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(
            // @ts-expect-error
            nuxtApp.$router,
          ),
          tracingOrigins: ['localhost', /^\//],
        }),
      ],
      tracesSampleRate: 1.0,
      logErrors: true,
    })
  }
})
