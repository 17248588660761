/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DayWeek } from './DayWeek';
import {
    DayWeekFromJSON,
    DayWeekFromJSONTyped,
    DayWeekToJSON,
} from './DayWeek';
import type { FilialRegion } from './FilialRegion';
import {
    FilialRegionFromJSON,
    FilialRegionFromJSONTyped,
    FilialRegionToJSON,
} from './FilialRegion';
import type { TimeInterval } from './TimeInterval';
import {
    TimeIntervalFromJSON,
    TimeIntervalFromJSONTyped,
    TimeIntervalToJSON,
} from './TimeInterval';

/**
 * 
 * @export
 * @interface DeliveryTimeInterval
 */
export interface DeliveryTimeInterval {
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeInterval
     */
    readonly id: number;
    /**
     * 
     * @type {Array<FilialRegion>}
     * @memberof DeliveryTimeInterval
     */
    readonly regions: Array<FilialRegion>;
    /**
     * 
     * @type {Array<DayWeek>}
     * @memberof DeliveryTimeInterval
     */
    readonly weekDays: Array<DayWeek>;
    /**
     * 
     * @type {Array<TimeInterval>}
     * @memberof DeliveryTimeInterval
     */
    readonly timeIntervals: Array<TimeInterval>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeInterval
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeInterval
     */
    beltwayDistanceFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeInterval
     */
    beltwayDistanceTo?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryTimeInterval
     */
    isIntervalForDistance?: boolean;
    /**
     * Если не отмечено, значит снаружи кольцевой OUT_MKAD/OUT_KAD
     * @type {boolean}
     * @memberof DeliveryTimeInterval
     */
    isInBeltway?: boolean;
}

/**
 * Check if a given object implements the DeliveryTimeInterval interface.
 */
export function instanceOfDeliveryTimeInterval(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "regions" in value;
    isInstance = isInstance && "weekDays" in value;
    isInstance = isInstance && "timeIntervals" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function DeliveryTimeIntervalFromJSON(json: any): DeliveryTimeInterval {
    return DeliveryTimeIntervalFromJSONTyped(json, false);
}

export function DeliveryTimeIntervalFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryTimeInterval {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'regions': ((json['regions'] as Array<any>).map(FilialRegionFromJSON)),
        'weekDays': ((json['week_days'] as Array<any>).map(DayWeekFromJSON)),
        'timeIntervals': ((json['time_intervals'] as Array<any>).map(TimeIntervalFromJSON)),
        'title': json['title'],
        'beltwayDistanceFrom': !exists(json, 'beltway_distance_from') ? undefined : json['beltway_distance_from'],
        'beltwayDistanceTo': !exists(json, 'beltway_distance_to') ? undefined : json['beltway_distance_to'],
        'isIntervalForDistance': !exists(json, 'is_interval_for_distance') ? undefined : json['is_interval_for_distance'],
        'isInBeltway': !exists(json, 'is_in_beltway') ? undefined : json['is_in_beltway'],
    };
}

export function DeliveryTimeIntervalToJSON(value?: DeliveryTimeInterval | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'beltway_distance_from': value.beltwayDistanceFrom,
        'beltway_distance_to': value.beltwayDistanceTo,
        'is_interval_for_distance': value.isIntervalForDistance,
        'is_in_beltway': value.isInBeltway,
    };
}

