/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedPhoneNumberRequest
 */
export interface PatchedPhoneNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedPhoneNumberRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedPhoneNumberRequest
     */
    verificationId?: string;
}

/**
 * Check if a given object implements the PatchedPhoneNumberRequest interface.
 */
export function instanceOfPatchedPhoneNumberRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedPhoneNumberRequestFromJSON(json: any): PatchedPhoneNumberRequest {
    return PatchedPhoneNumberRequestFromJSONTyped(json, false);
}

export function PatchedPhoneNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedPhoneNumberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'verificationId': !exists(json, 'verification_id') ? undefined : json['verification_id'],
    };
}

export function PatchedPhoneNumberRequestToJSON(value?: PatchedPhoneNumberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'verification_id': value.verificationId,
    };
}

