/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliveryAddressTo1c } from './DeliveryAddressTo1c';
import {
    DeliveryAddressTo1cFromJSON,
    DeliveryAddressTo1cFromJSONTyped,
    DeliveryAddressTo1cToJSON,
} from './DeliveryAddressTo1c';
import type { OrderTo1c } from './OrderTo1c';
import {
    OrderTo1cFromJSON,
    OrderTo1cFromJSONTyped,
    OrderTo1cToJSON,
} from './OrderTo1c';
import type { PetsTo1c } from './PetsTo1c';
import {
    PetsTo1cFromJSON,
    PetsTo1cFromJSONTyped,
    PetsTo1cToJSON,
} from './PetsTo1c';
import type { PhoneNumberTo1c } from './PhoneNumberTo1c';
import {
    PhoneNumberTo1cFromJSON,
    PhoneNumberTo1cFromJSONTyped,
    PhoneNumberTo1cToJSON,
} from './PhoneNumberTo1c';

/**
 * 
 * @export
 * @interface BrokerSenderResponse
 */
export interface BrokerSenderResponse {
    /**
     * 
     * @type {string}
     * @memberof BrokerSenderResponse
     */
    messageId: string;
    /**
     * 
     * @type {Array<DeliveryAddressTo1c>}
     * @memberof BrokerSenderResponse
     */
    deliveryAddressList: Array<DeliveryAddressTo1c>;
    /**
     * 
     * @type {Array<PhoneNumberTo1c>}
     * @memberof BrokerSenderResponse
     */
    userContactDetails: Array<PhoneNumberTo1c>;
    /**
     * 
     * @type {Array<OrderTo1c>}
     * @memberof BrokerSenderResponse
     */
    orderList: Array<OrderTo1c>;
    /**
     * 
     * @type {Array<PetsTo1c>}
     * @memberof BrokerSenderResponse
     */
    pets: Array<PetsTo1c>;
}

/**
 * Check if a given object implements the BrokerSenderResponse interface.
 */
export function instanceOfBrokerSenderResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "messageId" in value;
    isInstance = isInstance && "deliveryAddressList" in value;
    isInstance = isInstance && "userContactDetails" in value;
    isInstance = isInstance && "orderList" in value;
    isInstance = isInstance && "pets" in value;

    return isInstance;
}

export function BrokerSenderResponseFromJSON(json: any): BrokerSenderResponse {
    return BrokerSenderResponseFromJSONTyped(json, false);
}

export function BrokerSenderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerSenderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageId': json['MessageId'],
        'deliveryAddressList': ((json['DeliveryAddressList'] as Array<any>).map(DeliveryAddressTo1cFromJSON)),
        'userContactDetails': ((json['UserContactDetails'] as Array<any>).map(PhoneNumberTo1cFromJSON)),
        'orderList': ((json['OrderList'] as Array<any>).map(OrderTo1cFromJSON)),
        'pets': ((json['Pets'] as Array<any>).map(PetsTo1cFromJSON)),
    };
}

export function BrokerSenderResponseToJSON(value?: BrokerSenderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MessageId': value.messageId,
        'DeliveryAddressList': ((value.deliveryAddressList as Array<any>).map(DeliveryAddressTo1cToJSON)),
        'UserContactDetails': ((value.userContactDetails as Array<any>).map(PhoneNumberTo1cToJSON)),
        'OrderList': ((value.orderList as Array<any>).map(OrderTo1cToJSON)),
        'Pets': ((value.pets as Array<any>).map(PetsTo1cToJSON)),
    };
}

