import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const id = useRuntimeConfig().public.GTM_ID
  if (!id) {
    showError('[Error]: GTM_ID is undefined')
  }
  const router = useRouter()
  nuxtApp.vueApp.use(
    createGtm({
      id: id,
      enabled: true,
      loadScript: true,
      vueRouter: router,
    }),
  )
})
