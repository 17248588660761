/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateViewedProductsRequest
 */
export interface CreateViewedProductsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateViewedProductsRequest
     */
    products: Array<number>;
}

/**
 * Check if a given object implements the CreateViewedProductsRequest interface.
 */
export function instanceOfCreateViewedProductsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "products" in value;

    return isInstance;
}

export function CreateViewedProductsRequestFromJSON(json: any): CreateViewedProductsRequest {
    return CreateViewedProductsRequestFromJSONTyped(json, false);
}

export function CreateViewedProductsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateViewedProductsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'products': json['products'],
    };
}

export function CreateViewedProductsRequestToJSON(value?: CreateViewedProductsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'products': value.products,
    };
}

