/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationDistributor } from './NotificationDistributor';
import {
    NotificationDistributorFromJSON,
    NotificationDistributorFromJSONTyped,
    NotificationDistributorToJSON,
} from './NotificationDistributor';
import type { SimpleProductInfo } from './SimpleProductInfo';
import {
    SimpleProductInfoFromJSON,
    SimpleProductInfoFromJSONTyped,
    SimpleProductInfoToJSON,
} from './SimpleProductInfo';

/**
 * product_name field is designed to display a related product name at json.
 * @export
 * @interface NotifierSection
 */
export interface NotifierSection {
    /**
     * 
     * @type {number}
     * @memberof NotifierSection
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof NotifierSection
     */
    notificationType: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotifierSection
     */
    isRead?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotifierSection
     */
    isSeen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof NotifierSection
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {SimpleProductInfo}
     * @memberof NotifierSection
     */
    readonly product: SimpleProductInfo | null;
    /**
     * 
     * @type {NotificationDistributor}
     * @memberof NotifierSection
     */
    distributor: NotificationDistributor;
}

/**
 * Check if a given object implements the NotifierSection interface.
 */
export function instanceOfNotifierSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "notificationType" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "distributor" in value;

    return isInstance;
}

export function NotifierSectionFromJSON(json: any): NotifierSection {
    return NotifierSectionFromJSONTyped(json, false);
}

export function NotifierSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifierSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'notificationType': json['notification_type'],
        'isRead': !exists(json, 'is_read') ? undefined : json['is_read'],
        'isSeen': !exists(json, 'is_seen') ? undefined : json['is_seen'],
        'createdAt': (new Date(json['created_at'])),
        'product': SimpleProductInfoFromJSON(json['product']),
        'distributor': NotificationDistributorFromJSON(json['distributor']),
    };
}

export function NotifierSectionToJSON(value?: NotifierSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notification_type': value.notificationType,
        'is_read': value.isRead,
        'is_seen': value.isSeen,
        'distributor': NotificationDistributorToJSON(value.distributor),
    };
}

