<template>
  <div class="text-blue-gray-700 px-3 shadow-[0_19px_19px_0_rgba(0,0,0,0.1)]">
    <div class="scroll max-h-[426px] overflow-y-auto">
      <slot name="history" />
      <div v-if="showProducts && !props.loading">
        <div
          v-for="p in props.products"
          :key="p.id"
          class="border-placeholder grid grid-cols-[38px_1fr_122px] items-center gap-4 border-b p-4"
        >
          <NuxtLink
            :to="`/product/${p.id}/`"
            tabindex="-1"
            @click="$emit('close')"
          >
            <ProductImage
              :items="p.imageS3"
              :fallback-image-url="p.coverPic"
              :sizes="['medium']"
              :alt="p.name"
              class="h-full w-full object-contain"
            />
          </NuxtLink>
          <div>
            <NuxtLink
              :to="`/product/${p.id}/`"
              tabindex="-1"
              class="text-4 mb-3"
              @click="$emit('close')"
            >
              {{ p.name }}
            </NuxtLink>
            <div class="flex items-center gap-3">
              <div
                class="font-semibold"
                :class="
                  p.discount.discountValue ? 'text-danger' : 'text-primary'
                "
              >
                {{
                  n(
                    p.discount.discountValue ? p.discount.userPrice : p.price,
                    'currency',
                  )
                }}
              </div>
              <div
                v-if="p.discount.discountValue"
                class="text-danger rounded-lg bg-red-50 px-1 py-[2px] text-[10px] font-medium"
              >
                -{{ p.discount.discountPercent }}% скидка
              </div>
              <ProductAvailabilityStatus
                small
                :status="
                  p.quantity <= 0
                    ? 'not_available'
                    : 0 < p.quantity && p.quantity < 10
                      ? 'half'
                      : 'in_stock'
                "
              />
            </div>
          </div>
          <div
            class="p-1"
            :class="{
              'border-placeholder rounded-xl border': getCartItemQuantity(p.id),
            }"
          >
            <div class="grid grid-cols-3 items-center justify-items-center">
              <template v-if="getCartItemQuantity(p.id)">
                <button
                  class="text-primary bg-blue-gray-100 grid h-10 w-10 place-content-center rounded-xl"
                  @click="setCartItem(p, -1)"
                >
                  <Icon name="minus-solid" class="h-4 w-4" />
                </button>
                {{ getCartItemQuantity(p.id) }}
              </template>
              <button
                class="text-primary bg-blue-gray-100 col-start-3 grid h-10 w-10 place-content-center rounded-xl"
                :class="{
                  'bg-blue-gray-50 text-primary-200 cursor-not-allowed':
                    p.quantity <= getCartItemQuantity(p.id),
                }"
                :disabled="p.quantity <= getCartItemQuantity(p.id)"
                @click="setCartItem(p, 1)"
              >
                <Icon
                  v-if="getCartItemQuantity(p.id)"
                  name="plus-solid"
                  class="h-4 w-4"
                />
                <Icon v-else name="basket" class="w-4.5 h-4.5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-4">
      <div v-if="label" class="text-heading-5 flex justify-center">
        {{ label }}
      </div>
      <Btn
        v-else-if="showProducts"
        lg
        primary
        class="w-full"
        @click.stop="$emit('goto')"
      >
        Показать все
      </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductCatalog } from '@/openapi_fetch'

const props = withDefaults(
  defineProps<{
    search?: string
    products: ProductCatalog[]
    phrases?: any[]
    isCatalog?: boolean
    loading?: boolean
    showProducts?: boolean
  }>(),
  {
    search: '',
    products: () => [],
    phrases: () => [],
    loading: false,
    showProducts: false,
  },
)
defineEmits(['goto', 'close'])

const { n } = useI18n()

const cartStore = useCartStore()

const label = computed(() => {
  if (!props.search) {
    return 'Начните вводить текст'
  }
  if (props.loading) {
    return 'Загрузка'
  }
  if (!props.isCatalog && !props.products.length) {
    return 'Ничего не найдено'
  }
})

const getCartItem = (productId: number) => cartStore.productsByIds[productId]
const getCartItemQuantity = (productId: number) =>
  getCartItem(productId)?.quantity ?? 0

function setCartItem(product: ProductCatalog, diff: number) {
  const orderItem = getCartItem(product.id)
  const quantity = (orderItem?.quantity ?? 0) + diff
  const payload = [{ productId: product.id, quantity }]
  const optimisticData = [{ ...orderItem, quantity }]
  try {
    cartStore.queueUpdate(payload, 'createOrDestroy', optimisticData)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[queueUpdate error]', error)
  }
}
</script>
