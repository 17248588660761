/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Banner,
  CrossBanner,
} from '../models';
import {
    BannerFromJSON,
    BannerToJSON,
    CrossBannerFromJSON,
    CrossBannerToJSON,
} from '../models';

export interface BannersAddViewUpdateRequest {
    id: number;
}

/**
 * 
 */
export class BannersApi extends runtime.BaseAPI {

    /**
     * Набор ручек для запроса баннеров из базы данных
     */
    async bannersAddViewUpdateRaw(requestParameters: BannersAddViewUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bannersAddViewUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/banners/{id}/add-view/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Набор ручек для запроса баннеров из базы данных
     */
    async bannersAddViewUpdate(requestParameters: BannersAddViewUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bannersAddViewUpdateRaw(requestParameters, initOverrides);
    }

    /**
     * Набор ручек для запроса сквозных баннеров из базы данных
     * Запрос на получение списка сущностей (сквозной банер).
     */
    async bannersCrossBannersListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CrossBanner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/banners/cross-banners/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CrossBannerFromJSON));
    }

    /**
     * Набор ручек для запроса сквозных баннеров из базы данных
     * Запрос на получение списка сущностей (сквозной банер).
     */
    async bannersCrossBannersList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CrossBanner>> {
          try{
        const response = await this.bannersCrossBannersListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Набор ручек для запроса баннеров из базы данных
     * Запрос на получение списка сущностей (баннер).
     */
    async bannersListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Banner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/banners/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BannerFromJSON));
    }

    /**
     * Набор ручек для запроса баннеров из базы данных
     * Запрос на получение списка сущностей (баннер).
     */
    async bannersList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Banner>> {
          try{
        const response = await this.bannersListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
