function unformatPhoneNumber(val?: string) {
  return ('' + val).replace(/^\+7/, '8').replace(/\D/g, '')
}

function formatPhoneNumber(val?: string) {
  const cleaned = ('' + val).replace(/\D/g, '')
  const match = cleaned.match(/^([8,+7])(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+7 ${match[2]} ${match[3]} ${match[4]} ${match[5]}`
  }
  return val
}

function padLeft(v: number, maxLength = 2, fillString = '0') {
  return String(v).padStart(maxLength, fillString)
}

function toISODate(date: Date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${year}-${padLeft(month)}-${padLeft(day)}`
}

function toISODateTime(date: Date, h = 0, m = 0, s = 0) {
  return `${toISODate(date)}T${padLeft(h)}:${padLeft(m)}:${padLeft(s)}`
}

export default function () {
  return {
    unformatPhoneNumber,
    formatPhoneNumber,
    padLeft,
    toISODate,
    toISODateTime,
  }
}
