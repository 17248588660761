<template>
  <AppHeader :social-items="socialItems" :items="navItems" :phone-to="phoneTo">
    <template #logo>
      <img
        src="@/assets/images/Logo.svg?url"
        width="124"
        height="42"
        alt="логотип"
      />
    </template>
  </AppHeader>

  <div class="grow">
    <Crumbs
      v-if="!/^(index|catalog|cart)/.test(String(route.name))"
      class="container"
    />

    <NuxtPage />
  </div>

  <AppFooter
    class-title="gradient-text"
    class-button="button-hover-gradient"
    :items="isCart ? null : footerItems"
    :social-items="socialItems"
    :class="{ 'hidden lg:block': isCart || isCheckout }"
    :hide-subscribe="isCart || isCheckout"
    :hide-menu="false && isCart"
    :phone-to="phoneTo"
  >
    <template #logo>
      <img
        src="@/assets/images/Logo.svg?url"
        width="201"
        height="70"
        alt="логотип"
      />
    </template>
  </AppFooter>

  <CookieAlert v-if="route.name !== 'rules-cookie'" class="" />

  <Tabbar :hide-compare="true" :class="{ hidden: isCart || isCheckout }" />

  <ClientOnly>
    <DialogWrapper />
  </ClientOnly>
</template>

<script setup lang="ts">
import {
  header as navItems,
  footer as footerItems,
  social as socialItems,
  contacts,
} from '@/constants/navigation'

await useInitApp()

const route = useRoute()
usePageHead()

const isCart = computed(() => route.name === 'cart')
const isCheckout = computed(() => route.name === 'checkout')

const { phoneTo } = contacts
</script>
