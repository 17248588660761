/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverPoints } from './DeliverPoints';
import {
    DeliverPointsFromJSON,
    DeliverPointsFromJSONTyped,
    DeliverPointsToJSON,
} from './DeliverPoints';

/**
 * 
 * @export
 * @interface GetSetDeliveryInfoPickupAddress
 */
export interface GetSetDeliveryInfoPickupAddress {
    /**
     * 
     * @type {string}
     * @memberof GetSetDeliveryInfoPickupAddress
     */
    deliveryType: string | null;
    /**
     * 
     * @type {DeliverPoints}
     * @memberof GetSetDeliveryInfoPickupAddress
     */
    address: DeliverPoints;
}

/**
 * Check if a given object implements the GetSetDeliveryInfoPickupAddress interface.
 */
export function instanceOfGetSetDeliveryInfoPickupAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryType" in value;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function GetSetDeliveryInfoPickupAddressFromJSON(json: any): GetSetDeliveryInfoPickupAddress {
    return GetSetDeliveryInfoPickupAddressFromJSONTyped(json, false);
}

export function GetSetDeliveryInfoPickupAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSetDeliveryInfoPickupAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryType': json['delivery_type'],
        'address': DeliverPointsFromJSON(json['address']),
    };
}

export function GetSetDeliveryInfoPickupAddressToJSON(value?: GetSetDeliveryInfoPickupAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_type': value.deliveryType,
        'address': DeliverPointsToJSON(value.address),
    };
}

