/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationDistributorTypeEnum } from './NotificationDistributorTypeEnum';
import {
    NotificationDistributorTypeEnumFromJSON,
    NotificationDistributorTypeEnumFromJSONTyped,
    NotificationDistributorTypeEnumToJSON,
} from './NotificationDistributorTypeEnum';

/**
 * 
 * @export
 * @interface NotificationDistributor
 */
export interface NotificationDistributor {
    /**
     * 
     * @type {number}
     * @memberof NotificationDistributor
     */
    readonly id: number;
    /**
     * 
     * @type {NotificationDistributorTypeEnum}
     * @memberof NotificationDistributor
     */
    type: NotificationDistributorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDistributor
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDistributor
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDistributor
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDistributor
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDistributor
     */
    buttonText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDistributor
     */
    buttonLink?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDistributor
     */
    isActive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof NotificationDistributor
     */
    readonly createdAt: Date;
}

/**
 * Check if a given object implements the NotificationDistributor interface.
 */
export function instanceOfNotificationDistributor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function NotificationDistributorFromJSON(json: any): NotificationDistributor {
    return NotificationDistributorFromJSONTyped(json, false);
}

export function NotificationDistributorFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDistributor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': NotificationDistributorTypeEnumFromJSON(json['type']),
        'title': json['title'],
        'text': json['text'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'buttonText': !exists(json, 'button_text') ? undefined : json['button_text'],
        'buttonLink': !exists(json, 'button_link') ? undefined : json['button_link'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function NotificationDistributorToJSON(value?: NotificationDistributor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': NotificationDistributorTypeEnumToJSON(value.type),
        'title': value.title,
        'text': value.text,
        'content': value.content,
        'image': value.image,
        'button_text': value.buttonText,
        'button_link': value.buttonLink,
        'is_active': value.isActive,
    };
}

