<template>
  <div class="space-y-6">
    <form class="flex flex-col space-y-6" @submit.prevent="submit">
      <div class="space-y-3">
        <div class="space-y-3">
          <label for="full-name-input"> Ваше имя* </label>
          <input
            id="full-name-input"
            v-model="formModel.fullName"
            required
            placeholder="Ваше имя"
            name="full-name"
            type="text"
            inputmode="text"
            autocomplete="name"
            aria-label="full-name input"
            class="input input_lg placeholder:text-blue-gray-600 mt-3 bg-[#F1F4F8] px-3.5 text-gray-900 lg:px-6"
            @input="clearErrorMessage('full_name')"
          />
          <div v-if="validatedData?.detailData?.full_name" class="text-danger">
            {{ validatedData.detailData.full_name.join(' ') }}
          </div>
        </div>
        <div class="space-y-3">
          <label for="phone-input">
            Номер телефона{{ requiredField === 'phone' ? '*' : '' }}
          </label>
          <input
            id="phone-input"
            v-model="formModel.phone"
            v-maska:[phoneMaskOptions]
            :pattern="phonePattern"
            :required="requiredField === 'phone'"
            placeholder="Укажите номер телефона"
            name="phone"
            type="tel"
            inputmode="tel"
            autocomplete="tel"
            aria-label="phone input"
            class="input input_lg placeholder:text-blue-gray-600 mt-3 bg-[#F1F4F8] px-3.5 text-gray-900 lg:px-6"
            @input="clearErrorMessage('phone')"
          />
          <div v-if="validatedData?.detailData?.phone" class="text-danger">
            {{ validatedData.detailData.phone.join(' ') }}
          </div>
        </div>
        <div class="space-y-3">
          <label for="email-input">
            Email{{ requiredField === 'email' ? '*' : '' }}
          </label>
          <input
            id="email-input"
            v-model="formModel.email"
            v-maska:[mailMaskOptions]
            :pattern="mailPattern"
            :required="requiredField === 'email'"
            placeholder="Укажите адрес электронной почты"
            name="email"
            type="text"
            inputmode="email"
            autocomplete="email"
            aria-label="email input"
            class="input input_lg placeholder:text-blue-gray-600 mt-3 bg-[#F1F4F8] px-3.5 text-gray-900 lg:px-6"
            @input="clearErrorMessage('email')"
          />
          <div v-if="validatedData?.detailData?.email" class="text-danger">
            {{ validatedData.detailData.email.join(' ') }}
          </div>
        </div>
      </div>

      <div class="flex items-center space-x-3">
        <input
          id="accept-personal-data"
          v-model="acceptPersonalData"
          class="accent-primary text-primary"
          required
          type="checkbox"
        />
        <label for="accept-personal-data" class="text-4 lg:text-3">
          Нажимая на кнопку “Подтвердить”, я соглашаюсь с условиями
          <a
            class="text-primary hover:text-primary-hover"
            href="/privacy_policy.pdf"
            target="_blank"
            >обработки персональных данных</a
          >
        </label>
      </div>

      <Btn :disabled="loading" type="submit" primary lg class="w-full">
        Подтвердить
      </Btn>
    </form>

    <div v-if="validatedData.message" class="text-danger">
      {{ validatedData.message }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { StatusFromJSON } from '@/openapi_fetch'
import type { StockProductFormRequest, Status } from '@/openapi_fetch'

const props = defineProps<{
  productId?: number
  loading?: boolean
}>()
const emit = defineEmits(['update:loading', 'submitted'])

const { FeedbackApi } = useOpenApi()
const { phoneMaskOptions, mailMaskOptions, phonePattern, mailPattern } =
  useMasks()
const { unformatPhoneNumber } = useFormat()

const profileStore = useProfileStore()
const profile = await profileStore.getAccountInfo()

const loading = useVModel(props, 'loading', emit)
const validatedData = ref<Status>({ message: '', detailData: undefined })
const formModel = ref<StockProductFormRequest>({
  formName: 'Сообщить о поступлении',
  fullName: (
    (profile?.firstName ?? '') +
    ' ' +
    (profile?.middleName ?? '') +
    ' ' +
    (profile?.lastName ?? '')
  ).trim(),
  email: profile?.email ?? '',
  phone: profile?.mainPhone?.phone,
  product: 0,
})
const acceptPersonalData = ref(false)
const requiredField = ref<'email' | 'phone'>('email')

onMounted(() => {
  if (document.body.classList.contains('product-notify-required-phone')) {
    requiredField.value = 'phone'
  }
})

function clearErrorMessage(field: string) {
  if (validatedData.value.detailData?.[field]) {
    validatedData.value.detailData[field] = undefined
  }
}

async function submit() {
  if (!acceptPersonalData) {
    return
  }
  if (!props.productId) {
    validatedData.value = {
      message: 'Упс, что-то пошло не так, пожалуйста, попробуйте позже.',
      detailData: undefined,
    }
    return
  }

  validatedData.value = { message: '', detailData: undefined }

  try {
    loading.value = true

    const stockProductFormRequest = {
      ...formModel.value,
      phone: formModel.value.phone
        ? unformatPhoneNumber(formModel.value.phone)
        : undefined,
      product: props.productId,
    }

    await FeedbackApi.feedbackStockProductFormCreate({
      stockProductFormRequest,
    })

    emit('submitted', props.productId)
  } catch (error: any) {
    if (error.message === 'Response returned an error code') {
      validatedData.value = useApiError(error, StatusFromJSON) as Status
    } else {
      validatedData.value = { message: error.message, detailData: undefined }
    }
    throw error
  } finally {
    loading.value = false
  }
}
</script>
