/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `webp` - webp
 * * `jpeg` - jpeg
 * @export
 */
export const ImageFormatEnum = {
    Webp: 'webp',
    Jpeg: 'jpeg'
} as const;
export type ImageFormatEnum = typeof ImageFormatEnum[keyof typeof ImageFormatEnum];


export function ImageFormatEnumFromJSON(json: any): ImageFormatEnum {
    return ImageFormatEnumFromJSONTyped(json, false);
}

export function ImageFormatEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageFormatEnum {
    return json as ImageFormatEnum;
}

export function ImageFormatEnumToJSON(value?: ImageFormatEnum | null): any {
    return value as any;
}

