/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BrokerReceiverRequestRequest,
  BrokerSenderResponse,
} from '../models';
import {
    BrokerReceiverRequestRequestFromJSON,
    BrokerReceiverRequestRequestToJSON,
    BrokerSenderResponseFromJSON,
    BrokerSenderResponseToJSON,
} from '../models';

export interface BrokerCreateRequest {
    brokerReceiverRequestRequest: BrokerReceiverRequestRequest;
}

export interface BrokerErrorsRetrieveRequest {
    errorType?: BrokerErrorsRetrieveErrorTypeEnum;
}

/**
 * 
 */
export class BrokerApi extends runtime.BaseAPI {

    /**
     * Запрос на получение данных из брокера.
     */
    async brokerCreateRaw(requestParameters: BrokerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.brokerReceiverRequestRequest === null || requestParameters.brokerReceiverRequestRequest === undefined) {
            throw new runtime.RequiredError('brokerReceiverRequestRequest','Required parameter requestParameters.brokerReceiverRequestRequest was null or undefined when calling brokerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/data/broker/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BrokerReceiverRequestRequestToJSON(requestParameters.brokerReceiverRequestRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на получение данных из брокера.
     */
    async brokerCreate(requestParameters: BrokerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.brokerCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async brokerErrorsRetrieveRaw(requestParameters: BrokerErrorsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.errorType !== undefined) {
            queryParameters['error_type'] = requestParameters.errorType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/broker/errors/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async brokerErrorsRetrieve(requestParameters: BrokerErrorsRetrieveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.brokerErrorsRetrieveRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на формирование данных для брокера.
     */
    async brokerGetChangedDataRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrokerSenderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data/broker/get-changed-data/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrokerSenderResponseFromJSON(jsonValue));
    }

    /**
     * Запрос на формирование данных для брокера.
     */
    async brokerGetChangedDataRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrokerSenderResponse> {
          try{
        const response = await this.brokerGetChangedDataRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}

/**
 * @export
 */
export const BrokerErrorsRetrieveErrorTypeEnum = {
    _500Error: '500_error',
    DictError: 'dict_error',
    SerializerError: 'serializer_error',
    ValidError: 'valid_error',
    ValueError: 'value_error'
} as const;
export type BrokerErrorsRetrieveErrorTypeEnum = typeof BrokerErrorsRetrieveErrorTypeEnum[keyof typeof BrokerErrorsRetrieveErrorTypeEnum];
