/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChildProductCharacteristic
 */
export interface ChildProductCharacteristic {
    /**
     * 
     * @type {number}
     * @memberof ChildProductCharacteristic
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ChildProductCharacteristic
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ChildProductCharacteristic
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof ChildProductCharacteristic
     */
    tegTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ChildProductCharacteristic
     */
    slug: string;
    /**
     * 
     * @type {Array<ChildProductCharacteristic>}
     * @memberof ChildProductCharacteristic
     */
    children: Array<ChildProductCharacteristic>;
}

/**
 * Check if a given object implements the ChildProductCharacteristic interface.
 */
export function instanceOfChildProductCharacteristic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "tegTitle" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "children" in value;

    return isInstance;
}

export function ChildProductCharacteristicFromJSON(json: any): ChildProductCharacteristic {
    return ChildProductCharacteristicFromJSONTyped(json, false);
}

export function ChildProductCharacteristicFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildProductCharacteristic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'position': json['position'],
        'tegTitle': json['teg_title'],
        'slug': json['slug'],
        'children': ((json['children'] as Array<any>).map(ChildProductCharacteristicFromJSON)),
    };
}

export function ChildProductCharacteristicToJSON(value?: ChildProductCharacteristic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'position': value.position,
        'teg_title': value.tegTitle,
        'slug': value.slug,
        'children': ((value.children as Array<any>).map(ChildProductCharacteristicToJSON)),
    };
}

