import DOMPurify from 'isomorphic-dompurify'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      sanitize: (v?: string | null) => {
        if (!v) {
          return ''
        }
        return DOMPurify.sanitize(v)
      },
    },
  }
})
