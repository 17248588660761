/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebPushDeviceRequest
 */
export interface WebPushDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof WebPushDeviceRequest
     */
    sessionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebPushDeviceRequest
     */
    tokenId: string;
    /**
     * 
     * @type {number}
     * @memberof WebPushDeviceRequest
     */
    user?: number | null;
}

/**
 * Check if a given object implements the WebPushDeviceRequest interface.
 */
export function instanceOfWebPushDeviceRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tokenId" in value;

    return isInstance;
}

export function WebPushDeviceRequestFromJSON(json: any): WebPushDeviceRequest {
    return WebPushDeviceRequestFromJSONTyped(json, false);
}

export function WebPushDeviceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebPushDeviceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionKey': !exists(json, 'session_key') ? undefined : json['session_key'],
        'tokenId': json['token_id'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function WebPushDeviceRequestToJSON(value?: WebPushDeviceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_key': value.sessionKey,
        'token_id': value.tokenId,
        'user': value.user,
    };
}

