<template>
  <AppHeader :social-items="socialItems" :items="navItems" :phone-to="phoneTo">
    <template #logo>
      <img
        class="h-[42px] min-w-[124px]"
        src="@/assets/images/Logo.svg?url"
        alt="LogoSvg"
      />
    </template>
  </AppHeader>

  
  <div v-if="error.statusCode === '404' || error.statusCode === 404">
    <!-- MAIN BANNER -->
    <div class="mb-12 bg-blue-50 py-5">
      <div class="container mx-auto">
        <div class="flex flex-col lg:flex-row lg:items-center">
          <div class="lg:w-1/2">
            <h2 class="mb-3 block text-center text-3xl font-medium lg:hidden">
              {{ headingTitle }}
            </h2>
            <nuxt-picture
              src="/images/error-404.png"
              width="1348"
              height="913"
              sizes="xs:100vw sm:100vw md:100vw lg:50vw xl:50vw"
              :img-attrs="{
                alt: headingTitle,
              }"
            />
          </div>
          <div class="lg:ml-8 lg:w-1/2 lg:py-[90px]">
            <h2 class="mb-9 hidden text-3xl font-medium lg:block">
              {{ headingTitle }}
            </h2>
            <p
              v-if="false"
              class="mb-6 text-center text-[20px] font-medium leading-[24px] lg:mb-[45px] lg:text-left"
            >
              Ваш приз - <strong class="text-sapphire-900">скидка 5%</strong> на
              следующую покупку! Запомните этот промокод и укажите его при
              оформлении заказа
            </p>
            <div class="flex flex-col text-center lg:flex-row lg:text-left">
              <Btn
                lg
                class="bg-sapphire-900 rounded-xl px-10 py-3 text-lg font-medium text-white"
                to="/"
              >
                Вернуться на главную
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /MAIN BANNER -->

    <!-- CATALOG LINKS -->
    <div class="container mx-auto mb-14">
      <div class="flex flex-col gap-x-7 lg:flex-row">
        <div
          v-for="({ title, link, img }, index) in catalogLinks"
          :key="index"
          class="border-blue-gray-200 mb-5 flex items-center rounded-2xl border-2 py-6 pl-6 pr-2 lg:mb-0 lg:w-1/2 lg:p-5 xl:p-9"
        >
          <div class="grow">
            <h2
              class="mb-4 text-xl font-medium leading-[24px] lg:mb-14 lg:text-[26px] lg:leading-8"
            >
              {{ title }}
            </h2>
            <Btn
              lg
              class="bg-sapphire-50 text-sapphire-900 text-4 inline-block h-auto rounded-lg px-5 py-2 font-medium lg:rounded-xl lg:px-11 lg:py-[14px] lg:text-lg"
              :to="link"
            >
              Перейти в каталог
            </Btn>
          </div>
          <nuxt-picture
            :src="img.src"
            :width="img.width"
            :height="img.height"
            sizes="xs:100vw sm:100vw md:100vw"
            class="w-[115px] lg:w-[165px] xl:w-[206px]"
            :img-attrs="{
              alt: 'Ого, вы нашли несуществующую страницу!',
              class: 'ml-auto w-full object-contain',
            }"
          />
        </div>
      </div>
    </div>
    <!-- /CATALOG LINKS -->

    <!-- POPULAR PRODUCTS -->
    <div class="container mx-auto mb-11">
      <ItemSlider
        v-if="popularProducts.length"
        ref="popularSlider"
        title="Популярные товары"
        :items="popularProducts"
        :is-loading="pending"
        :slides-per-view="3.2"
        unique-class="error-products"
        slide-class="h-auto dark-theme!max-w-[146px] lg:!max-w-[275px]"
      >
        <template #top>
          <div class="mb-6 flex lg:space-x-4">
            <Btn
              v-for="item in productFilters"
              :key="item.id"
              lg
              secondary
              :class="[
                'px-6 capitalize',
                {
                  'text-primary hover:text-primary-hover bg-blue-100':
                    item.id === selectedFilter,
                },
              ]"
              @click="setFilter(item)"
            >
              {{ item.value }}
            </Btn>
          </div>
        </template>
        <template #default="{ item }">
          <ProductCard :item="item" class="min-h-full" />
        </template>
      </ItemSlider>
    </div>
    <!-- /POPULAR PRODUCTS -->
  </div>

  <div v-else class="mt-5 text-center xl:mt-0">
    <!-- MAIN BANNER -->
    <div class="mb-12 bg-blue-50 py-5">
      <div class="container mx-auto">
        <h2 class="mb-3 block text-2xl font-medium lg:hidden">
          {{ headingTitle }}
        </h2>
        <nuxt-picture
          src="/images/error-500.png"
          width="1388"
          height="868"
          sizes="xs:100vw sm:100vw md:100vw lg:50vw xl:50vw"
          :img-attrs="{
            alt: headingTitle,
            class: 'mx-auto mb-6 w-auto lg:mb-3 lg:w-[695px]',
          }"
        />
        <h2 class="mb-8 hidden text-3xl font-medium lg:block">
          {{ headingTitle }}
        </h2>
        <Btn
          lg
          secondary
          class="bg-sapphire-900 mx-auto mb-5 px-[45px] py-5 text-white hover:text-white"
          @click="reloadPage"
        >
          Обновить страницу
        </Btn>
      </div>
    </div>
    <!-- /MAIN BANNER -->
  </div>

  <AppFooter
    class-title="gradient-text"
    class-button="button-hover-gradient"
    :items="footerItems"
    :social-items="socialItems"
    :class="{ 'hidden lg:block': isCart || isCheckout }"
    :phone-to="phoneTo"
  >
    <template #logo>
      <img
        class="h-[62px] w-[247px]"
        src="@/assets/images/Logo.svg?url"
        alt="LogoSvg"
      />
    </template>
  </AppFooter>
</template>

<script setup lang="ts">
import {
  header as navItems,
  footer as footerItems,
  social as socialItems,
  contacts,
} from '@/constants/navigation'

const props = defineProps<{
  error: any
}>()

const headingTitle = computed(() => {
  if (props.error.statusCode === 404) {
    return 'Ого, вы нашли несуществующую страницу!'
  }
  return 'Ой, произошла непредвиденная ошибка'
})

useHead({
  title: `${props.error.statusCode} - ${headingTitle.value}`,
})

const route = useRoute()

const isCart = computed(() => route.name === 'cart')
const isCheckout = computed(() => route.name === 'checkout')

const { phoneTo } = contacts

const catalogLinks = [
  {
    title: 'Суперпремиум корма для\xA0кошек',
    link: '/catalog/koshki/',
    img: {
      src: '/images/catalog-cat-items.png',
      width: 408,
      height: 388,
    },
  },
  {
    title: 'Суперпремиум корма для\xA0собак',
    link: '/catalog/sobaki/',
    img: {
      src: '/images/catalog-dog-items.png',
      width: 412,
      height: 388,
    },
  },
]

function reloadPage() {
  location.reload()
}

const selectedFilter = ref(31)

function setFilter(item) {
  selectedFilter.value = item.id
}

// const {CatalogApi} = useOpenApi()
const products = ref([])
const pending = ref(false)
// const { data: products, pending } = await useAsyncData(
// FIXME:рекурсия
//   'error-popular-products',
//   () => {
//     return CatalogApi.catalogProductsList({
//       characteristic: selectedFilter.value
//     })
//   },
//   {
//     watch: selectedFilter
//   }
// )

const popularProducts = computed(() => {
  return products.value?.results || []
})

const productFilters = computed(() => [
  {
    id: 31,
    value: 'Кошки',
  },
  {
    id: 30,
    value: 'Собаки',
  },
])
</script>
