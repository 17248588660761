/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataRegion } from './DataRegion';
import {
    DataRegionFromJSON,
    DataRegionFromJSONTyped,
    DataRegionToJSON,
} from './DataRegion';

/**
 * 
 * @export
 * @interface RegionStatus
 */
export interface RegionStatus {
    /**
     * 
     * @type {DataRegion}
     * @memberof RegionStatus
     */
    detailData?: DataRegion;
    /**
     * 
     * @type {string}
     * @memberof RegionStatus
     */
    readonly message: string;
}

/**
 * Check if a given object implements the RegionStatus interface.
 */
export function instanceOfRegionStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function RegionStatusFromJSON(json: any): RegionStatus {
    return RegionStatusFromJSONTyped(json, false);
}

export function RegionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detailData': !exists(json, 'detail_data') ? undefined : DataRegionFromJSON(json['detail_data']),
        'message': json['message'],
    };
}

export function RegionStatusToJSON(value?: RegionStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detail_data': DataRegionToJSON(value.detailData),
    };
}

