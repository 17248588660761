/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderItem1c } from './OrderItem1c';
import {
    OrderItem1cFromJSON,
    OrderItem1cFromJSONTyped,
    OrderItem1cToJSON,
} from './OrderItem1c';

/**
 * Данные для передачи в 1с по ручке /get-changed-data/
 * @export
 * @interface OrderTo1c
 */
export interface OrderTo1c {
    /**
     * 
     * @type {number}
     * @memberof OrderTo1c
     */
    readonly orderId: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly orderIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly contactIntegrationId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderTo1c
     */
    readonly orderDate: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderTo1c
     */
    readonly orderAmount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly orderStatusIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly deliveryAddressIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly deliveryTypeIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly paymentTypeIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly filialIntegrationId: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderTo1c
     */
    readonly plannedDeliveryDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTo1c
     */
    readonly isPaid: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly dateOfPayment: string | null;
    /**
     * 
     * @type {any}
     * @memberof OrderTo1c
     */
    readonly trackingLink: any | null;
    /**
     * 
     * @type {number}
     * @memberof OrderTo1c
     */
    readonly loyaltyPointsCharged: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly receiverFullName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly receiverSiteDomain: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly receiverEmail: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly receiverMobile: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly receiverTimeFrom: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly receiverTimeTill: string;
    /**
     * 
     * @type {any}
     * @memberof OrderTo1c
     */
    readonly isAutoOrder: any | null;
    /**
     * 
     * @type {number}
     * @memberof OrderTo1c
     */
    readonly autoOrderPeriod: number;
    /**
     * 
     * @type {any}
     * @memberof OrderTo1c
     */
    readonly lastModified: any | null;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly comment: string;
    /**
     * 
     * @type {Array<OrderItem1c>}
     * @memberof OrderTo1c
     */
    readonly orderProductList: Array<OrderItem1c>;
    /**
     * 
     * @type {string}
     * @memberof OrderTo1c
     */
    readonly siteId: string;
}

/**
 * Check if a given object implements the OrderTo1c interface.
 */
export function instanceOfOrderTo1c(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "orderIntegrationId" in value;
    isInstance = isInstance && "contactIntegrationId" in value;
    isInstance = isInstance && "orderDate" in value;
    isInstance = isInstance && "orderAmount" in value;
    isInstance = isInstance && "orderStatusIntegrationId" in value;
    isInstance = isInstance && "deliveryAddressIntegrationId" in value;
    isInstance = isInstance && "deliveryTypeIntegrationId" in value;
    isInstance = isInstance && "paymentTypeIntegrationId" in value;
    isInstance = isInstance && "filialIntegrationId" in value;
    isInstance = isInstance && "plannedDeliveryDate" in value;
    isInstance = isInstance && "isPaid" in value;
    isInstance = isInstance && "dateOfPayment" in value;
    isInstance = isInstance && "trackingLink" in value;
    isInstance = isInstance && "loyaltyPointsCharged" in value;
    isInstance = isInstance && "receiverFullName" in value;
    isInstance = isInstance && "receiverSiteDomain" in value;
    isInstance = isInstance && "receiverEmail" in value;
    isInstance = isInstance && "receiverMobile" in value;
    isInstance = isInstance && "receiverTimeFrom" in value;
    isInstance = isInstance && "receiverTimeTill" in value;
    isInstance = isInstance && "isAutoOrder" in value;
    isInstance = isInstance && "autoOrderPeriod" in value;
    isInstance = isInstance && "lastModified" in value;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "orderProductList" in value;
    isInstance = isInstance && "siteId" in value;

    return isInstance;
}

export function OrderTo1cFromJSON(json: any): OrderTo1c {
    return OrderTo1cFromJSONTyped(json, false);
}

export function OrderTo1cFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTo1c {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderId': json['order_id'],
        'orderIntegrationId': json['OrderIntegrationId'],
        'contactIntegrationId': json['ContactIntegrationId'],
        'orderDate': (new Date(json['OrderDate'])),
        'orderAmount': json['OrderAmount'],
        'orderStatusIntegrationId': json['OrderStatusIntegrationId'],
        'deliveryAddressIntegrationId': json['DeliveryAddressIntegrationId'],
        'deliveryTypeIntegrationId': json['DeliveryTypeIntegrationId'],
        'paymentTypeIntegrationId': json['PaymentTypeIntegrationId'],
        'filialIntegrationId': json['FilialIntegrationId'],
        'plannedDeliveryDate': (new Date(json['PlannedDeliveryDate'])),
        'isPaid': json['IsPaid'],
        'dateOfPayment': json['DateOfPayment'],
        'trackingLink': json['TrackingLink'],
        'loyaltyPointsCharged': json['LoyaltyPointsCharged'],
        'receiverFullName': json['ReceiverFullName'],
        'receiverSiteDomain': json['ReceiverSiteDomain'],
        'receiverEmail': json['ReceiverEmail'],
        'receiverMobile': json['ReceiverMobile'],
        'receiverTimeFrom': json['ReceiverTimeFrom'],
        'receiverTimeTill': json['ReceiverTimeTill'],
        'isAutoOrder': json['IsAutoOrder'],
        'autoOrderPeriod': json['AutoOrderPeriod'],
        'lastModified': json['LastModified'],
        'comment': json['Comment'],
        'orderProductList': ((json['OrderProductList'] as Array<any>).map(OrderItem1cFromJSON)),
        'siteId': json['SiteId'],
    };
}

export function OrderTo1cToJSON(value?: OrderTo1c | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

