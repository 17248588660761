/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliveryAddressRequest } from './DeliveryAddressRequest';
import {
    DeliveryAddressRequestFromJSON,
    DeliveryAddressRequestFromJSONTyped,
    DeliveryAddressRequestToJSON,
} from './DeliveryAddressRequest';

/**
 * 
 * @export
 * @interface SetDeliveryInfoRequest
 */
export interface SetDeliveryInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof SetDeliveryInfoRequest
     */
    deliveryType: string;
    /**
     * 
     * @type {DeliveryAddressRequest}
     * @memberof SetDeliveryInfoRequest
     */
    address: DeliveryAddressRequest;
    /**
     * 
     * @type {string}
     * @memberof SetDeliveryInfoRequest
     */
    cdekCode: string;
}

/**
 * Check if a given object implements the SetDeliveryInfoRequest interface.
 */
export function instanceOfSetDeliveryInfoRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryType" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "cdekCode" in value;

    return isInstance;
}

export function SetDeliveryInfoRequestFromJSON(json: any): SetDeliveryInfoRequest {
    return SetDeliveryInfoRequestFromJSONTyped(json, false);
}

export function SetDeliveryInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDeliveryInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryType': json['delivery_type'],
        'address': DeliveryAddressRequestFromJSON(json['address']),
        'cdekCode': json['cdek_code'],
    };
}

export function SetDeliveryInfoRequestToJSON(value?: SetDeliveryInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_type': value.deliveryType,
        'address': DeliveryAddressRequestToJSON(value.address),
        'cdek_code': value.cdekCode,
    };
}

