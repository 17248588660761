/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlockedDeliveryDates
 */
export interface BlockedDeliveryDates {
    /**
     * 
     * @type {Array<Date>}
     * @memberof BlockedDeliveryDates
     */
    blockedDeliveryDates: Array<Date>;
}

/**
 * Check if a given object implements the BlockedDeliveryDates interface.
 */
export function instanceOfBlockedDeliveryDates(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "blockedDeliveryDates" in value;

    return isInstance;
}

export function BlockedDeliveryDatesFromJSON(json: any): BlockedDeliveryDates {
    return BlockedDeliveryDatesFromJSONTyped(json, false);
}

export function BlockedDeliveryDatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockedDeliveryDates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blockedDeliveryDates': json['blocked_delivery_dates'],
    };
}

export function BlockedDeliveryDatesToJSON(value?: BlockedDeliveryDates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocked_delivery_dates': value.blockedDeliveryDates,
    };
}

