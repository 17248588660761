/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountProduct } from './DiscountProduct';
import {
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';

/**
 * 
 * @export
 * @interface CompareItemProduct
 */
export interface CompareItemProduct {
    /**
     * 
     * @type {number}
     * @memberof CompareItemProduct
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof CompareItemProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompareItemProduct
     */
    readonly coverPic: string;
    /**
     * 
     * @type {number}
     * @memberof CompareItemProduct
     */
    readonly price: number;
    /**
     * 
     * @type {number}
     * @memberof CompareItemProduct
     */
    readonly quantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompareItemProduct
     */
    isMonoprotein?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompareItemProduct
     */
    isNovelty?: boolean;
    /**
     * Средний рейтинг продукта по всем одобренным отзывам
     * @type {number}
     * @memberof CompareItemProduct
     */
    readonly rating: number;
    /**
     * 
     * @type {DiscountProduct}
     * @memberof CompareItemProduct
     */
    readonly discount: DiscountProduct;
}

/**
 * Check if a given object implements the CompareItemProduct interface.
 */
export function instanceOfCompareItemProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "coverPic" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "discount" in value;

    return isInstance;
}

export function CompareItemProductFromJSON(json: any): CompareItemProduct {
    return CompareItemProductFromJSONTyped(json, false);
}

export function CompareItemProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompareItemProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'coverPic': json['cover_pic'],
        'price': json['price'],
        'quantity': json['quantity'],
        'isMonoprotein': !exists(json, 'is_monoprotein') ? undefined : json['is_monoprotein'],
        'isNovelty': !exists(json, 'is_novelty') ? undefined : json['is_novelty'],
        'rating': json['rating'],
        'discount': DiscountProductFromJSON(json['discount']),
    };
}

export function CompareItemProductToJSON(value?: CompareItemProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'is_monoprotein': value.isMonoprotein,
        'is_novelty': value.isNovelty,
    };
}

