/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PetsTo1c
 */
export interface PetsTo1c {
    /**
     * 
     * @type {string}
     * @memberof PetsTo1c
     */
    readonly petIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof PetsTo1c
     */
    readonly contactIntegrationId: string | null;
    /**
     * 
     * @type {string}
     * @memberof PetsTo1c
     */
    readonly breedIntegrationId: string;
    /**
     * 
     * @type {string}
     * @memberof PetsTo1c
     */
    readonly petName: string;
    /**
     * 
     * @type {Date}
     * @memberof PetsTo1c
     */
    readonly petBirthDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PetsTo1c
     */
    readonly petGender: string;
    /**
     * 
     * @type {boolean}
     * @memberof PetsTo1c
     */
    readonly isCastrated: boolean;
    /**
     * 
     * @type {number}
     * @memberof PetsTo1c
     */
    readonly petWeight: number;
    /**
     * 
     * @type {boolean}
     * @memberof PetsTo1c
     */
    readonly petStatus: boolean;
    /**
     * 
     * @type {string}
     * @memberof PetsTo1c
     */
    readonly chipNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof PetsTo1c
     */
    readonly isOnShow: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PetsTo1c
     */
    readonly isHaveTitles: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PetsTo1c
     */
    readonly isVisitGrooming: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PetsTo1c
     */
    readonly lastVaccineDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PetsTo1c
     */
    readonly lastDewormingDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PetsTo1c
     */
    readonly lastEctoparasitesDate: Date;
    /**
     * 
     * @type {any}
     * @memberof PetsTo1c
     */
    readonly lastModified: any | null;
}

/**
 * Check if a given object implements the PetsTo1c interface.
 */
export function instanceOfPetsTo1c(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "petIntegrationId" in value;
    isInstance = isInstance && "contactIntegrationId" in value;
    isInstance = isInstance && "breedIntegrationId" in value;
    isInstance = isInstance && "petName" in value;
    isInstance = isInstance && "petBirthDate" in value;
    isInstance = isInstance && "petGender" in value;
    isInstance = isInstance && "isCastrated" in value;
    isInstance = isInstance && "petWeight" in value;
    isInstance = isInstance && "petStatus" in value;
    isInstance = isInstance && "chipNumber" in value;
    isInstance = isInstance && "isOnShow" in value;
    isInstance = isInstance && "isHaveTitles" in value;
    isInstance = isInstance && "isVisitGrooming" in value;
    isInstance = isInstance && "lastVaccineDate" in value;
    isInstance = isInstance && "lastDewormingDate" in value;
    isInstance = isInstance && "lastEctoparasitesDate" in value;
    isInstance = isInstance && "lastModified" in value;

    return isInstance;
}

export function PetsTo1cFromJSON(json: any): PetsTo1c {
    return PetsTo1cFromJSONTyped(json, false);
}

export function PetsTo1cFromJSONTyped(json: any, ignoreDiscriminator: boolean): PetsTo1c {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'petIntegrationId': json['PetIntegrationId'],
        'contactIntegrationId': json['ContactIntegrationId'],
        'breedIntegrationId': json['BreedIntegrationId'],
        'petName': json['PetName'],
        'petBirthDate': (new Date(json['PetBirthDate'])),
        'petGender': json['PetGender'],
        'isCastrated': json['IsCastrated'],
        'petWeight': json['PetWeight'],
        'petStatus': json['PetStatus'],
        'chipNumber': json['ChipNumber'],
        'isOnShow': json['IsOnShow'],
        'isHaveTitles': json['IsHaveTitles'],
        'isVisitGrooming': json['IsVisitGrooming'],
        'lastVaccineDate': (new Date(json['LastVaccineDate'])),
        'lastDewormingDate': (new Date(json['LastDewormingDate'])),
        'lastEctoparasitesDate': (new Date(json['LastEctoparasitesDate'])),
        'lastModified': json['LastModified'],
    };
}

export function PetsTo1cToJSON(value?: PetsTo1c | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

