/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountProduct
 */
export interface DiscountProduct {
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    discountPercent: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    discountValue: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    userPrice: number;
}

/**
 * Check if a given object implements the DiscountProduct interface.
 */
export function instanceOfDiscountProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "discountPercent" in value;
    isInstance = isInstance && "discountValue" in value;
    isInstance = isInstance && "userPrice" in value;

    return isInstance;
}

export function DiscountProductFromJSON(json: any): DiscountProduct {
    return DiscountProductFromJSONTyped(json, false);
}

export function DiscountProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountPercent': json['discount_percent'],
        'discountValue': json['discount_value'],
        'userPrice': json['user_price'],
    };
}

export function DiscountProductToJSON(value?: DiscountProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discount_percent': value.discountPercent,
        'discount_value': value.discountValue,
        'user_price': value.userPrice,
    };
}

