import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging'

function requestPermission() {
  return new Promise((resolve) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        resolve(permission)
      } else {
        // eslint-disable-next-line no-console
        console.log('Unable to get permission to notify.')
      }
    })
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', async () => {
    const { FIREBASE_VAPID_KEY, FIREBASE_CONFIG } = useRuntimeConfig().public
    const { NotificationsApi } = useOpenApi()
    const { loggedIn } = useAuth()
    const isFirebaseSupported = await isSupported()

    function refreshNotifications() {
      if (loggedIn.value) {
        refreshNuxtData(['/notifications/count/', '/notifications/'])
      }
    }

    if (
      'serviceWorker' in navigator &&
      isFirebaseSupported &&
      FIREBASE_CONFIG
    ) {
      const firebaseApp = initializeApp(
        typeof FIREBASE_CONFIG === 'string'
          ? JSON.parse(FIREBASE_CONFIG)
          : (FIREBASE_CONFIG as unknown),
      )

      const messaging = getMessaging(firebaseApp)

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data?.type === 'REFRESH_NOTIFICATIONS') {
          refreshNotifications()
        }
      })

      onMessage(messaging, (payload) => {
        // eslint-disable-next-line no-console
        console.log('Message received. ', payload)

        refreshNotifications()

        requestPermission().then(() => {
          navigator.serviceWorker
            .getRegistrations()
            .then((registrations) => {
              const registration = registrations.find(
                (r) =>
                  r.active?.state === 'activated' &&
                  r.active.scriptURL.endsWith('/firebase-messaging-sw.js'),
              )
              if (registration) {
                return registration.showNotification(
                  payload.notification?.title || '',
                  payload.notification,
                )
              } else {
                // eslint-disable-next-line no-console
                console.log(
                  '[FCM onMessage] activated регистрация /firebase-messaging-sw.js не найдена',
                )
              }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log('[FCM onMessage] ошибка getRegistrations', error)
            })
        })
      })

      requestPermission().then(() => {
        // при регистрации возникает ошибка
        // DOMException: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js', {
            scope: '/firebase-cloud-messaging-push-scope',
          })
          .then(() => {
            // получение токена FCM
            getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
              .then((token) => {
                // получение токена с апи
                NotificationsApi.notificationsDeviceList()
                  .then((deviceResponse) => {
                    if (
                      !deviceResponse?.some((item) => item.tokenId === token)
                    ) {
                      // отправка токена на сервер
                      NotificationsApi.notificationsDeviceCreate({
                        webPushDeviceRequest: {
                          tokenId: token,
                        },
                      }).catch((error) => {
                        if (
                          error.data?.data?.detail !==
                          'У сессии уже есть токен устройства'
                        ) {
                          // eslint-disable-next-line no-console
                          console.error(
                            '[api fetch] ошибка при попытке установить токен FCM',
                            error,
                          )
                        }
                      })
                    }
                  })
                  .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(
                      '[api fetch] ошибка при попытке получения токен FCM',
                      error,
                    )
                  })
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(
                  '[FCM] ошибка при попытке получения токен FCM',
                  error,
                )
              })
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('FCM ошибка регистрации sw', error)
          })
      })
    } else {
      // eslint-disable-next-line no-console
      console.log(
        'FCM не инициализирован, браузер не поддерживается или firebaseConfig не установлен.',
      )
    }
  })
})
