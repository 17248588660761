<template>
  <NuxtLink
    v-if="to"
    :to="to"
    :class="classes"
    :disabled="isDisabled || undefined"
    :replace="replace"
    :target="newTab ? '_blank' : '_self'"
    @click="onClick"
  >
    <slot />
  </NuxtLink>
  <component
    :is="tag"
    v-else
    :class="classes"
    :type="tag === 'button' ? type : undefined"
    :disabled="isDisabled || undefined"
    :href="tag === 'a' ? href : undefined"
    :target="target"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import type { ButtonHTMLAttributes } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

interface BtnProps {
  type?: ButtonHTMLAttributes['type']
  tag?: string
  primary?: boolean
  secondary?: boolean
  to?: RouteLocationRaw
  replace?: boolean
  href?: string
  disabled?: boolean
  lg?: boolean
  xl?: boolean
  xxl?: boolean
  xxxl?: boolean
  target?: string
  newTab?: boolean
}

const props = withDefaults(defineProps<BtnProps>(), {
  type: 'button',
  tag: 'button',
})

const emit = defineEmits(['click'])

const classes = computed(() => [
  {
    btn: true,
    btn_primary: props.primary,
    btn_secondary: props.secondary,
    btn_lg: props.lg,
    btn_xl: props.xl,
    btn_xxl: props.xxl,
  },
])

const isDisabled = computed(() => props.disabled)

const tag = computed(() => (props.href ? 'a' : props.tag))

function onClick(e: MouseEvent) {
  if (isDisabled.value) {
    return
  }
  emit('click', e)
}
</script>
