/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverPoints } from './DeliverPoints';
import {
    DeliverPointsFromJSON,
    DeliverPointsFromJSONTyped,
    DeliverPointsToJSON,
} from './DeliverPoints';
import type { DeliveryAddress } from './DeliveryAddress';
import {
    DeliveryAddressFromJSON,
    DeliveryAddressFromJSONTyped,
    DeliveryAddressToJSON,
} from './DeliveryAddress';

/**
 * 
 * @export
 * @interface DeliveryInfo
 */
export interface DeliveryInfo {
    /**
     * 
     * @type {string}
     * @memberof DeliveryInfo
     */
    deliveryType: string;
    /**
     * 
     * @type {DeliveryAddress}
     * @memberof DeliveryInfo
     */
    readonly address: DeliveryAddress | null;
    /**
     * 
     * @type {DeliverPoints}
     * @memberof DeliveryInfo
     */
    readonly pickupAddress: DeliverPoints | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryInfo
     */
    cdekCode: string;
}

/**
 * Check if a given object implements the DeliveryInfo interface.
 */
export function instanceOfDeliveryInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryType" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "pickupAddress" in value;
    isInstance = isInstance && "cdekCode" in value;

    return isInstance;
}

export function DeliveryInfoFromJSON(json: any): DeliveryInfo {
    return DeliveryInfoFromJSONTyped(json, false);
}

export function DeliveryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryType': json['delivery_type'],
        'address': DeliveryAddressFromJSON(json['address']),
        'pickupAddress': DeliverPointsFromJSON(json['pickup_address']),
        'cdekCode': json['cdek_code'],
    };
}

export function DeliveryInfoToJSON(value?: DeliveryInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_type': value.deliveryType,
        'cdek_code': value.cdekCode,
    };
}

