/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityEnum } from './ActivityEnum';
import {
    ActivityEnumFromJSON,
    ActivityEnumFromJSONTyped,
    ActivityEnumToJSON,
} from './ActivityEnum';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
} from './GenderEnum';

/**
 * 
 * @export
 * @interface PatchedPetRequest
 */
export interface PatchedPetRequest {
    /**
     * 
     * @type {Date}
     * @memberof PatchedPetRequest
     */
    birthday?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedPetRequest
     */
    name?: string;
    /**
     * 
     * @type {GenderEnum}
     * @memberof PatchedPetRequest
     */
    gender?: GenderEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedPetRequest
     */
    weight?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedPetRequest
     */
    status?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedPetRequest
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedPetRequest
     */
    active?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedPetRequest
     */
    characteristics?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedPetRequest
     */
    isCastrated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedPetRequest
     */
    breedId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedPetRequest
     */
    petType?: number | null;
    /**
     * 
     * @type {ActivityEnum}
     * @memberof PatchedPetRequest
     */
    activity?: ActivityEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedPetRequest
     */
    specialNeeds?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedPetRequest
     */
    specialNeedsData?: Array<number>;
}

/**
 * Check if a given object implements the PatchedPetRequest interface.
 */
export function instanceOfPatchedPetRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedPetRequestFromJSON(json: any): PatchedPetRequest {
    return PatchedPetRequestFromJSONTyped(json, false);
}

export function PatchedPetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedPetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'birthday': !exists(json, 'birthday') ? undefined : (json['birthday'] === null ? null : new Date(json['birthday'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : GenderEnumFromJSON(json['gender']),
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'characteristics': !exists(json, 'characteristics') ? undefined : json['characteristics'],
        'isCastrated': !exists(json, 'is_castrated') ? undefined : json['is_castrated'],
        'breedId': !exists(json, 'breed_id') ? undefined : json['breed_id'],
        'petType': !exists(json, 'pet_type') ? undefined : json['pet_type'],
        'activity': !exists(json, 'activity') ? undefined : ActivityEnumFromJSON(json['activity']),
        'specialNeeds': !exists(json, 'special_needs') ? undefined : json['special_needs'],
        'specialNeedsData': !exists(json, 'special_needs_data') ? undefined : json['special_needs_data'],
    };
}

export function PatchedPetRequestToJSON(value?: PatchedPetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'birthday': value.birthday === undefined ? undefined : (value.birthday === null ? null : value.birthday.toISOString().substr(0,10)),
        'name': value.name,
        'gender': GenderEnumToJSON(value.gender),
        'weight': value.weight,
        'status': value.status,
        'comment': value.comment,
        'active': value.active,
        'characteristics': value.characteristics,
        'is_castrated': value.isCastrated,
        'breed_id': value.breedId,
        'pet_type': value.petType,
        'activity': ActivityEnumToJSON(value.activity),
        'special_needs': value.specialNeeds,
        'special_needs_data': value.specialNeedsData,
    };
}

