/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    verificationId: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    externalService?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    utm?: string | null;
}

/**
 * Check if a given object implements the RegisterRequest interface.
 */
export function instanceOfRegisterRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "verificationId" in value;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function RegisterRequestFromJSON(json: any): RegisterRequest {
    return RegisterRequestFromJSONTyped(json, false);
}

export function RegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationId': json['verification_id'],
        'username': json['username'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'externalService': !exists(json, 'external_service') ? undefined : json['external_service'],
        'utm': !exists(json, 'utm') ? undefined : json['utm'],
    };
}

export function RegisterRequestToJSON(value?: RegisterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verification_id': value.verificationId,
        'username': value.username,
        'last_name': value.lastName,
        'first_name': value.firstName,
        'middle_name': value.middleName,
        'external_service': value.externalService,
        'utm': value.utm,
    };
}

