/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliveryAddressRequest } from './DeliveryAddressRequest';
import {
    DeliveryAddressRequestFromJSON,
    DeliveryAddressRequestFromJSONTyped,
    DeliveryAddressRequestToJSON,
} from './DeliveryAddressRequest';

/**
 * 
 * @export
 * @interface PatchedOrderRequest
 */
export interface PatchedOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    receiverFullName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOrderRequest
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    sessionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    comment?: string | null;
    /**
     * 
     * @type {DeliveryAddressRequest}
     * @memberof PatchedOrderRequest
     */
    address?: DeliveryAddressRequest;
    /**
     * 
     * @type {Date}
     * @memberof PatchedOrderRequest
     */
    deliveryDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    deliveryRegularity?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    timeFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    timeTill?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchedOrderRequest
     */
    confirmedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    deliveryPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOrderRequest
     */
    isPaid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    paymentMethodsId?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    bonusUsed?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOrderRequest
     */
    isBonusAccrual?: boolean;
    /**
     * Внутренняя категория доставки для сайта
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    categoryDeliveryType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedOrderRequest
     */
    cdekCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    absoluteDiscount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOrderRequest
     */
    isChanged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedOrderRequest
     */
    totalOrderPercentDiscount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOrderRequest
     */
    isOtherRecipient?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedOrderRequest
     */
    isCheckout?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedOrderRequest
     */
    checkoutDate?: Date | null;
}

/**
 * Check if a given object implements the PatchedOrderRequest interface.
 */
export function instanceOfPatchedOrderRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedOrderRequestFromJSON(json: any): PatchedOrderRequest {
    return PatchedOrderRequestFromJSONTyped(json, false);
}

export function PatchedOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'receiverFullName': !exists(json, 'receiver_full_name') ? undefined : json['receiver_full_name'],
        'confirmed': !exists(json, 'confirmed') ? undefined : json['confirmed'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'sessionKey': !exists(json, 'session_key') ? undefined : json['session_key'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'address': !exists(json, 'address') ? undefined : DeliveryAddressRequestFromJSON(json['address']),
        'deliveryDate': !exists(json, 'delivery_date') ? undefined : (json['delivery_date'] === null ? null : new Date(json['delivery_date'])),
        'deliveryRegularity': !exists(json, 'delivery_regularity') ? undefined : json['delivery_regularity'],
        'timeFrom': !exists(json, 'time_from') ? undefined : json['time_from'],
        'timeTill': !exists(json, 'time_till') ? undefined : json['time_till'],
        'confirmedDate': !exists(json, 'confirmed_date') ? undefined : (json['confirmed_date'] === null ? null : new Date(json['confirmed_date'])),
        'deliveryPrice': !exists(json, 'delivery_price') ? undefined : json['delivery_price'],
        'isPaid': !exists(json, 'is_paid') ? undefined : json['is_paid'],
        'paymentMethodsId': !exists(json, 'payment_methods_id') ? undefined : json['payment_methods_id'],
        'bonusUsed': !exists(json, 'bonus_used') ? undefined : json['bonus_used'],
        'isBonusAccrual': !exists(json, 'is_bonus_accrual') ? undefined : json['is_bonus_accrual'],
        'categoryDeliveryType': !exists(json, 'category_delivery_type') ? undefined : json['category_delivery_type'],
        'cdekCode': !exists(json, 'cdek_code') ? undefined : json['cdek_code'],
        'absoluteDiscount': !exists(json, 'absolute_discount') ? undefined : json['absolute_discount'],
        'isChanged': !exists(json, 'is_changed') ? undefined : json['is_changed'],
        'totalOrderPercentDiscount': !exists(json, 'total_order_percent_discount') ? undefined : json['total_order_percent_discount'],
        'isOtherRecipient': !exists(json, 'is_other_recipient') ? undefined : json['is_other_recipient'],
        'isCheckout': !exists(json, 'is_checkout') ? undefined : json['is_checkout'],
        'checkoutDate': !exists(json, 'checkout_date') ? undefined : (json['checkout_date'] === null ? null : new Date(json['checkout_date'])),
    };
}

export function PatchedOrderRequestToJSON(value?: PatchedOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'title': value.title,
        'receiver_full_name': value.receiverFullName,
        'confirmed': value.confirmed,
        'full_name': value.fullName,
        'session_key': value.sessionKey,
        'email': value.email,
        'phone': value.phone,
        'comment': value.comment,
        'address': DeliveryAddressRequestToJSON(value.address),
        'delivery_date': value.deliveryDate === undefined ? undefined : (value.deliveryDate === null ? null : value.deliveryDate.toISOString().substr(0,10)),
        'delivery_regularity': value.deliveryRegularity,
        'time_from': value.timeFrom,
        'time_till': value.timeTill,
        'confirmed_date': value.confirmedDate === undefined ? undefined : (value.confirmedDate === null ? null : value.confirmedDate.toISOString()),
        'delivery_price': value.deliveryPrice,
        'is_paid': value.isPaid,
        'payment_methods_id': value.paymentMethodsId,
        'bonus_used': value.bonusUsed,
        'is_bonus_accrual': value.isBonusAccrual,
        'category_delivery_type': value.categoryDeliveryType,
        'cdek_code': value.cdekCode,
        'absolute_discount': value.absoluteDiscount,
        'is_changed': value.isChanged,
        'total_order_percent_discount': value.totalOrderPercentDiscount,
        'is_other_recipient': value.isOtherRecipient,
        'is_checkout': value.isCheckout,
        'checkout_date': value.checkoutDate === undefined ? undefined : (value.checkoutDate === null ? null : value.checkoutDate.toISOString()),
    };
}

