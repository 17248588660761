/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderHistory
 */
export interface OrderHistory {
    /**
     * 
     * @type {number}
     * @memberof OrderHistory
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof OrderHistory
     */
    integrationId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistory
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderHistory
     */
    readonly status: string;
    /**
     * 
     * @type {any}
     * @memberof OrderHistory
     */
    readonly type: any | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderHistory
     */
    deliveryDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderHistory
     */
    readonly created: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderHistory
     */
    confirmedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrderHistory
     */
    readonly coverPic: string;
    /**
     * 
     * @type {number}
     * @memberof OrderHistory
     */
    readonly itemsCount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderHistory
     */
    readonly totalOrderUserPrice: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderHistory
     */
    isPaid?: boolean;
}

/**
 * Check if a given object implements the OrderHistory interface.
 */
export function instanceOfOrderHistory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "integrationId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "coverPic" in value;
    isInstance = isInstance && "itemsCount" in value;
    isInstance = isInstance && "totalOrderUserPrice" in value;

    return isInstance;
}

export function OrderHistoryFromJSON(json: any): OrderHistory {
    return OrderHistoryFromJSONTyped(json, false);
}

export function OrderHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': json['integration_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'status': json['status'],
        'type': json['type'],
        'deliveryDate': !exists(json, 'delivery_date') ? undefined : (json['delivery_date'] === null ? null : new Date(json['delivery_date'])),
        'created': (new Date(json['created'])),
        'confirmedDate': !exists(json, 'confirmed_date') ? undefined : (json['confirmed_date'] === null ? null : new Date(json['confirmed_date'])),
        'coverPic': json['cover_pic'],
        'itemsCount': json['items_count'],
        'totalOrderUserPrice': json['total_order_user_price'],
        'isPaid': !exists(json, 'is_paid') ? undefined : json['is_paid'],
    };
}

export function OrderHistoryToJSON(value?: OrderHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'title': value.title,
        'delivery_date': value.deliveryDate === undefined ? undefined : (value.deliveryDate === null ? null : value.deliveryDate.toISOString().substr(0,10)),
        'confirmed_date': value.confirmedDate === undefined ? undefined : (value.confirmedDate === null ? null : value.confirmedDate.toISOString()),
        'is_paid': value.isPaid,
    };
}

