/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetSetDeliveryInfoPickupAddress } from './GetSetDeliveryInfoPickupAddress';
import {
    GetSetDeliveryInfoPickupAddressFromJSON,
    GetSetDeliveryInfoPickupAddressFromJSONTyped,
    GetSetDeliveryInfoPickupAddressToJSON,
} from './GetSetDeliveryInfoPickupAddress';

/**
 * 
 * @export
 * @interface GetPickUpAddressInfo
 */
export interface GetPickUpAddressInfo {
    /**
     * 
     * @type {Array<GetSetDeliveryInfoPickupAddress>}
     * @memberof GetPickUpAddressInfo
     */
    pickupAddress: Array<GetSetDeliveryInfoPickupAddress>;
}

/**
 * Check if a given object implements the GetPickUpAddressInfo interface.
 */
export function instanceOfGetPickUpAddressInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pickupAddress" in value;

    return isInstance;
}

export function GetPickUpAddressInfoFromJSON(json: any): GetPickUpAddressInfo {
    return GetPickUpAddressInfoFromJSONTyped(json, false);
}

export function GetPickUpAddressInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPickUpAddressInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pickupAddress': ((json['pickup_address'] as Array<any>).map(GetSetDeliveryInfoPickupAddressFromJSON)),
    };
}

export function GetPickUpAddressInfoToJSON(value?: GetPickUpAddressInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pickup_address': ((value.pickupAddress as Array<any>).map(GetSetDeliveryInfoPickupAddressToJSON)),
    };
}

