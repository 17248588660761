import { joinURL } from 'ufo'
import { parseCookies } from 'h3'
import {
  ref,
  useRuntimeConfig,
  useCookie,
  useState,
  computed,
  navigateTo,
  decodeJWT,
} from '#imports'
import type { AuthState, AccessToken, RefreshToken } from '../types'

let accessTimerId: NodeJS.Timeout | undefined = undefined
let refreshTimerId: NodeJS.Timeout | undefined = undefined

function stringifyCookie(cookies: Record<string, string>) {
  return Object.entries(cookies)
    .map(([k, v]) => `${k}=${v}`)
    .join('; ')
}

export const useAuthState = () =>
  useState<
    AuthState & {
      _checked?: boolean
      _baseURL?: string
    }
  >('auth:state', () => ({
    loggedIn: false,
    user: undefined,
    userUuid: undefined,
    userRole: 'anonymous',
    _checked: false,
    _baseURL: undefined,
  }))

export function useAuth() {
  const { APP_HOST, auth: authRuntimeConfig } = useRuntimeConfig().public
  const state = useAuthState()
  const nuxtApp = useNuxtApp()
  const event = useRequestEvent()

  const {
    accessTokenKey,
    refreshTokenKey,
    hasuraTokenKey,
    hasuraAnonymousToken,
    accessTokenTTL,
    refreshTokenTTL,
    timeBeforeTokenUpdate,
  } = authRuntimeConfig

  const loggedIn = computed(() => state.value.loggedIn)

  const accessToken = useCookie(accessTokenKey, {
    sameSite: 'lax',
    readonly: true,
  })

  const refreshToken = useCookie(refreshTokenKey, {
    sameSite: 'lax',
    readonly: true,
  })

  const hasuraTokenCookie = useCookie(hasuraTokenKey, { sameSite: 'lax' })
  const hasuraToken = computed(() => accessToken.value ?? hasuraAnonymousToken)

  watch(
    hasuraToken,
    (val) => {
      hasuraTokenCookie.value = val
      if (event) {
        const cookies = parseCookies(event)
        cookies[hasuraTokenKey] = val
        event.node.req.headers.cookie = stringifyCookie(cookies)
      }
    },
    {
      immediate: true,
    },
  )

  async function _refreshAccessToken() {
    if (!accessToken.value || !refreshToken.value) {
      throw new Error('[useAuth] должны быть активны access и refresh')
    }

    const tokens = ref({
      access: '',
    })

    await nuxtApp.callHook(
      'auth:refresh',
      {
        access: accessToken.value,
        refresh: refreshToken.value,
      },
      tokens,
    )

    updateAccessToken(tokens.value.access)
  }

  async function _refreshTokens() {
    // в данный момент, по истечению срока жизни refresh токена пользователь считается неавторизованным
    removeTokens()
  }

  function _setAccessTokenTimeout(timeout = accessTokenTTL) {
    if (import.meta.server) return
    clearTimeout(accessTimerId)
    const timeoutMS = (timeout - timeBeforeTokenUpdate) * 1000
    accessTimerId = setTimeout(_refreshAccessToken, timeoutMS)
  }

  function _setRefreshTokenTimeout(timeout = refreshTokenTTL) {
    if (import.meta.server) return
    clearTimeout(refreshTimerId)
    const timeoutMS = (timeout - timeBeforeTokenUpdate) * 1000
    refreshTimerId = setTimeout(
      _refreshTokens,
      // setTimeout не дает установить больше 2147483647 (Max signed 32-bit integer)
      // примерно 24 дня
      timeoutMS > 2147483647 ? 2147483647 : timeoutMS,
    )
  }

  function _checkTokenExpiration(token: string) {
    const timestamp = Math.floor(Date.now() / 1000)
    const { exp } = decodeJWT<AccessToken>(token)
    if (timestamp >= exp) {
      return 'expired'
    } else if (exp - timestamp < timeBeforeTokenUpdate) {
      return 'expiring'
    } else {
      return true
    }
  }

  function updateAccessToken(token: string) {
    const timestamp = Math.floor(Date.now() / 1000)
    const { exp } = decodeJWT<AccessToken>(token)

    // при ssr куки еще не установлены, поэтому принудительно добавляем в event
    if (event) {
      const cookies = parseCookies(event)
      cookies[accessTokenKey] = token
      event.node.req.headers.cookie = stringifyCookie(cookies)
    }

    useCookie(accessTokenKey, {
      sameSite: 'lax',
      expires: new Date(exp * 1000),
    }).value = token

    _setAccessTokenTimeout(exp - timestamp)
  }

  function updateRefreshToken(token: string) {
    const timestamp = Math.floor(Date.now() / 1000)
    const { exp } = decodeJWT<RefreshToken>(token)

    // при ssr куки еще не установлены, поэтому принудительно добавляем в event
    if (event) {
      const cookies = parseCookies(event)
      cookies[refreshTokenKey] = token
      event.node.req.headers.cookie = stringifyCookie(cookies)
    }

    useCookie(refreshTokenKey, {
      sameSite: 'lax',
      expires: new Date(exp * 1000),
    }).value = token

    _setRefreshTokenTimeout(exp - timestamp)
  }

  async function initTokens() {
    if (!accessToken.value || !refreshToken.value) {
      removeTokens()
      return
    }

    const timestamp = Math.floor(Date.now() / 1000)

    const accessExpiration = _checkTokenExpiration(accessToken.value)
    const refreshExpiration = _checkTokenExpiration(accessToken.value)

    if (accessExpiration === 'expired' || refreshExpiration === 'expired') {
      removeTokens()
      return
    }

    const { exp: accessExp } = decodeJWT<AccessToken>(accessToken.value)
    const { exp: refreshExp } = decodeJWT<RefreshToken>(refreshToken.value)

    if (refreshExpiration === 'expiring') {
      await _refreshTokens()
    } else if (accessExpiration === 'expiring') {
      await _refreshAccessToken()
      _setRefreshTokenTimeout(refreshExp - timestamp)
    } else {
      _setAccessTokenTimeout(accessExp - timestamp)
      _setRefreshTokenTimeout(refreshExp - timestamp)
    }
    state.value.loggedIn = true

    const { 'https://hasura.io/jwt/claims': hasuraClaims } =
      decodeJWT<AccessToken>(accessToken.value)
    state.value.userRole = hasuraClaims['x-hasura-default-role']
    state.value.userUuid = hasuraClaims['x-hasura-user-id']
  }

  function removeTokens() {
    clearTimeout(accessTimerId)
    clearTimeout(refreshTimerId)

    useCookie(accessTokenKey, {
      sameSite: 'lax',
    }).value = undefined
    useCookie(refreshTokenKey, {
      sameSite: 'lax',
    }).value = undefined

    state.value.loggedIn = false
  }

  async function login(returnPath: string) {
    const baseURL = await getBaseURL()
    // редирект на СAS
    const redirectURL = joinURL(
      baseURL,
      `/auth/?service=${joinURL(APP_HOST, encodeURI(returnPath))}`,
    )
    await navigateTo(redirectURL, { external: true })
  }

  async function register(returnPath: string) {
    const baseURL = await getBaseURL()
    // редирект на СAS
    const redirectURL = joinURL(
      baseURL,
      `/auth/register/?service=${joinURL(APP_HOST, encodeURI(returnPath))}`,
    )
    await navigateTo(redirectURL, { external: true })
  }

  async function logout(returnPath: string) {
    const baseURL = await getBaseURL()
    removeTokens()
    await nuxtApp.callHook('auth:onLogout')
    // редирект на СAS
    const redirectURL = joinURL(
      baseURL,
      `/logout/?service=${joinURL(APP_HOST, encodeURI(returnPath))}`,
    )
    await navigateTo(redirectURL, { external: true })
  }

  async function getBaseURL() {
    if (state.value._baseURL) {
      return state.value._baseURL
    }
    const url = ref('')
    await nuxtApp.callHook('auth:baseURL', url)
    state.value._baseURL = url.value
    return state.value._baseURL
  }

  return {
    state,
    accessToken,
    refreshToken,
    hasuraToken,
    loggedIn,
    updateAccessToken,
    updateRefreshToken,
    initTokens,
    removeTokens,
    login,
    register,
    logout,
    getBaseURL,
  }
}
