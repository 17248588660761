import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { el: to.hash, behavior: 'smooth' }
      }
      const catalogRegex = /catalog*/
      if (catalogRegex.test(from.name) && to.name === from.name) {
        return
      }

      return { top: 0 }
    }
  },
}
