import { ecommerce } from '@/services/ecommerce'
import type {
  Cart,
  CartReserveResult,
  OrderItem,
  OrderItemAddDeleteRequest,
  ManagmentCartDestroyRequest,
} from '@/openapi_fetch'
import { findIndex } from 'lodash-es'

// 'create' вызов сеттер метода, например, для функционала "Вернуть в корзину", когда товар не должен быть удален из корзины
// 'destroy' явное удаление товара из корзины
// 'createOrDestroy' при 0 вызывает метод удаления
type RequestQueueType = 'create' | 'destroy' | 'createOrDestroy'
let requestQueue: {
  type: RequestQueueType
  payload: OrderItemAddDeleteRequest[]
}[] = []

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: null as Cart | null,
    prevCart: null as Cart | null,
    queuePending: false,
  }),
  getters: {
    hasAutoorder: (state) => state.cart?.hasAutoOrder,
    productsByIds: (state) =>
      state.cart?.orderItems.reduce<Record<number, OrderItem>>((a, c) => {
        a[c.productId] = c
        return a
      }, {}) ?? {},
  },
  actions: {
    clearStore() {
      this.cart = null
      this.prevCart = null
      this.queuePending = false
    },
    setData(data: Cart & { detail?: string }) {
      if (data?.detail === 'Нет корзины. Положите товар в корзину') {
        this.cart = null
      } else {
        this.cart = data
      }
    },
    async load() {
      const { ManagmentApi } = useOpenApi()
      try {
        const res = await ManagmentApi.managmentCartRetrieve()
        this.setData(res)
      } catch (error: any) {
        if (error.message === 'Нет корзины. Положите товар в корзину') {
          this.cart = null
        }
      }
    },
    // при обновлении корзины создается очередь запросов, для гарантию порядка запросов
    async queueUpdate(
      payload: OrderItemAddDeleteRequest[],
      type: RequestQueueType = 'createOrDestroy',
      optimisticData?: OrderItem[],
    ) {
      if (payload.length === 0) {
        return
      }
      const profileStore = useProfileStore()
      if (!profileStore.hasDeliveryAddress) {
        profileStore.deliveryAddressSelectDialogOpener = true
        // запуск промиса закрытия модального окна адреса доставки
        await profileStore.waitDeliveryAddressSelectDialogClose()
        // после закрытия модального окна адреса доставки проверка адрес доставки
        if (!profileStore.hasDeliveryAddress) {
          return
        }
      }
      // предыдущее состояние корзины сохраняется, если еще не было инциализировано
      // далее обновляется из ответа запросов
      if (!this.prevCart) {
        this.prevCart = this._cloneCartItem(this.cart)
      }
      // оптимистик обновление кол-ва товаров в корзине
      this._optimisticUpdate(payload, type, optimisticData)
      // добавление или обновление запроса в очереди
      // при обновлении рассматриваются только одиночные обновления
      // проверика групповых операций, если имеются до последней одиночной операции, то добавляется новая очередь
      const lastRequestIndex =
        (type === 'createOrDestroy' || type === 'create') &&
        payload.length === 1
          ? requestQueue.findLastIndex(
              (item) =>
                item.payload.length === 1 &&
                item.payload[0].productId === payload[0].productId,
            )
          : -1
      const lastGroupOperationIndex =
        payload.length === 1
          ? requestQueue.findLastIndex(
              (item) =>
                item.payload.length > 1 &&
                item.payload.some(
                  (item) => item.productId === payload[0].productId,
                ),
            )
          : -1
      if (
        lastRequestIndex !== -1 &&
        lastRequestIndex > lastGroupOperationIndex
      ) {
        requestQueue[lastRequestIndex].payload[0].quantity = payload[0].quantity
      } else {
        requestQueue.push({
          type,
          payload,
        })
      }
      // запуск очереди с debounce
      await this._debouncedProcessQueue()
    },
    async repeatOrder(items: OrderItem[]) {
      const { ManagmentApi } = useOpenApi()
      const payload = items.reduce<OrderItemAddDeleteRequest[]>((acc, item) => {
        const prevQuantity =
          this.cart?.orderItems?.find(
            (prev) => prev.productId === item.productId,
          )?.quantity ?? 0
        acc.push({
          productId: item.productId,
          quantity: prevQuantity + item.quantity,
        })
        return acc
      }, [])
      const response = await ManagmentApi.managmentCartCreate({
        orderItemAddDeleteRequest: payload,
      })
      this._setEcommerce(payload, response.orderItems, this.cart?.orderItems)
      this.cart = response
    },
    async reserve() {
      const { ManagmentApi } = useOpenApi()
      try {
        return await ManagmentApi.managmentReserveCreate({})
      } catch (e) {
        return {
          isError: false,
          cartChange: false,
          itemsChange: [],
          type: null,
        } as CartReserveResult
      }
    },
    // оптимистик обновление кол-ва товаров в корзине
    _optimisticUpdate(
      payload: OrderItemAddDeleteRequest[],
      type: RequestQueueType = 'createOrDestroy',
      optimisticData?: OrderItem[],
    ) {
      // если корзина еще не создана, то создается пустая корзина
      if (!this.cart) {
        this.cart = this.emptyCart()
      }
      for (const p of payload) {
        const index = findIndex(
          this.cart.orderItems,
          (item) => item.productId === p.productId,
        )
        if (index !== -1) {
          if (type === 'destroy') {
            this.cart.orderItems.splice(index, 1)
          } else {
            this.cart.orderItems[index].quantity = p.quantity
          }
        } else {
          const data = optimisticData?.find(
            (item) => p.productId === item.productId,
          )
          if (data) {
            this.cart.orderItems.push({ ...data, quantity: p.quantity })
          }
        }
      }
    },
    _debouncedProcessQueue: useDebounceFn(function () {
      // @ts-ignore контекст теряет типизацияю
      this._processQueue()
    }, 300),
    async _processQueue() {
      const { ManagmentApi } = useOpenApi()
      if (!this.queuePending && requestQueue.length > 0) {
        this.queuePending = true
        const request = requestQueue[0]
        // из очереди запрос удаляется после триггера debounce для избежания проблем с обновлением запроса
        requestQueue.shift()
        try {
          let response: Cart | undefined
          if (
            request.type === 'destroy' ||
            (request.type === 'createOrDestroy' &&
              request.payload.every((item) => item.quantity === 0))
          ) {
            response = await ManagmentApi.managmentCartDestroy(
              this._genDestroyRequestPayload(request.payload),
            )
          } else {
            response = await ManagmentApi.managmentCartCreate(
              this._genCreateRequestPayload(request.payload),
            )
          }
          const origResponse = this._cloneCartItem(response)
          // обновление кол-ва товаров нового состояния из локального состояния
          // нужно оставлять локальное состояние кол-ва товаров при обновлении данных из сервера
          // т.к. во время запроса состояние могло быть изменено и добавлен запрос в очередь
          // последний запрос синхронизирует данные с сервером
          for (const req of requestQueue.reverse()) {
            for (const p of req.payload) {
              const index = findIndex(
                response.orderItems,
                (item) => item.productId === p.productId,
              )
              if (req.type === 'create' || req.type === 'createOrDestroy') {
                const localItem = this.cart?.orderItems.find(
                  (item) => item.productId === p.productId,
                )
                if (localItem) {
                  if (index !== -1) {
                    response.orderItems[index].quantity = localItem.quantity
                  } else {
                    response.orderItems.push(localItem)
                  }
                }
              } else if (req.type === 'destroy' && index !== -1) {
                response.orderItems.splice(index, 1)
              }
            }
          }
          // отправление ecommerce с данными ответа сервера
          this._setEcommerce(
            request.payload,
            origResponse.orderItems,
            this.prevCart?.orderItems,
          )
          // сохраняется новое состояние
          this.$patch({
            cart: this._cloneCartItem(response),
            prevCart: this._cloneCartItem(origResponse),
          })
          this.queuePending = false
          // вызов следующей очереди
          await this._processQueue()
        } catch (error: any) {
          this.queuePending = false
          // при ошибке восстанавливается предыдущее состояние и очищается очередь
          requestQueue = []
          this.$patch({
            cart: this._cloneCartItem(this.prevCart),
          })
          throw new Error(
            '[cart store] при POST|DELETE /managment/cart/ состояние корзины не было изменено',
            error,
          )
        }
      }
    },
    _cloneCartItem(item: Cart | null) {
      return Object.assign({}, item, {
        orderItems: JSON.parse(JSON.stringify(item?.orderItems ?? [])),
      })
    },
    _genCreateRequestPayload(payload: OrderItemAddDeleteRequest[]) {
      return {
        orderItemAddDeleteRequest: payload,
      }
    },
    _genDestroyRequestPayload(payload: OrderItemAddDeleteRequest[]) {
      return payload.reduce<ManagmentCartDestroyRequest>(
        (acc, item) => {
          acc.productId?.push(item.productId)
          acc.quantity?.push(item.quantity)
          acc.orderId = item.orderId
          return acc
        },
        { productId: [], quantity: [] },
      )
    },
    _setEcommerce(
      payload: OrderItemAddDeleteRequest[],
      cartItems?: OrderItem[],
      prevItems?: OrderItem[],
    ) {
      payload.forEach((p) => {
        const productData = cartItems?.find(
          (item) => item.productId === p.productId,
        )
        const prevProductData = prevItems?.find(
          (item) => item.productId === p.productId,
        )
        const productQuantity = productData?.quantity ?? 0
        const prevProductQuantity = prevProductData?.quantity ?? 0
        const isAdd = productQuantity >= prevProductQuantity
        const method = isAdd ? 'addToCardProduct' : 'removeFromCardProduct'
        const product = isAdd ? productData : prevProductData
        ecommerce[method]({
          item_name: product?.title || '',
          item_id: p.productId,
          price: String(product?.price),
          item_brand: product?.productTrademark || 'Monge',
          quantity: String(Math.abs(productQuantity - prevProductQuantity)),
        })
      })
    },
    emptyCart() {
      return {
        filial: '',
        filialId: 0,
        orderId: 0,
        createOrder: false,
        isChanged: false,
        bonusUsed: 0,
        hasAutoOrder: false,
        totalProductCount: 0,
        orderItems: [],
        bonusAccrual: 0,
        promoCode: { id: 0, code: '' },
        bonusWriteOff: 0,
        totalOrderPrice: 0,
        totalOrderUserPrice: 0,
        totalOrderPercentDiscount: 0,
        totalOrderDiscount: 0,
        absoluteDiscount: 0,
      } as Cart
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
