/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestFeedback,
  BadRequestLead,
  BadRequestStockProductForm,
  Feedback,
  FeedbackRequest,
  Lead,
  LeadRequest,
  PageNotFound,
  PaginatedFeedbackList,
  PaginatedLeadList,
  StockProductForm,
  StockProductFormRequest,
} from '../models';
import {
    BadRequestFeedbackFromJSON,
    BadRequestFeedbackToJSON,
    BadRequestLeadFromJSON,
    BadRequestLeadToJSON,
    BadRequestStockProductFormFromJSON,
    BadRequestStockProductFormToJSON,
    FeedbackFromJSON,
    FeedbackToJSON,
    FeedbackRequestFromJSON,
    FeedbackRequestToJSON,
    LeadFromJSON,
    LeadToJSON,
    LeadRequestFromJSON,
    LeadRequestToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PaginatedFeedbackListFromJSON,
    PaginatedFeedbackListToJSON,
    PaginatedLeadListFromJSON,
    PaginatedLeadListToJSON,
    StockProductFormFromJSON,
    StockProductFormToJSON,
    StockProductFormRequestFromJSON,
    StockProductFormRequestToJSON,
} from '../models';

export interface FeedbackFeedbackCreateRequest {
    feedbackRequest: FeedbackRequest;
}

export interface FeedbackFeedbackListRequest {
    accepted?: boolean;
    page?: number;
    pageSize?: number;
}

export interface FeedbackFeedbackRetrieveRequest {
    id: number;
}

export interface FeedbackLeadsCreateRequest {
    leadRequest: LeadRequest;
}

export interface FeedbackLeadsListRequest {
    accepted?: boolean;
    page?: number;
    pageSize?: number;
}

export interface FeedbackLeadsRetrieveRequest {
    id: number;
}

export interface FeedbackStockProductFormCreateRequest {
    stockProductFormRequest: StockProductFormRequest;
}

/**
 * 
 */
export class FeedbackApi extends runtime.BaseAPI {

    /**
     * Запрос на создание сущности (обращение в службу поддержки).
     */
    async feedbackFeedbackCreateRaw(requestParameters: FeedbackFeedbackCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Feedback>> {
        if (requestParameters.feedbackRequest === null || requestParameters.feedbackRequest === undefined) {
            throw new runtime.RequiredError('feedbackRequest','Required parameter requestParameters.feedbackRequest was null or undefined when calling feedbackFeedbackCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/feedback/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedbackRequestToJSON(requestParameters.feedbackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (обращение в службу поддержки).
     */
    async feedbackFeedbackCreate(requestParameters: FeedbackFeedbackCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Feedback> {
          try{
        const response = await this.feedbackFeedbackCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение списка сущностей (обращение в службу поддержки).
     */
    async feedbackFeedbackListRaw(requestParameters: FeedbackFeedbackListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFeedbackList>> {
        const queryParameters: any = {};

        if (requestParameters.accepted !== undefined) {
            queryParameters['accepted'] = requestParameters.accepted;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/feedback/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFeedbackListFromJSON(jsonValue));
    }

    /**
     * Запрос на получение списка сущностей (обращение в службу поддержки).
     */
    async feedbackFeedbackList(requestParameters: FeedbackFeedbackListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFeedbackList> {
          try{
        const response = await this.feedbackFeedbackListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (обращение в службу поддержки).
     */
    async feedbackFeedbackRetrieveRaw(requestParameters: FeedbackFeedbackRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Feedback>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling feedbackFeedbackRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/feedback/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedbackFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (обращение в службу поддержки).
     */
    async feedbackFeedbackRetrieve(requestParameters: FeedbackFeedbackRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Feedback> {
          try{
        const response = await this.feedbackFeedbackRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на создание сущности (лид).
     */
    async feedbackLeadsCreateRaw(requestParameters: FeedbackLeadsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Lead>> {
        if (requestParameters.leadRequest === null || requestParameters.leadRequest === undefined) {
            throw new runtime.RequiredError('leadRequest','Required parameter requestParameters.leadRequest was null or undefined when calling feedbackLeadsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/leads/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeadRequestToJSON(requestParameters.leadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeadFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (лид).
     */
    async feedbackLeadsCreate(requestParameters: FeedbackLeadsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Lead> {
          try{
        const response = await this.feedbackLeadsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение списка сущностей (лид).
     */
    async feedbackLeadsListRaw(requestParameters: FeedbackLeadsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedLeadList>> {
        const queryParameters: any = {};

        if (requestParameters.accepted !== undefined) {
            queryParameters['accepted'] = requestParameters.accepted;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/leads/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedLeadListFromJSON(jsonValue));
    }

    /**
     * Запрос на получение списка сущностей (лид).
     */
    async feedbackLeadsList(requestParameters: FeedbackLeadsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedLeadList> {
          try{
        const response = await this.feedbackLeadsListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (лид).
     */
    async feedbackLeadsRetrieveRaw(requestParameters: FeedbackLeadsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Lead>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling feedbackLeadsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/leads/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LeadFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (лид).
     */
    async feedbackLeadsRetrieve(requestParameters: FeedbackLeadsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Lead> {
          try{
        const response = await this.feedbackLeadsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на создание сущности (заявка на отсутствующий товар).
     */
    async feedbackStockProductFormCreateRaw(requestParameters: FeedbackStockProductFormCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StockProductForm>> {
        if (requestParameters.stockProductFormRequest === null || requestParameters.stockProductFormRequest === undefined) {
            throw new runtime.RequiredError('stockProductFormRequest','Required parameter requestParameters.stockProductFormRequest was null or undefined when calling feedbackStockProductFormCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/feedback/stock-product-form/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockProductFormRequestToJSON(requestParameters.stockProductFormRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StockProductFormFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (заявка на отсутствующий товар).
     */
    async feedbackStockProductFormCreate(requestParameters: FeedbackStockProductFormCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StockProductForm> {
          try{
        const response = await this.feedbackStockProductFormCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
