/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
} from './GenderEnum';
import type { Nested } from './Nested';
import {
    NestedFromJSON,
    NestedFromJSONTyped,
    NestedToJSON,
} from './Nested';
import type { UserMainDeliveryAddress } from './UserMainDeliveryAddress';
import {
    UserMainDeliveryAddressFromJSON,
    UserMainDeliveryAddressFromJSONTyped,
    UserMainDeliveryAddressToJSON,
} from './UserMainDeliveryAddress';
import type { UserMainPhone } from './UserMainPhone';
import {
    UserMainPhoneFromJSON,
    UserMainPhoneFromJSONTyped,
    UserMainPhoneToJSON,
} from './UserMainPhone';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly image: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly webp: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly png: string | null;
    /**
     * 
     * @type {UserMainDeliveryAddress}
     * @memberof User
     */
    mainDeliveryAddress: UserMainDeliveryAddress | null;
    /**
     * 
     * @type {UserMainPhone}
     * @memberof User
     */
    mainPhone: UserMainPhone | null;
    /**
     * 
     * @type {GenderEnum}
     * @memberof User
     */
    gender: GenderEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly integrationId: string | null;
    /**
     * 
     * @type {Nested}
     * @memberof User
     */
    readonly filialRegion: Nested;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    readonly picSocial: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    birthday?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly confirmationedEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly confirmationedPhone: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly subscribed: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly bonuses: number;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "webp" in value;
    isInstance = isInstance && "png" in value;
    isInstance = isInstance && "mainDeliveryAddress" in value;
    isInstance = isInstance && "mainPhone" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "integrationId" in value;
    isInstance = isInstance && "filialRegion" in value;
    isInstance = isInstance && "picSocial" in value;
    isInstance = isInstance && "confirmationedEmail" in value;
    isInstance = isInstance && "confirmationedPhone" in value;
    isInstance = isInstance && "subscribed" in value;
    isInstance = isInstance && "bonuses" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'email': json['email'],
        'image': json['image'],
        'webp': json['webp'],
        'png': json['png'],
        'mainDeliveryAddress': UserMainDeliveryAddressFromJSON(json['main_delivery_address']),
        'mainPhone': UserMainPhoneFromJSON(json['main_phone']),
        'gender': GenderEnumFromJSON(json['gender']),
        'integrationId': json['integration_id'],
        'filialRegion': NestedFromJSON(json['filial_region']),
        'picSocial': json['pic_social'],
        'birthday': !exists(json, 'birthday') ? undefined : (json['birthday'] === null ? null : new Date(json['birthday'])),
        'confirmationedEmail': json['confirmationed_email'],
        'confirmationedPhone': json['confirmationed_phone'],
        'subscribed': json['subscribed'],
        'bonuses': json['bonuses'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'middle_name': value.middleName,
        'main_delivery_address': UserMainDeliveryAddressToJSON(value.mainDeliveryAddress),
        'main_phone': UserMainPhoneToJSON(value.mainPhone),
        'gender': GenderEnumToJSON(value.gender),
        'birthday': value.birthday === undefined ? undefined : (value.birthday === null ? null : value.birthday.toISOString().substr(0,10)),
    };
}

