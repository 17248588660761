/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CountNotifications,
  CreateViewedProductsRequest,
  NotifierSection,
  PageNotFound,
  PaginatedNotifierSectionList,
  Status,
  ViewedProducts,
  ViewedProductsRule,
  WebPushDevice,
  WebPushDeviceRequest,
} from '../models';
import {
    CountNotificationsFromJSON,
    CountNotificationsToJSON,
    CreateViewedProductsRequestFromJSON,
    CreateViewedProductsRequestToJSON,
    NotifierSectionFromJSON,
    NotifierSectionToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PaginatedNotifierSectionListFromJSON,
    PaginatedNotifierSectionListToJSON,
    StatusFromJSON,
    StatusToJSON,
    ViewedProductsFromJSON,
    ViewedProductsToJSON,
    ViewedProductsRuleFromJSON,
    ViewedProductsRuleToJSON,
    WebPushDeviceFromJSON,
    WebPushDeviceToJSON,
    WebPushDeviceRequestFromJSON,
    WebPushDeviceRequestToJSON,
} from '../models';

export interface NotificationsDestroyRequest {
    id: number;
}

export interface NotificationsDeviceCreateRequest {
    webPushDeviceRequest: WebPushDeviceRequest;
}

export interface NotificationsDeviceRetrieveRequest {
    id: number;
}

export interface NotificationsListRequest {
    page?: number;
    pageSize?: number;
}

export interface NotificationsPopUpListRequest {
    page?: number;
    pageSize?: number;
}

export interface NotificationsPopUpRetrieveRequest {
    id: number;
}

export interface NotificationsRetrieveRequest {
    id: number;
}

export interface NotificationsViewedProductsCreateRequest {
    createViewedProductsRequest: CreateViewedProductsRequest;
}

export interface NotificationsViewedProductsRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI {

    /**
     * Число непрочитанных уведомлений в разделе уведомлений
     * Число непрочитанных уведомлений в разделе уведомлений
     */
    async notificationsCountRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CountNotifications>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/count/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CountNotificationsFromJSON(jsonValue));
    }

    /**
     * Число непрочитанных уведомлений в разделе уведомлений
     * Число непрочитанных уведомлений в разделе уведомлений
     */
    async notificationsCountRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CountNotifications> {
          try{
        const response = await this.notificationsCountRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет связанный с уведомлениями в разделе уведомлений
     * Запрос на удаление сущности (уведомление).
     */
    async notificationsDestroyRaw(requestParameters: NotificationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Вьюсет связанный с уведомлениями в разделе уведомлений
     * Запрос на удаление сущности (уведомление).
     */
    async notificationsDestroy(requestParameters: NotificationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notificationsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Вьюсет веб-пуш устройств для получения уникальных токенов
     */
    async notificationsDeviceCreateRaw(requestParameters: NotificationsDeviceCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebPushDevice>> {
        if (requestParameters.webPushDeviceRequest === null || requestParameters.webPushDeviceRequest === undefined) {
            throw new runtime.RequiredError('webPushDeviceRequest','Required parameter requestParameters.webPushDeviceRequest was null or undefined when calling notificationsDeviceCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/device/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebPushDeviceRequestToJSON(requestParameters.webPushDeviceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebPushDeviceFromJSON(jsonValue));
    }

    /**
     * Вьюсет веб-пуш устройств для получения уникальных токенов
     */
    async notificationsDeviceCreate(requestParameters: NotificationsDeviceCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebPushDevice> {
          try{
        const response = await this.notificationsDeviceCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет веб-пуш устройств для получения уникальных токенов
     */
    async notificationsDeviceListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebPushDevice>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/device/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebPushDeviceFromJSON));
    }

    /**
     * Вьюсет веб-пуш устройств для получения уникальных токенов
     */
    async notificationsDeviceList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebPushDevice>> {
          try{
        const response = await this.notificationsDeviceListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет веб-пуш устройств для получения уникальных токенов
     */
    async notificationsDeviceRetrieveRaw(requestParameters: NotificationsDeviceRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebPushDevice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsDeviceRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/device/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebPushDeviceFromJSON(jsonValue));
    }

    /**
     * Вьюсет веб-пуш устройств для получения уникальных токенов
     */
    async notificationsDeviceRetrieve(requestParameters: NotificationsDeviceRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebPushDevice> {
          try{
        const response = await this.notificationsDeviceRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет связанный с уведомлениями в разделе уведомлений
     * Запрос на получение списка сущностей (уведомление).
     */
    async notificationsListRaw(requestParameters: NotificationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotifierSectionList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotifierSectionListFromJSON(jsonValue));
    }

    /**
     * Вьюсет связанный с уведомлениями в разделе уведомлений
     * Запрос на получение списка сущностей (уведомление).
     */
    async notificationsList(requestParameters: NotificationsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotifierSectionList> {
          try{
        const response = await this.notificationsListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет для всплавающих уведомлений
     * Запрос на получение списка сущностей (уведомление).
     */
    async notificationsPopUpListRaw(requestParameters: NotificationsPopUpListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotifierSectionList>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/pop-up/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotifierSectionListFromJSON(jsonValue));
    }

    /**
     * Вьюсет для всплавающих уведомлений
     * Запрос на получение списка сущностей (уведомление).
     */
    async notificationsPopUpList(requestParameters: NotificationsPopUpListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotifierSectionList> {
          try{
        const response = await this.notificationsPopUpListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет для всплавающих уведомлений
     * Запрос на получение экземпляра сущности (уведомление).
     */
    async notificationsPopUpRetrieveRaw(requestParameters: NotificationsPopUpRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotifierSection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsPopUpRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/pop-up/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotifierSectionFromJSON(jsonValue));
    }

    /**
     * Вьюсет для всплавающих уведомлений
     * Запрос на получение экземпляра сущности (уведомление).
     */
    async notificationsPopUpRetrieve(requestParameters: NotificationsPopUpRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotifierSection> {
          try{
        const response = await this.notificationsPopUpRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет связанный с уведомлениями в разделе уведомлений
     * Запрос на получение экземпляра сущности (уведомление).
     */
    async notificationsRetrieveRaw(requestParameters: NotificationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotifierSection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotifierSectionFromJSON(jsonValue));
    }

    /**
     * Вьюсет связанный с уведомлениями в разделе уведомлений
     * Запрос на получение экземпляра сущности (уведомление).
     */
    async notificationsRetrieve(requestParameters: NotificationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotifierSection> {
          try{
        const response = await this.notificationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * POST запросом кладется товар в массив products у объекта просмотренных товаров  Запускается таска через час об уведомлениях о просмотренных товарах, если в корзине нет такого товара.
     */
    async notificationsViewedProductsCreateRaw(requestParameters: NotificationsViewedProductsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewedProducts>> {
        if (requestParameters.createViewedProductsRequest === null || requestParameters.createViewedProductsRequest === undefined) {
            throw new runtime.RequiredError('createViewedProductsRequest','Required parameter requestParameters.createViewedProductsRequest was null or undefined when calling notificationsViewedProductsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/viewed-products/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateViewedProductsRequestToJSON(requestParameters.createViewedProductsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewedProductsFromJSON(jsonValue));
    }

    /**
     * POST запросом кладется товар в массив products у объекта просмотренных товаров  Запускается таска через час об уведомлениях о просмотренных товарах, если в корзине нет такого товара.
     */
    async notificationsViewedProductsCreate(requestParameters: NotificationsViewedProductsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewedProducts> {
          try{
        const response = await this.notificationsViewedProductsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет просмотренных товаров
     */
    async notificationsViewedProductsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ViewedProducts>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/viewed-products/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViewedProductsFromJSON));
    }

    /**
     * Вьюсет просмотренных товаров
     */
    async notificationsViewedProductsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ViewedProducts>> {
          try{
        const response = await this.notificationsViewedProductsListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вьюсет просмотренных товаров
     */
    async notificationsViewedProductsRetrieveRaw(requestParameters: NotificationsViewedProductsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewedProducts>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationsViewedProductsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/viewed-products/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewedProductsFromJSON(jsonValue));
    }

    /**
     * Вьюсет просмотренных товаров
     */
    async notificationsViewedProductsRetrieve(requestParameters: NotificationsViewedProductsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewedProducts> {
          try{
        const response = await this.notificationsViewedProductsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Если создано правило, то при GET запросе вернуться параметры правила для триггера на фронте когда нужно будет послать POST запрос, чтобы положить товар в \"просмотренные\".
     */
    async notificationsViewedProductsRuleRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewedProductsRule>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/notifications/viewed-products/rule/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewedProductsRuleFromJSON(jsonValue));
    }

    /**
     * Если создано правило, то при GET запросе вернуться параметры правила для триггера на фронте когда нужно будет послать POST запрос, чтобы положить товар в \"просмотренные\".
     */
    async notificationsViewedProductsRuleRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewedProductsRule> {
          try{
        const response = await this.notificationsViewedProductsRuleRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
