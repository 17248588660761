<template>
  <div
    v-if="showAlert"
    class="shadow-box fixed bottom-16 right-0 z-[100] max-w-[708px] space-y-3.5 rounded-2xl bg-white p-4 md:p-6 lg:bottom-3 lg:right-12"
  >
    <div class="flex items-center gap-3">
      <img
        src="@valta/assets/images/cookie.png"
        width="24"
        height="24"
        alt=""
        class=""
      />
      <h2 class="text-txt text-lg leading-tight md:text-xl">
        На нашем сайте используем cookie файлы
      </h2>
    </div>
    <p class="text-xs md:text-sm">
      Используемые нами файлы cookie позволяют узнавать тех, кто посещает наш
      сайт повторно, благодаря чему им не требуется каждый раз вводить одну и ту
      же информацию. Эти файлы также служат для отслеживания моделей трафика
      посетителей по сайту, что помогает повышать его удобство.
    </p>
    <div class="flex items-center gap-4">
      <Btn
        lg
        primary
        class="style_app w-1/2 md:w-[224px]"
        @click="acceptCookies"
      >
        Принять все
      </Btn>
      <Btn
        to="/rules/cookie/"
        lg
        secondary
        class="bg-btn-secondary style_app w-1/2 md:w-[224px]"
      >
        Подробнее
      </Btn>
    </div>
  </div>
</template>

<script setup lang="ts">
const showAlert = ref(false)

const sessionDuration = 2592000000

function acceptCookies() {
  showAlert.value = false
  const now = new Date().getTime()
  localStorage.setItem(
    'cookieAccepted',
    JSON.stringify({ accepted: true, timestamp: now }),
  )
}

function isSessionValid() {
  const data = localStorage.getItem('cookieAccepted')
  if (data) {
    const { accepted, timestamp } = JSON.parse(data)
    const now = new Date().getTime()

    // Check if the current time is within the session duration from the stored timestamp
    return accepted && now - timestamp < sessionDuration
  }
  return false
}

onMounted(() => {
  showAlert.value = !isSessionValid()
})
</script>

<style>
.shadow-box {
  box-shadow: 0px 0px 44px -12px rgba(0, 0, 0, 0.15);
}
</style>
