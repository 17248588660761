import { ecommerce } from '@/services/ecommerce'
import { DeliveryTypeEnum, type ISetDeliveryInfoRequest } from '@/types'
import type {
  CompareProductItem,
  EmailRequest,
  FeaturedProductsItem,
  ResetPasswordConfirmRequestRequest,
  User,
  SetDeliveryInfoRequest,
  GetLastOrderDeliveryAddressInfo,
  AuthConfirmCreateRequest,
  AuthRegisterCreateRequest,
  Status,
} from '@/openapi_fetch'
import { StatusFromJSON } from '@/openapi_fetch'

interface ProfileStoreState {
  profile: User | null
  deliveryAddressSelectDialogOpener: boolean
  productIdNotifyDialogOpener: number | undefined | null
  lastOrderDeliveryAddressInfo: GetLastOrderDeliveryAddressInfo | null
  favoritesPending: boolean
  favoriteItems: FeaturedProductsItem[]
  comparesPending: boolean
  compareItems: CompareProductItem[]
  compareFields: string[]
  notificationsCount: number
  subscribed: boolean | undefined
  promoCode: string
}

export const useProfileStore = defineStore('profile', {
  state: (): ProfileStoreState => ({
    profile: null,
    deliveryAddressSelectDialogOpener: false,
    productIdNotifyDialogOpener: null,
    lastOrderDeliveryAddressInfo: null,
    favoritesPending: false,
    favoriteItems: [],
    comparesPending: false,
    compareItems: [],
    compareFields: [],
    notificationsCount: 0,
    subscribed: false,
    promoCode: '',
  }),

  getters: {
    hasDeliveryAddress: (state) => {
      return !!(
        state.lastOrderDeliveryAddressInfo?.lastOrderPickupAddress ||
        state.lastOrderDeliveryAddressInfo?.lastOrderDeliveryAddress
      )
    },
    deliveryAddressType: (state) => {
      if (state.lastOrderDeliveryAddressInfo?.lastOrderPickupAddress) {
        return state.lastOrderDeliveryAddressInfo.lastOrderPickupAddress
          .deliveryType as DeliveryTypeEnum
      } else if (state.lastOrderDeliveryAddressInfo?.lastOrderDeliveryAddress) {
        return state.lastOrderDeliveryAddressInfo.lastOrderDeliveryAddress
          .deliveryType as DeliveryTypeEnum
      }
      return DeliveryTypeEnum.COURIER_CDEK
    },
    deliveryAddressRegion(state) {
      if (this.deliveryAddressType === DeliveryTypeEnum.PICK_UP) {
        return state.lastOrderDeliveryAddressInfo?.lastOrderPickupAddress
          ?.address.regionCode
      } else {
        return state.lastOrderDeliveryAddressInfo?.lastOrderDeliveryAddress
          ?.address.region
      }
    },
    deliveryAddressRegionName(state) {
      if (this.deliveryAddressType === DeliveryTypeEnum.PICK_UP) {
        return state.lastOrderDeliveryAddressInfo?.lastOrderPickupAddress
          ?.address.region
      } else {
        return state.lastOrderDeliveryAddressInfo?.lastOrderDeliveryAddress
          ?.address.regionName
      }
    },
    mainPhone: (state) => {
      return state.profile?.mainPhone
    },
    mainAddress: (state) => {
      return state.profile?.mainDeliveryAddress
    },
    favoriteIds: (state) =>
      state.favoriteItems
        .map((item) => (item.product ? item.product.id : null))
        .filter(Boolean),
    isFavorite() {
      return (id: number) => this.favoriteIds.includes(id)
    },
    compareIds(state) {
      const ids: number[] = []
      if (state.compareItems && state.compareItems.length) {
        for (const item of state.compareItems) {
          if (item.product) {
            ids.push(item.product.id)
          }
        }
      }
      return ids
    },
    isCompare(state) {
      return (id: number) =>
        state.compareItems
          .map((i) => (i.product ? i.product.id : null))
          .filter(Boolean)
          .includes(id)
    },
  },

  actions: {
    clearStore() {
      this.profile = null
      this.deliveryAddressSelectDialogOpener = false
      this.lastOrderDeliveryAddressInfo = null
      this.favoritesPending = false
      this.favoriteItems = []
      this.comparesPending = false
      this.compareItems = []
      this.compareFields = []
      this.notificationsCount = 0
      this.subscribed = undefined
      this.promoCode = ''
    },
    async getDeliveryAddress() {
      const { DeliveryApi } = useOpenApi()
      const response = await DeliveryApi.deliveryLastOrderAddressRetrieve()

      this.lastOrderDeliveryAddressInfo = response

      return response
    },
    async setDeliveryAddress(setDeliveryInfoRequest: ISetDeliveryInfoRequest) {
      const fetchOpenApi = useOpenApi()

      try {
        const response =
          await fetchOpenApi.DeliveryApi.deliverySetDeliveryCreate({
            setDeliveryInfoRequest:
              setDeliveryInfoRequest as SetDeliveryInfoRequest,
          })

        if (
          response.deliveryType === DeliveryTypeEnum.PICK_UP &&
          response.pickupAddress
        ) {
          this.lastOrderDeliveryAddressInfo = {
            lastOrderPickupAddress: {
              deliveryType: response.deliveryType,
              address: response.pickupAddress,
            },
            lastOrderDeliveryAddress: null,
          }
        } else if (response.deliveryType && response.address) {
          this.lastOrderDeliveryAddressInfo = {
            lastOrderPickupAddress: null,
            lastOrderDeliveryAddress: {
              deliveryType: response.deliveryType,
              address: response.address!,
            },
          }
        } else {
          await this.getDeliveryAddress()
        }

        reloadNuxtApp()

        return response
      } catch (error: any) {
        const statusCode = error.response.status
        const apiError = useApiError(error, StatusFromJSON) as Status
        const message = apiError.message
        if (
          statusCode === 500 ||
          (statusCode === 503 && message === 'Ошибка при обращении в API CDEK')
        ) {
          this.lastOrderDeliveryAddressInfo = {
            lastOrderPickupAddress: null,
            lastOrderDeliveryAddress: null,
          }
          await this.getDeliveryAddress()
        }
        throw error
      }
    },
    waitDeliveryAddressSelectDialogClose() {
      if (!this.deliveryAddressSelectDialogOpener) {
        return Promise.resolve()
      }
      return new Promise<void>((resolve) => {
        const unsubscribe = this.$subscribe((_, state) => {
          if (!state.deliveryAddressSelectDialogOpener) {
            resolve()
            unsubscribe()
          }
        })
      })
    },
    // DEPRECATED авторизация CAS
    setLoggedIn(_val: boolean) {},
    async getAccountInfo() {
      try {
        if (!this.profile) {
          const { accountsMeRetrieve } = useAccountsMeRetrieve()
          const account = await accountsMeRetrieve()
          this.subscribed = account.subscribed || undefined
          this.profile = account
        }
        return this.profile
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log('accountsMeRetrieve: ', error)
      }
    },
    // DEPRECATED авторизация CAS
    async completeVoucher(voucher: string) {
      const { LoyalityApi } = useOpenApi()
      try {
        await LoyalityApi.loyalityVoucherCreate({
          voucherRequest: { voucher },
        })
      } catch (e) {
        // TODO что делать пока не ясно)
      }
    },
    // DEPRECATED авторизация CAS
    async passwordLessConfirmCode(data: AuthConfirmCreateRequest) {
      const { AuthApi } = useOpenApi()
      await AuthApi.authConfirmCreate(data)
      await this.getAccountInfo()
      this.setLoggedIn(true)
      ecommerce.successAuth('password_less')
    },
    // DEPRECATED авторизация CAS
    async passwordLessRegister(
      data: AuthRegisterCreateRequest,
      voucherId: number | string | undefined = undefined,
    ) {
      const { AuthApi } = useOpenApi()
      await AuthApi.authRegisterCreate(data)
      await this.getAccountInfo()
      this.setLoggedIn(true)
      if (voucherId) {
        await this.completeVoucher(`${voucherId}`)
      }

      ecommerce.successAuth('password_less')
    },
    // DEPRECATED авторизация CAS
    async passwordReset(data: EmailRequest) {
      const fetchOpenApi = useOpenApi()
      const response =
        await fetchOpenApi.AccountApi.accountsPasswordResetCreate({
          emailRequest: data,
        })
      return response
    },
    // DEPRECATED авторизация CAS
    async passwordConfirmReset(data: ResetPasswordConfirmRequestRequest) {
      const fetchOpenApi = useOpenApi()
      const response =
        await fetchOpenApi.AccountApi.accountsPasswordConfirmCreate({
          resetPasswordConfirmRequestRequest: data,
        })
      return response
    },
    async toggleFavorite(id: number) {
      const fetchOpenApi = useOpenApi()
      let response = null
      if (this.isFavorite(id)) {
        response = await fetchOpenApi.ManagmentApi.managmentFeaturedDestroy({
          id,
        })
      } else {
        response = await fetchOpenApi.ManagmentApi.managmentFeaturedCreate({
          addProductToFeaturedRequest: {
            productId: id,
          },
        })
      }

      this.favoriteItems = (response && response.items) || []
    },
    async toggleCompare(id: number) {
      const fetchOpenApi = useOpenApi()
      let response = null
      if (!this.isCompare(id)) {
        response = await fetchOpenApi.ManagmentApi.managmentCompareCreate({
          addProductToCompareRequest: {
            productId: id,
          },
        })
      } else {
        response = await fetchOpenApi.ManagmentApi.managmentCompareDestroy({
          id,
        })
      }

      this.compareItems = (response && response.items) || []
    },
  },
  persist: [
    {
      storage: persistedState.localStorage,
      key: 'profile',
      paths: [
        'compareFields',
        'subscribed',
        'promoCode',
        'productIdNotifyDialogOpener',
      ],
    },
  ],
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
