import validate from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/validate.js";
import seo_45tag_45global from "/app/packages/shared/middleware/seo-tag.global.ts";
import trailing_45slash_45global from "/app/packages/shared/middleware/trailingSlash.global.ts";
import auth_45check from "/app/packages/shared/modules/auth/runtime/middleware/auth-check.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  seo_45tag_45global,
  trailing_45slash_45global,
  auth_45check,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-cas-redirect": () => import("/app/packages/shared/middleware/auth-cas-redirect.ts"),
  "auth-require": () => import("/app/packages/shared/modules/auth/runtime/middleware/auth-require.ts")
}