/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeUsersRequest
 */
export interface MergeUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof MergeUsersRequest
     */
    mainUserId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MergeUsersRequest
     */
    slaveUsersId: Array<number>;
}

/**
 * Check if a given object implements the MergeUsersRequest interface.
 */
export function instanceOfMergeUsersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mainUserId" in value;
    isInstance = isInstance && "slaveUsersId" in value;

    return isInstance;
}

export function MergeUsersRequestFromJSON(json: any): MergeUsersRequest {
    return MergeUsersRequestFromJSONTyped(json, false);
}

export function MergeUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mainUserId': json['main_user_id'],
        'slaveUsersId': json['slave_users_id'],
    };
}

export function MergeUsersRequestToJSON(value?: MergeUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'main_user_id': value.mainUserId,
        'slave_users_id': value.slaveUsersId,
    };
}

