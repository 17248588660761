/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCatalog } from './ProductCatalog';
import {
    ProductCatalogFromJSON,
    ProductCatalogFromJSONTyped,
    ProductCatalogToJSON,
} from './ProductCatalog';

/**
 * 
 * @export
 * @interface FeaturedProductsItem
 */
export interface FeaturedProductsItem {
    /**
     * 
     * @type {number}
     * @memberof FeaturedProductsItem
     */
    readonly id: number;
    /**
     * 
     * @type {ProductCatalog}
     * @memberof FeaturedProductsItem
     */
    readonly product: ProductCatalog;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeaturedProductsItem
     */
    readonly characteristics: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FeaturedProductsItem
     */
    featured: number;
}

/**
 * Check if a given object implements the FeaturedProductsItem interface.
 */
export function instanceOfFeaturedProductsItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "characteristics" in value;
    isInstance = isInstance && "featured" in value;

    return isInstance;
}

export function FeaturedProductsItemFromJSON(json: any): FeaturedProductsItem {
    return FeaturedProductsItemFromJSONTyped(json, false);
}

export function FeaturedProductsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturedProductsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product': ProductCatalogFromJSON(json['product']),
        'characteristics': json['characteristics'],
        'featured': json['featured'],
    };
}

export function FeaturedProductsItemToJSON(value?: FeaturedProductsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'featured': value.featured,
    };
}

