export const header = [
  {
    text: 'Профиль',
    to: '/profile/',
    mobileOnly: true,
    auth: true,
  },
  {
    text: 'О компании',
    to: '/about/',
  },
  {
    text: 'Каталог',
    to: '/catalog/',
    mobileOnly: true,
  },
  {
    text: 'Где купить',
    to: '/map/',
  },
  {
    text: 'Контакты',
    to: '/contacts/',
  },
  {
    text: 'Сотрудничество',
    to: '/cooperation/',
  },
  {
    text: 'Заводчикам',
    to: '/breeder/',
  },
  {
    text: 'Новости',
    to: '/articles/',
  },
  {
    text: 'Вет. диеты',
    to: 'https://vetsolution.ru',
    external: true,
    class: '!text-[#005B98] hover:!text-[#337CAD]',
  },
]

export const footer = [
  {
    title: 'О компании',
    children: header
      .filter((i) => !i.mobileOnly)
      .map((i) => ({ title: i.text, href: i.to })),
  },
  {
    title: 'Покупателям',
    children: [
      {
        title: 'Условия продажи',
        href: '/terms_of_sales.pdf',
        type: 'file',
      },
      {
        title: 'Условия возврата',
        href: 'https://brands4pets.ru/refund.pdf',
        type: 'file',
      },
      {
        title: 'Доставка и оплата',
        href: '/payment_and_delivery.pdf',
        type: 'file',
      },
    ],
  },
  {
    title: 'Линии',
    children: [
      {
        title: 'GiftShop',
        href: '/giftshop/',
      },
      {
        title: 'Gift',
        href: '/gift/',
      },
      {
        title: 'BWild',
        href: '/bwild/',
      },

      {
        title: 'Speciality Line',
        href: '/catalog/speciality/',
      },
      {
        title: 'Daily Line',
        href: '/catalog/daily/',
      },
      {
        title: 'Supreme',
        href: '/catalog/supreme/',
      },
    ],
  },
]

export const social = [
  {
    href: 'https://vk.com/monge_korm',
    icon: 'vk',
  },
  {
    href: 'https://ok.ru/monge',
    icon: 'od',
  },
]

export const contacts = {
  phoneTo: '8 (800) 600-28-04',
}
