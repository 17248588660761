/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreTypeEnum } from './StoreTypeEnum';
import {
    StoreTypeEnumFromJSON,
    StoreTypeEnumFromJSONTyped,
    StoreTypeEnumToJSON,
} from './StoreTypeEnum';

/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    logo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    holding?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    workFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    workTill?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    readonly storeLink: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    addressLat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    addressLon?: string | null;
    /**
     * 
     * @type {StoreTypeEnum}
     * @memberof Store
     */
    type: StoreTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    position?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    region?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    isAllRussiaDelivery?: boolean;
}

/**
 * Check if a given object implements the Store interface.
 */
export function instanceOfStore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "storeLink" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function StoreFromJSON(json: any): Store {
    return StoreFromJSONTyped(json, false);
}

export function StoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): Store {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'holding': !exists(json, 'holding') ? undefined : json['holding'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'workFrom': !exists(json, 'work_from') ? undefined : json['work_from'],
        'workTill': !exists(json, 'work_till') ? undefined : json['work_till'],
        'storeLink': json['store_link'],
        'addressLat': !exists(json, 'address_lat') ? undefined : json['address_lat'],
        'addressLon': !exists(json, 'address_lon') ? undefined : json['address_lon'],
        'type': StoreTypeEnumFromJSON(json['type']),
        'position': !exists(json, 'position') ? undefined : json['position'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'isAllRussiaDelivery': !exists(json, 'is_all_russia_delivery') ? undefined : json['is_all_russia_delivery'],
    };
}

export function StoreToJSON(value?: Store | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logo': value.logo,
        'name': value.name,
        'holding': value.holding,
        'address': value.address,
        'phone': value.phone,
        'email': value.email,
        'work_from': value.workFrom,
        'work_till': value.workTill,
        'address_lat': value.addressLat,
        'address_lon': value.addressLon,
        'type': StoreTypeEnumToJSON(value.type),
        'position': value.position,
        'region': value.region,
        'is_all_russia_delivery': value.isAllRussiaDelivery,
    };
}

