/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DaData
 */
export interface DaData {
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    readonly region: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    regionName: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    settlement: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    house: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    block: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    apartment: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    fias: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    cityFiasId: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    lon: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    lat: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    stead: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    steadType: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    beltwayHit: string;
    /**
     * 
     * @type {string}
     * @memberof DaData
     */
    beltwayDistance: string;
}

/**
 * Check if a given object implements the DaData interface.
 */
export function instanceOfDaData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "regionName" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "settlement" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "house" in value;
    isInstance = isInstance && "block" in value;
    isInstance = isInstance && "apartment" in value;
    isInstance = isInstance && "zipcode" in value;
    isInstance = isInstance && "fias" in value;
    isInstance = isInstance && "cityFiasId" in value;
    isInstance = isInstance && "lon" in value;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "stead" in value;
    isInstance = isInstance && "steadType" in value;
    isInstance = isInstance && "beltwayHit" in value;
    isInstance = isInstance && "beltwayDistance" in value;

    return isInstance;
}

export function DaDataFromJSON(json: any): DaData {
    return DaDataFromJSONTyped(json, false);
}

export function DaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'country': json['country'],
        'region': json['region'],
        'regionName': json['region_name'],
        'city': json['city'],
        'settlement': json['settlement'],
        'street': json['street'],
        'house': json['house'],
        'block': json['block'],
        'apartment': json['apartment'],
        'zipcode': json['zipcode'],
        'fias': json['fias'],
        'cityFiasId': json['city_fias_id'],
        'lon': json['lon'],
        'lat': json['lat'],
        'stead': json['stead'],
        'steadType': json['stead_type'],
        'beltwayHit': json['beltway_hit'],
        'beltwayDistance': json['beltway_distance'],
    };
}

export function DaDataToJSON(value?: DaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'country': value.country,
        'region_name': value.regionName,
        'city': value.city,
        'settlement': value.settlement,
        'street': value.street,
        'house': value.house,
        'block': value.block,
        'apartment': value.apartment,
        'zipcode': value.zipcode,
        'fias': value.fias,
        'city_fias_id': value.cityFiasId,
        'lon': value.lon,
        'lat': value.lat,
        'stead': value.stead,
        'stead_type': value.steadType,
        'beltway_hit': value.beltwayHit,
        'beltway_distance': value.beltwayDistance,
    };
}

