/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CartReserveItemsChanged } from './CartReserveItemsChanged';
import {
    CartReserveItemsChangedFromJSON,
    CartReserveItemsChangedFromJSONTyped,
    CartReserveItemsChangedToJSON,
} from './CartReserveItemsChanged';

/**
 * 
 * @export
 * @interface CartReserveResult
 */
export interface CartReserveResult {
    /**
     * 
     * @type {boolean}
     * @memberof CartReserveResult
     */
    isError: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CartReserveResult
     */
    cartChange: boolean;
    /**
     * 
     * @type {string}
     * @memberof CartReserveResult
     */
    text?: string | null;
    /**
     * 
     * @type {Array<CartReserveItemsChanged>}
     * @memberof CartReserveResult
     */
    itemsChange: Array<CartReserveItemsChanged>;
    /**
     * 
     * @type {any}
     * @memberof CartReserveResult
     */
    readonly type: any | null;
}

/**
 * Check if a given object implements the CartReserveResult interface.
 */
export function instanceOfCartReserveResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isError" in value;
    isInstance = isInstance && "cartChange" in value;
    isInstance = isInstance && "itemsChange" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CartReserveResultFromJSON(json: any): CartReserveResult {
    return CartReserveResultFromJSONTyped(json, false);
}

export function CartReserveResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartReserveResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['is_error'],
        'cartChange': json['cart_change'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'itemsChange': ((json['items_change'] as Array<any>).map(CartReserveItemsChangedFromJSON)),
        'type': json['type'],
    };
}

export function CartReserveResultToJSON(value?: CartReserveResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_error': value.isError,
        'cart_change': value.cartChange,
        'text': value.text,
        'items_change': ((value.itemsChange as Array<any>).map(CartReserveItemsChangedToJSON)),
    };
}

