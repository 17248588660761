/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BonusCreateRequest,
  BonusHistory,
  CancelPromoCodeRequest,
  GetBonus,
  PageNotFound,
  PromoCodeCreateRequest,
  Status,
  VoucherRequest,
} from '../models';
import {
    BonusCreateRequestFromJSON,
    BonusCreateRequestToJSON,
    BonusHistoryFromJSON,
    BonusHistoryToJSON,
    CancelPromoCodeRequestFromJSON,
    CancelPromoCodeRequestToJSON,
    GetBonusFromJSON,
    GetBonusToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PromoCodeCreateRequestFromJSON,
    PromoCodeCreateRequestToJSON,
    StatusFromJSON,
    StatusToJSON,
    VoucherRequestFromJSON,
    VoucherRequestToJSON,
} from '../models';

export interface LoyalityBonusCreateRequest {
    bonusCreateRequest: BonusCreateRequest;
}

export interface LoyalityCancelPromoCodeCreateRequest {
    cancelPromoCodeRequest: CancelPromoCodeRequest;
}

export interface LoyalityPromoCodeCreateRequest {
    promoCodeCreateRequest: PromoCodeCreateRequest;
}

export interface LoyalityVoucherCreateRequest {
    voucherRequest: VoucherRequest;
}

/**
 * 
 */
export class LoyalityApi extends runtime.BaseAPI {

    /**
     * Предоставление для списания бонусов юзера
     * Запрос на списание бонуса.
     */
    async loyalityBonusCreateRaw(requestParameters: LoyalityBonusCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.bonusCreateRequest === null || requestParameters.bonusCreateRequest === undefined) {
            throw new runtime.RequiredError('bonusCreateRequest','Required parameter requestParameters.bonusCreateRequest was null or undefined when calling loyalityBonusCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/loyality/bonus/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BonusCreateRequestToJSON(requestParameters.bonusCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Предоставление для списания бонусов юзера
     * Запрос на списание бонуса.
     */
    async loyalityBonusCreate(requestParameters: LoyalityBonusCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
          try{
        const response = await this.loyalityBonusCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Предоставление для истории действий бонусов юзера
     * Запрос на получение истории использования бонусов.
     */
    async loyalityBonusHistoryListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BonusHistory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/loyality/bonus/history/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BonusHistoryFromJSON));
    }

    /**
     * Предоставление для истории действий бонусов юзера
     * Запрос на получение истории использования бонусов.
     */
    async loyalityBonusHistoryList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BonusHistory>> {
          try{
        const response = await this.loyalityBonusHistoryListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Показать кол-во бонусов у юзера
     * Запрос на получение бонуса.
     */
    async loyalityBonusRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBonus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/loyality/bonus/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBonusFromJSON(jsonValue));
    }

    /**
     * Показать кол-во бонусов у юзера
     * Запрос на получение бонуса.
     */
    async loyalityBonusRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBonus> {
          try{
        const response = await this.loyalityBonusRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отмена промокода - из заказа удаляется связь с промокодом - удаляет из таблицы UsedPromoCodes запись
     * Запрос на отмену промокода.
     */
    async loyalityCancelPromoCodeCreateRaw(requestParameters: LoyalityCancelPromoCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.cancelPromoCodeRequest === null || requestParameters.cancelPromoCodeRequest === undefined) {
            throw new runtime.RequiredError('cancelPromoCodeRequest','Required parameter requestParameters.cancelPromoCodeRequest was null or undefined when calling loyalityCancelPromoCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/loyality/cancel-promo-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelPromoCodeRequestToJSON(requestParameters.cancelPromoCodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Отмена промокода - из заказа удаляется связь с промокодом - удаляет из таблицы UsedPromoCodes запись
     * Запрос на отмену промокода.
     */
    async loyalityCancelPromoCodeCreate(requestParameters: LoyalityCancelPromoCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
          try{
        const response = await this.loyalityCancelPromoCodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Предоставление для промокода
     * Запрос на создание промокода.
     */
    async loyalityPromoCodeCreateRaw(requestParameters: LoyalityPromoCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.promoCodeCreateRequest === null || requestParameters.promoCodeCreateRequest === undefined) {
            throw new runtime.RequiredError('promoCodeCreateRequest','Required parameter requestParameters.promoCodeCreateRequest was null or undefined when calling loyalityPromoCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/loyality/promo-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PromoCodeCreateRequestToJSON(requestParameters.promoCodeCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Предоставление для промокода
     * Запрос на создание промокода.
     */
    async loyalityPromoCodeCreate(requestParameters: LoyalityPromoCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
          try{
        const response = await this.loyalityPromoCodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async loyalityVoucherCreateRaw(requestParameters: LoyalityVoucherCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.voucherRequest === null || requestParameters.voucherRequest === undefined) {
            throw new runtime.RequiredError('voucherRequest','Required parameter requestParameters.voucherRequest was null or undefined when calling loyalityVoucherCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/loyality/voucher/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VoucherRequestToJSON(requestParameters.voucherRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     */
    async loyalityVoucherCreate(requestParameters: LoyalityVoucherCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
          try{
        const response = await this.loyalityVoucherCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
