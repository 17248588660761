/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Filial,
  PageNotFound,
  PaginatedStoreList,
  Site,
  Store,
} from '../models';
import {
    FilialFromJSON,
    FilialToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PaginatedStoreListFromJSON,
    PaginatedStoreListToJSON,
    SiteFromJSON,
    SiteToJSON,
    StoreFromJSON,
    StoreToJSON,
} from '../models';

export interface CompanySitesBrandsRetrieveRequest {
    id: number;
}

export interface CompanySitesRetrieveRequest {
    id: number;
}

export interface CompanyStoresClinicListRequest {
    type: CompanyStoresClinicListTypeEnum;
    brandId?: Array<string>;
    companyId?: Array<string>;
    ordering?: string;
    page?: number;
    pageSize?: number;
    region?: string;
}

export interface CompanyStoresListRequest {
    type: CompanyStoresListTypeEnum;
    brandId?: Array<string>;
    companyId?: Array<string>;
    ordering?: string;
    page?: number;
    pageSize?: number;
    region?: string;
}

export interface CompanyStoresRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     */
    async companySitesBrandsRetrieveRaw(requestParameters: CompanySitesBrandsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Filial>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companySitesBrandsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/company/sites/{id}/brands/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilialFromJSON(jsonValue));
    }

    /**
     */
    async companySitesBrandsRetrieve(requestParameters: CompanySitesBrandsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Filial> {
          try{
        const response = await this.companySitesBrandsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение списка сущностей (сайт).
     */
    async companySitesListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Site>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/company/sites/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SiteFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (сайт).
     */
    async companySitesList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Site>> {
          try{
        const response = await this.companySitesListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (сайт).
     */
    async companySitesRetrieveRaw(requestParameters: CompanySitesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Site>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companySitesRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/company/sites/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiteFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (сайт).
     */
    async companySitesRetrieve(requestParameters: CompanySitesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Site> {
          try{
        const response = await this.companySitesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async companyStoresClinicListRaw(requestParameters: CompanyStoresClinicListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedStoreList>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling companyStoresClinicList.');
        }

        const queryParameters: any = {};

        if (requestParameters.brandId) {
            queryParameters['brand_id'] = requestParameters.brandId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.companyId) {
            queryParameters['company_id'] = requestParameters.companyId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/company/stores/clinic/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedStoreListFromJSON(jsonValue));
    }

    /**
     */
    async companyStoresClinicList(requestParameters: CompanyStoresClinicListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedStoreList> {
          try{
        const response = await this.companyStoresClinicListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение списка сущностей (магазин карты где купить).
     */
    async companyStoresListRaw(requestParameters: CompanyStoresListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedStoreList>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling companyStoresList.');
        }

        const queryParameters: any = {};

        if (requestParameters.brandId) {
            queryParameters['brand_id'] = requestParameters.brandId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.companyId) {
            queryParameters['company_id'] = requestParameters.companyId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.region !== undefined) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/company/stores/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedStoreListFromJSON(jsonValue));
    }

    /**
     * Запрос на получение списка сущностей (магазин карты где купить).
     */
    async companyStoresList(requestParameters: CompanyStoresListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedStoreList> {
          try{
        const response = await this.companyStoresListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (магазин карты где купить).
     */
    async companyStoresRetrieveRaw(requestParameters: CompanyStoresRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Store>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyStoresRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/company/stores/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (магазин карты где купить).
     */
    async companyStoresRetrieve(requestParameters: CompanyStoresRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Store> {
          try{
        const response = await this.companyStoresRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}

/**
 * @export
 */
export const CompanyStoresClinicListTypeEnum = {
    GroomingSalon: 'GROOMING_SALON',
    OfflineShop: 'OFFLINE_SHOP',
    OnlineShop: 'ONLINE_SHOP',
    VetClinic: 'VET_CLINIC'
} as const;
export type CompanyStoresClinicListTypeEnum = typeof CompanyStoresClinicListTypeEnum[keyof typeof CompanyStoresClinicListTypeEnum];
/**
 * @export
 */
export const CompanyStoresListTypeEnum = {
    GroomingSalon: 'GROOMING_SALON',
    OfflineShop: 'OFFLINE_SHOP',
    OnlineShop: 'ONLINE_SHOP',
    VetClinic: 'VET_CLINIC'
} as const;
export type CompanyStoresListTypeEnum = typeof CompanyStoresListTypeEnum[keyof typeof CompanyStoresListTypeEnum];
