<template>
  <div class="flex h-full w-full flex-col gap-y-4">
    <div class="relative">
      <input
        type="text"
        class="input input_outlined w-full overflow-hidden bg-white px-10 focus:bg-white"
        placeholder="Найди свой товар"
        :value="search"
        @input="search = $event.target.value"
        @keyup.enter="emit('goto')"
      />
      <div
        class="text-blue-gray-400 absolute inset-y-0 left-0 flex items-center pl-3 lg:pl-6"
      >
        <button
          class="text-3 flex items-center font-medium leading-5"
          @click="emit('close')"
        >
          <Icon name="expand-left" class="mr-2 h-6 w-6" />
        </button>
      </div>
      <Btn
        v-if="!!search"
        class="text-blue-gray-400 hover:text-blue-gray-500 absolute inset-y-0 right-3"
        @click="emit('clear')"
      >
        <Icon name="close-round-fill" class="h-[22px] w-[22px]" />
      </Btn>
    </div>
    <slot name="history" />
    <div
      v-if="!props.products.length"
      class="text-heading-3 flex max-w-[245px] flex-grow items-center self-center text-center"
    >
      {{
        !search
          ? 'Начните вводить текст'
          : loading
            ? 'Загрузка'
            : 'Ничего не найдено'
      }}
    </div>
    <template v-else>
      <div
        class="scroll grid flex-grow auto-rows-max grid-cols-2 gap-7 overflow-y-auto pr-2"
        @click="emit('close')"
      >
        <ProductCard v-for="p in products" :key="p.id" :item="p" />
      </div>
      <div>
        <Btn lg primary class="w-full" @click.stop="emit('goto')">
          Показать все
        </Btn>
      </div>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  search: {
    type: String,
    default: '',
  },
  products: {
    type: Array,
    default: () => [],
  },
  phrases: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close', 'update:search', 'clear', 'goto'])
const search = useVModel(props, 'search', emit)
</script>
