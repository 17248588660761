<template>
  <ClientOnly>
    <component
      :is="getComponent(item)"
      v-for="item in filteredBannerItems"
      :key="item.id"
      :item="item"
      :class="$attrs.class"
      @close="hideBanner(item)"
    >
      {{ item.backgroundColor }}
    </component>
  </ClientOnly>
</template>

<script setup lang="ts">
import type { CrossBanner } from '@/openapi_fetch'

const LazyBannerTemplateDefault = resolveComponent('LazyBannerTemplateDefault')
const LazyBannerTemplateFur = resolveComponent('LazyBannerTemplateFur')
const LazyBannerTemplateMart8 = resolveComponent('LazyBannerTemplateMart8')
const LazyBannerTemplateNG = resolveComponent('LazyBannerTemplateNG')

const { SITE_ID } = useRuntimeConfig().public
const { BannersApi } = useOpenApi()

const now = new Date()
const hiddenIds = ref<number[]>([])

const { data: bannersList } = useLazyAsyncData(
  () => BannersApi.bannersCrossBannersList(),
  {
    server: false,
  },
)

watch(bannersList, setHiddenIds)

const filteredBannerItems = computed(() =>
  bannersList.value?.filter(
    (item) =>
      item.sites.includes(SITE_ID) &&
      item.isActive &&
      !hiddenIds.value.includes(item.id) &&
      now >= item.startDate &&
      now <= item.endDate,
  ),
)

function getComponent(item: CrossBanner) {
  switch (item.template?.name) {
    case 'noviy-god':
      return LazyBannerTemplateNG
    case '8-marta':
      return LazyBannerTemplateMart8
    case 'furminator':
      return LazyBannerTemplateFur
    default:
      return LazyBannerTemplateDefault
  }
}

function getCookie(item: CrossBanner) {
  const key = `hidden-banner-${item.id}`
  return useCookie(key, {
    sameSite: 'strict',
    expires: item.endDate,
  })
}

function setHiddenIds(items?: CrossBanner[] | null) {
  items?.forEach((item) => {
    if (getCookie(item).value && !hiddenIds.value.includes(item.id)) {
      hiddenIds.value.push(item.id)
    }
  })
}

function hideBanner(item: CrossBanner) {
  getCookie(item).value = Date.now().toString()
  nextTick(() => setHiddenIds(bannersList.value))
}
</script>
