import type { SetDeliveryInfoRequest } from '@/openapi_fetch'

export enum OrderStatusTextEnum {
  DRAFT = 'Черновик',
  APPROVAL = 'На согласовании',
  CONFIRMED = 'Согласован',
  WAREHOUSE = 'Передан на склад',
  ASSEMBLE = 'В сборке',
  READY_FOR_DELIVERY = 'Подготовлен к доставке',
  DELIVERED = 'Доставлен',
  CANCELED = 'Отменен',
  CONTROL = 'Контроль',
  TEMPLATE = 'Шаблон',
  AUTOORDER = 'Автозаказ',
}

export enum OrderStatusIntegrationIdEnum {
  DRAFT = '000000001', // Черновик (Оформление)
  APPROVAL = '000000002', // На согласовании (Оформление)
  CONFIRMED = '000000003', // Согласован (Доставляется)
  WAREHOUSE = '000000004', // Передан на склад (Доставляется)
  ASSEMBLE = '000000008', // В сборке (Доставляется)
  READY_FOR_DELIVERY = '000000005', // Подготовлен к доставке (Доставляется)
  DELIVERED = '000000006', // Доставлен
  CANCELED = '000000007', // Отменен
  CONTROL = '000000009', // Контроль
  TEMPLATE = '000000026', // Шаблон
  AUTOORDER = '000000025', // Автозаказ
}

export enum OrderPaymentMethodEnum {
  PAYMENT_CASH = 'НР       ', // Наличный расчет
  PAYMENT_CASHLESS = 'БР       ', // Безналичный расчет
  PAYMENT_CARD = 'ОБК      ', // Оплата по банковской карте
  PAYMENT_CARD_TO_COURIER = 'ОБК000001', // Картой курьеру
}

export enum DeliveryTypeEnum {
  COURIER_VALTA = '0001',
  COURIER_CDEK = '0002',
  PICK_UP = '0003',
}

export enum OrderDeliveryPointsTypeEnum {
  PVZ = 'PVZ', //  для отображения складов СДЭК;
  POSTAMAT = 'POSTAMAT', // для отображения постаматов СДЭК;
  ALL = 'ALL', // для отображения всех ПВЗ независимо от их типа;
}

export interface IOrderDeliveryZonesCollectionFeature {
  type: 'Feature'
  id: number
  geometry: {
    type: 'Polygon'
    coordinates: number[][][]
  }
  properties: {
    area: string
    fillColor?: string
    fillOpacity?: number
    strokeColor?: string
    strokeWidth?: number
    strokeOpacity?: number
  }
}

export interface IOrderDeliveryZonesCollection {
  type: 'FeatureCollection'
  metadata: Record<string, string>
  features?: IOrderDeliveryZonesCollectionFeature[]
}

export type ISetDeliveryInfoRequest = Pick<
  SetDeliveryInfoRequest,
  'deliveryType'
> &
  Partial<Pick<SetDeliveryInfoRequest, 'address' | 'cdekCode'>>

export type IOrderDeliveryAddressSuccessEmit = ISetDeliveryInfoRequest & {
  deliveryPointRegion?: string
  deliveryPointAddress?: string
}
