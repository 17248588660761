import type { Product, ProductCatalog, ProductUnit } from '@/openapi_fetch'
import type {
  IProductAction,
  IProductActions,
  IProductActionShareLink,
  IProductActionShareNetwork,
} from '@/types'
import ecommerce from '@/services/ecommerce'

export function useProductDesciption() {
  function getDesciption(value?: string) {
    const chosenSentences = [] as string[]
    // разбивка текста на предложения с разделителем ! или .
    // в массиве будут содержаться разделители
    const sentencesArr = (value || '').split(/(!|\.)/)
    // используются только 2 и 3 предложения
    let i = 2
    while (i < sentencesArr.length) {
      const value = sentencesArr[i]
        // очистка от html тегов
        .replace(/<[^>]*>?/gm, '')
        // знаки перехода строки или &nbsp; заменяются на ' '
        .replace(/[\r\n&nbsp;]/gm, ' ')
        // очистка от лишних пробелов
        .replace(/ +(?= )/g, '')
        .trim()
      const delemiter = sentencesArr[i + 1]
      chosenSentences.push(`${value}${delemiter}`)
      if (chosenSentences.length === 2) {
        break
      }
      i += 2
    }
    const secondAndThird = chosenSentences.filter(Boolean).join(' ')
    return secondAndThird.length > 200 ? chosenSentences[0] : secondAndThird
  }

  return {
    getDesciption,
  }
}

export default function (
  _product: Ref<ProductCatalog | Product | null | undefined>,
  withProductUnits: boolean = false,
) {
  // const { SITE_ID } = useRuntimeConfig().public
  const route = useRoute()
  const { t } = useI18n()
  const cartStore = useCartStore()
  const { loggedIn, login } = useAuth()
  const profileStore = useProfileStore()
  const { CatalogApi } = useOpenApi()
  const { getDesciption } = useProductDesciption()

  const isLinkCopied = ref(false)
  const changeCartLoading = ref(false)
  const changeCartErrorMessage = ref('')
  const isNotified = ref(false)
  const notifyLoading = ref(false)
  const notifyErrorMessage = ref('')
  const toggleFavoriteLoading = ref(false)
  const toggleFavoriteErrorMessage = ref('')
  const toggleCompareLoading = ref(false)
  const toggleCompareErrorMessage = ref('')
  const productUnit = ref<ProductUnit | undefined>()

  const countReview = computed(() => {
    const p = _product.value
    if (!p) return 0
    if ('reviewQuantity' in p) return p.reviewQuantity
    if ('reviewsCount' in p) return p.reviewsCount
    return 0
  })

  const actions = computed<IProductActions>(() => {
    const p = _product.value
    const pId = p?.id
    const pUrl = process.client ? window.location.href : ''
    const networkData = {
      url: pUrl,
      title: p?.name ?? '',
      description: p && 'description' in p ? getDesciption(p.description) : '',
      media: p && 'productImages' in p ? p.productImages[0]?.url : '',
    }

    function goto(elementSelector: string) {
      const element = document.querySelector(elementSelector)
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY
        window.scrollTo({ top: elementPosition - 300, behavior: 'smooth' })
      }
    }

    // Дженерика при вызове computed не досточно, чтобы отследить лишние свойства
    const actions: IProductActions = [
      {
        name: 'rating',
        hidden: !p?.rating,
        type: 'action',
        icon: 'star',
        title: p?.rating.toString() ?? '',
        action: () => goto('#reviews'),
        isActive: false,
      },
      {
        name: 'reviews',
        type: 'action',
        icon: 'comment',
        title:
          countReview.value !== 0
            ? t('reviews', countReview.value)
            : 'Оставить отзыв',
        action: () => goto('#reviews'),
        isActive: false,
      },
      {
        name: 'favorite',
        className: 'hidden md:block',
        type: 'action',
        icon: 'favorite',
        title: isFavorite.value ? 'В избранном' : 'В избранное',
        action: () => pId && profileStore.toggleFavorite(pId),
        isActive: isFavorite.value,
      },
      {
        name: 'compare',
        className: 'hidden md:block',
        type: 'action',
        icon: 'compare',
        title: isCompare.value ? 'В сравнении' : 'В сравнение',
        action: () => pId && profileStore.toggleCompare(pId),
        isActive: isCompare.value,
      },
      {
        name: 'share',
        className: 'hidden md:block',
        type: 'share',
        icon: 'share',
        title: 'Поделиться',
        children: [
          {
            name: 'link',
            type: 'share-link',
            icon: isLinkCopied.value ? 'check-circle' : 'share-alt',
            title: isLinkCopied.value
              ? 'Ссылка скопирована'
              : 'Скопировать ссылку',
            action: async () => {
              await navigator.clipboard.writeText(pUrl)
              isLinkCopied.value = true
            },
            success: isLinkCopied.value,
          },
          {
            name: 'vk',
            type: 'share-network',
            icon: 'vk',
            title: 'Вконтакте',
            network: 'vk',
            data: networkData,
          },
          {
            name: 'odnoklassniki',
            type: 'share-network',
            icon: 'od',
            title: 'Одноклассники',
            network: 'odnoklassniki',
            data: networkData,
          },
          {
            name: 'telegram',
            type: 'share-network',
            icon: 'telegram',
            title: 'Telegram',
            network: 'telegram',
            data: networkData,
          },
        ],
      },
      {
        name: 'available',
        type: 'tag',
        title: available.value.title,
        color: available.value.color,
      },
    ] as IProductActions

    actions.exclude = function (names) {
      return this.reduce((a: Array<IProductAction>, c) => {
        if (names.includes(c.name)) return a
        if (c.type !== 'share') return [...a, c]
        return [
          ...a,
          {
            ...c,
            // children: actions.exclude.call(c.children, names) // if regular structure
            children: c.children.reduce(
              (
                aa: Array<IProductActionShareLink | IProductActionShareNetwork>,
                cc,
              ) => {
                if (names.includes(cc.name)) return aa
                return [...aa, cc]
              },
              [],
            ),
          },
        ]
      }, [])
    }
    return actions
  })

  // активно только для страницы товара кот федор
  // при добавлении функциональности в другие, см. изменения в GEM-2323
  // или добавить в шаблон
  // <ProductUnit
  //   v-if="isProductUnitEnabled && product"
  //   v-model="productUnit"
  //   :product="product"
  //   :user-price="userPrice"
  //   :in-cart="inCart"
  //   class="mt-8"
  // />
  // и заменить
  // @click="changeCart(inCart - 1)" на @click="decrementCart"
  // @click="changeCart(inCart + 1)" на @click="incrementCart"
  // @click="changeCart(1)" на @click="changeCart(productUnitCount)"

  // const productUnitList = ref([])
  const { data: productUnitList } = useLazyAsyncData(
    `product-${_product.value?.id}-units`,
    () =>
      withProductUnits && _product.value?.id
        ? CatalogApi.catalogProductsUnitList({
            id: _product.value?.id,
          })
        : Promise.resolve(null),
    {
      transform: (v) => {
        return v?.sort((a, b) => (a.number ?? 0) - (b.number ?? 0)) ?? []
      },
      watch: [() => _product.value?.id],
    },
  )

  const isProductUnitEnabled = computed(
    () => true,
    // [17, 1, 2].includes(SITE_ID as number),
  )

  const productUnitCount = computed(() =>
    isProductUnitEnabled.value ? productUnit.value?.number ?? 1 : 1,
  )

  const isRegionNotify = computed(
    () =>
      _product.value?.mskQuantity &&
      ![77, 50].includes(Number(profileStore.deliveryAddressRegion)),
  )

  const isFavorite = computed(() =>
    _product.value ? profileStore.isFavorite(_product.value.id) : false,
  )

  const isCompare = computed(() => {
    if (_product.value) {
      return profileStore.compareIds.includes(_product.value?.id || 0)
    }
    return false
  })

  const discountPercent = computed(() => {
    return _product.value?.discount?.discountPercent &&
      _product.value?.discount?.userPrice
      ? _product.value.discount.discountPercent
      : 0
  })

  const userPrice = computed(() => {
    if (discountPercent.value) {
      return _product.value?.discount?.userPrice ?? 0
    }
    return _product.value?.price ?? 0
  })

  const available = computed(() => {
    const _quantity = _product.value?.quantity ?? 0
    const r = +_quantity - inCart.value
    const q = r <= 0 ? 0 : r
    const a = { color: 'text-danger', amount: q, title: 'Нет в наличии' }

    if (q > 0 && q <= 10) {
      a.color = 'text-warning'
      a.title = 'Мало'
    } else if (q > 10) {
      a.color = 'text-success'
      a.title = 'В наличии'
    }

    return a
  })

  const inCart = computed(
    () =>
      (_product.value &&
        cartStore.productsByIds[_product.value.id]?.quantity) ??
      0,
  )

  function incrementCart() {
    changeCart(inCart.value + productUnitCount.value)
  }

  function decrementCart() {
    changeCart(
      productUnitCount.value > inCart.value
        ? 0
        : inCart.value - productUnitCount.value,
    )
  }

  function changeCart(quantity: number) {
    if (!_product.value) {
      return
    }

    try {
      changeCartErrorMessage.value = ''
      changeCartLoading.value = true

      const payload = [
        {
          productId: _product.value.id,
          quantity,
        },
      ]

      const optimisticData = [
        {
          id: _product.value.id,
          title: _product.value.name,
          productId: _product.value.id,
          productIntegrationId: '',
          productType: [],
          petType: [],
          code: '',
          bonusOff: 0,
          stockBalances: _product.value.quantity,
          coverPic: (_product.value as ProductCatalog).coverPic,
          imageS3: (_product.value as ProductCatalog).imageS3,
          totalPrice: _product.value.price,
          quantity,
          price: _product.value.price,
          automaticDiscount: 0,
          isPromoOneRub: false,
          totalUserPrice: _product.value.price,
          discountValue: 0,
          manualDiscount: 0,
          productTrademark: (_product.value as ProductCatalog)?.trademark?.name,
          characteristics: [],
        },
      ]

      cartStore.queueUpdate(payload, 'createOrDestroy', optimisticData)
    } catch (error) {
      changeCartErrorMessage.value = (error as Error).message
      // eslint-disable-next-line no-console
      console.error('[queueUpdate error]', error)
    } finally {
      changeCartLoading.value = false
    }
  }

  async function notify(id: number | undefined) {
    if (!id) return
    if (!loggedIn.value) {
      return login(route.fullPath)
    }

    try {
      notifyErrorMessage.value = ''
      notifyLoading.value = true
      await CatalogApi.catalogProductsNotifyRetrieve({ id })

      if (!profileStore.favoriteIds.includes(id)) {
        await toggleFavorite(id)
      }

      isNotified.value = true
    } catch (error) {
      notifyErrorMessage.value = (error as Error).message
      throw error
    } finally {
      notifyLoading.value = false
    }
  }

  async function toggleFavorite(id: number, cb?: () => void) {
    try {
      toggleFavoriteErrorMessage.value = ''
      toggleFavoriteLoading.value = true

      await profileStore.toggleFavorite(id)

      cb && cb()
    } catch (error) {
      toggleFavoriteErrorMessage.value = (error as Error).message

      throw error
    } finally {
      toggleFavoriteLoading.value = false
    }
  }

  async function toggleCompare(id: number, cb?: () => void) {
    try {
      toggleCompareErrorMessage.value = ''
      toggleCompareLoading.value = true

      await profileStore.toggleCompare(id)

      cb && cb()
    } catch (error) {
      toggleCompareErrorMessage.value = (error as Error).message

      throw error
    } finally {
      toggleCompareLoading.value = false
    }
  }

  function setEcommerce(item: ProductCatalog | Product) {
    const _item = {
      item_name: item.name,
      item_id: item.id,
      price: String(item.price),
      item_brand: item.trademark.name || 'Monge',
      quantity: String(inCart.value),
    }
    ecommerce.viewProduct(_item)
    ecommerce.selectProduct(_item)
  }

  return {
    actions,
    isProductUnitEnabled,
    productUnitList,
    productUnit,
    productUnitCount,
    isRegionNotify,
    discountPercent,
    userPrice,
    available,
    inCart,
    changeCartLoading,
    changeCartErrorMessage,
    isNotified,
    notifyLoading,
    notifyErrorMessage,
    toggleFavoriteLoading,
    toggleFavoriteErrorMessage,
    isFavorite,
    toggleCompareLoading,
    toggleCompareErrorMessage,
    isCompare,
    incrementCart,
    decrementCart,
    changeCart,
    notify,
    toggleFavorite,
    toggleCompare,
    setEcommerce,
  }
}
