/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountProduct } from './DiscountProduct';
import {
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';
import type { ImageS3 } from './ImageS3';
import {
    ImageS3FromJSON,
    ImageS3FromJSONTyped,
    ImageS3ToJSON,
} from './ImageS3';
import type { TradeMark } from './TradeMark';
import {
    TradeMarkFromJSON,
    TradeMarkFromJSONTyped,
    TradeMarkToJSON,
} from './TradeMark';

/**
 * 
 * @export
 * @interface ProductCatalog
 */
export interface ProductCatalog {
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly id: number;
    /**
     * 
     * @type {Array<ImageS3>}
     * @memberof ProductCatalog
     */
    readonly imageS3: Array<ImageS3>;
    /**
     * 
     * @type {string}
     * @memberof ProductCatalog
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly price: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly quantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    productTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    weight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCatalog
     */
    readonly coverPic: string;
    /**
     * 
     * @type {DiscountProduct}
     * @memberof ProductCatalog
     */
    readonly discount: DiscountProduct;
    /**
     * Средний рейтинг продукта по всем одобренным отзывам
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly rating: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCatalog
     */
    isNovelty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCatalog
     */
    isMonoprotein?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly bonusAccrual: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly reviewsCount: number;
    /**
     * 
     * @type {TradeMark}
     * @memberof ProductCatalog
     */
    readonly trademark: TradeMark;
    /**
     * 
     * @type {string}
     * @memberof ProductCatalog
     */
    code?: string | null;
    /**
     * Остатки по Москве
     * @type {number}
     * @memberof ProductCatalog
     */
    readonly mskQuantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCatalog
     */
    readonly isRegionAvailable: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCatalog
     */
    dailyConsumptionPhoto?: string | null;
}

/**
 * Check if a given object implements the ProductCatalog interface.
 */
export function instanceOfProductCatalog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "imageS3" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "productTypeId" in value;
    isInstance = isInstance && "coverPic" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "bonusAccrual" in value;
    isInstance = isInstance && "reviewsCount" in value;
    isInstance = isInstance && "trademark" in value;
    isInstance = isInstance && "mskQuantity" in value;
    isInstance = isInstance && "isRegionAvailable" in value;

    return isInstance;
}

export function ProductCatalogFromJSON(json: any): ProductCatalog {
    return ProductCatalogFromJSONTyped(json, false);
}

export function ProductCatalogFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCatalog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'imageS3': ((json['image_s3'] as Array<any>).map(ImageS3FromJSON)),
        'name': json['name'],
        'price': json['price'],
        'quantity': json['quantity'],
        'productTypeId': json['product_type_id'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'coverPic': json['cover_pic'],
        'discount': DiscountProductFromJSON(json['discount']),
        'rating': json['rating'],
        'isNovelty': !exists(json, 'is_novelty') ? undefined : json['is_novelty'],
        'isMonoprotein': !exists(json, 'is_monoprotein') ? undefined : json['is_monoprotein'],
        'bonusAccrual': json['bonus_accrual'],
        'reviewsCount': json['reviews_count'],
        'trademark': TradeMarkFromJSON(json['trademark']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'mskQuantity': json['msk_quantity'],
        'isRegionAvailable': json['is_region_available'],
        'dailyConsumptionPhoto': !exists(json, 'daily_consumption_photo') ? undefined : json['daily_consumption_photo'],
    };
}

export function ProductCatalogToJSON(value?: ProductCatalog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'product_type_id': value.productTypeId,
        'weight': value.weight,
        'is_novelty': value.isNovelty,
        'is_monoprotein': value.isMonoprotein,
        'code': value.code,
        'daily_consumption_photo': value.dailyConsumptionPhoto,
    };
}

