import type { MaskaDetail } from 'maska'

export default function () {
  const maskDetail = ref<MaskaDetail>({
    masked: '',
    unmasked: '',
    completed: false,
  })

  const maskOptions = reactive({
    mask: (value: string) =>
      value.startsWith('8') ? '8 (###) ###-##-##' : '+7 (###) ###-##-##',
    onMaska: (detail: MaskaDetail) => {
      maskDetail.value = detail
    },
  })
  const phoneCodeMask = reactive({
    mask: () => '####',
    onMaska: (detail: MaskaDetail) => {
      maskDetail.value = detail
    },
  })
  const phonePattern = computed(() =>
    maskDetail.value.masked.startsWith('8')
      ? '8\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}'
      : '\\+7\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}',
  )

  return {
    maskDetail,
    phoneCodeMask,
    maskOptions,
    phonePattern,
  }
}
