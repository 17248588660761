/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestRegionFilialPost,
  BaseFilter,
  Breed,
  CategoryDetail,
  CategoryList,
  Characteristic,
  CharacteristicSameProduct,
  FilialRegion,
  PaginatedProductCatalogList,
  PetType,
  Product,
  ProductCharacteristic,
  ProductUnit,
  RegionFilialPostRequest,
  SiteList,
  Status,
  SuccessResponsePostFilial,
} from '../models';
import {
    BadRequestRegionFilialPostFromJSON,
    BadRequestRegionFilialPostToJSON,
    BaseFilterFromJSON,
    BaseFilterToJSON,
    BreedFromJSON,
    BreedToJSON,
    CategoryDetailFromJSON,
    CategoryDetailToJSON,
    CategoryListFromJSON,
    CategoryListToJSON,
    CharacteristicFromJSON,
    CharacteristicToJSON,
    CharacteristicSameProductFromJSON,
    CharacteristicSameProductToJSON,
    FilialRegionFromJSON,
    FilialRegionToJSON,
    PaginatedProductCatalogListFromJSON,
    PaginatedProductCatalogListToJSON,
    PetTypeFromJSON,
    PetTypeToJSON,
    ProductFromJSON,
    ProductToJSON,
    ProductCharacteristicFromJSON,
    ProductCharacteristicToJSON,
    ProductUnitFromJSON,
    ProductUnitToJSON,
    RegionFilialPostRequestFromJSON,
    RegionFilialPostRequestToJSON,
    SiteListFromJSON,
    SiteListToJSON,
    StatusFromJSON,
    StatusToJSON,
    SuccessResponsePostFilialFromJSON,
    SuccessResponsePostFilialToJSON,
} from '../models';

export interface CatalogBreedsListRequest {
    petType?: string;
}

export interface CatalogCategoryRetrieveRequest {
    slug: string;
}

export interface CatalogFiltersListRequest {
    slug?: Array<string>;
}

export interface CatalogProductsListRequest {
    category?: Array<number>;
    characteristic?: Array<number>;
    characteristicSlugs?: Array<string>;
    code?: Array<string>;
    hit?: boolean;
    id?: Array<string>;
    inStock?: boolean;
    isNovelty?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
    petId?: string;
    priceMax?: number;
    priceMin?: number;
    productLine?: Array<string>;
    productType?: Array<string>;
    search?: string;
    site?: number;
    weightMax?: number | null;
    weightMin?: number | null;
}

export interface CatalogProductsNotifyRetrieveRequest {
    id: number;
}

export interface CatalogProductsRetrieveRequest {
    id: number;
}

export interface CatalogProductsSameProductsListRequest {
    id: number;
}

export interface CatalogProductsUnitListRequest {
    id: number;
}

export interface CatalogRegionsCreateRequest {
    regionFilialPostRequest?: RegionFilialPostRequest;
}

/**
 * 
 */
export class CatalogApi extends runtime.BaseAPI {

    /**
     * Отдаёт список пород
     * Запрос на получение списка сущностей (породы питомцев).
     */
    async catalogBreedsListRaw(requestParameters: CatalogBreedsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Breed>>> {
        const queryParameters: any = {};

        if (requestParameters.petType !== undefined) {
            queryParameters['pet_type'] = requestParameters.petType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/breeds/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BreedFromJSON));
    }

    /**
     * Отдаёт список пород
     * Запрос на получение списка сущностей (породы питомцев).
     */
    async catalogBreedsList(requestParameters: CatalogBreedsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Breed>> {
          try{
        const response = await this.catalogBreedsListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async catalogCategoryListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/category/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryListFromJSON));
    }

    /**
     */
    async catalogCategoryList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryList>> {
          try{
        const response = await this.catalogCategoryListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async catalogCategoryRetrieveRaw(requestParameters: CatalogCategoryRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDetail>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling catalogCategoryRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/category/{slug}/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDetailFromJSON(jsonValue));
    }

    /**
     */
    async catalogCategoryRetrieve(requestParameters: CatalogCategoryRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDetail> {
          try{
        const response = await this.catalogCategoryRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async catalogCharacteristicListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductCharacteristic>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/characteristic/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductCharacteristicFromJSON));
    }

    /**
     */
    async catalogCharacteristicList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductCharacteristic>> {
          try{
        const response = await this.catalogCharacteristicListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение списка сущностей (характеристика).
     */
    async catalogFiltersListRaw(requestParameters: CatalogFiltersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Characteristic>>> {
        const queryParameters: any = {};

        if (requestParameters.slug) {
            queryParameters['slug'] = requestParameters.slug.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/filters/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CharacteristicFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (характеристика).
     */
    async catalogFiltersList(requestParameters: CatalogFiltersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Characteristic>> {
          try{
        const response = await this.catalogFiltersListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт список типов питомцев
     * Запрос на получение списка сущностей (вид питомца).
     */
    async catalogPetTypeListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PetType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/pet-type/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PetTypeFromJSON));
    }

    /**
     * Отдаёт список типов питомцев
     * Запрос на получение списка сущностей (вид питомца).
     */
    async catalogPetTypeList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PetType>> {
          try{
        const response = await this.catalogPetTypeListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Вернуть мин и макс значения цены и веса товаров в каталоге под филиалом
     * Запрос на получение минимальных и максимальных цены и веса.
     */
    async catalogProductsBaseFilterRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BaseFilter>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/products/base-filter/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BaseFilterFromJSON(jsonValue));
    }

    /**
     * Вернуть мин и макс значения цены и веса товаров в каталоге под филиалом
     * Запрос на получение минимальных и максимальных цены и веса.
     */
    async catalogProductsBaseFilterRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BaseFilter> {
          try{
        const response = await this.catalogProductsBaseFilterRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение списка сущностей (товар).
     */
    async catalogProductsListRaw(requestParameters: CatalogProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedProductCatalogList>> {
        const queryParameters: any = {};

        if (requestParameters.category) {
            queryParameters['category'] = requestParameters.category.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.characteristic) {
            queryParameters['characteristic'] = requestParameters.characteristic.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.characteristicSlugs) {
            queryParameters['characteristic_slugs'] = requestParameters.characteristicSlugs.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.code) {
            queryParameters['code'] = requestParameters.code.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.hit !== undefined) {
            queryParameters['hit'] = requestParameters.hit;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.inStock !== undefined) {
            queryParameters['in_stock'] = requestParameters.inStock;
        }

        if (requestParameters.isNovelty !== undefined) {
            queryParameters['is_novelty'] = requestParameters.isNovelty;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.petId !== undefined) {
            queryParameters['pet_id'] = requestParameters.petId;
        }

        if (requestParameters.priceMax !== undefined) {
            queryParameters['price_max'] = requestParameters.priceMax;
        }

        if (requestParameters.priceMin !== undefined) {
            queryParameters['price_min'] = requestParameters.priceMin;
        }

        if (requestParameters.productLine) {
            queryParameters['product_line'] = requestParameters.productLine.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.productType) {
            queryParameters['product_type'] = requestParameters.productType.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.site !== undefined) {
            queryParameters['site'] = requestParameters.site;
        }

        if (requestParameters.weightMax !== undefined) {
            queryParameters['weight_max'] = requestParameters.weightMax;
        }

        if (requestParameters.weightMin !== undefined) {
            queryParameters['weight_min'] = requestParameters.weightMin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/products/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedProductCatalogListFromJSON(jsonValue));
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение списка сущностей (товар).
     */
    async catalogProductsList(requestParameters: CatalogProductsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedProductCatalogList> {
          try{
        const response = await this.catalogProductsListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение уведовлений.
     */
    async catalogProductsNotifyRetrieveRaw(requestParameters: CatalogProductsNotifyRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogProductsNotifyRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/products/{id}/notify/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение уведовлений.
     */
    async catalogProductsNotifyRetrieve(requestParameters: CatalogProductsNotifyRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
          try{
        const response = await this.catalogProductsNotifyRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение экземпляра сущности (товар).
     */
    async catalogProductsRetrieveRaw(requestParameters: CatalogProductsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogProductsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/products/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение экземпляра сущности (товар).
     */
    async catalogProductsRetrieve(requestParameters: CatalogProductsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
          try{
        const response = await this.catalogProductsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     */
    async catalogProductsSameProductsListRaw(requestParameters: CatalogProductsSameProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CharacteristicSameProduct>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogProductsSameProductsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/products/{id}/same-products/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CharacteristicSameProductFromJSON));
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     */
    async catalogProductsSameProductsList(requestParameters: CatalogProductsSameProductsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CharacteristicSameProduct>> {
          try{
        const response = await this.catalogProductsSameProductsListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение информации по упаковкам.
     */
    async catalogProductsUnitListRaw(requestParameters: CatalogProductsUnitListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductUnit>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling catalogProductsUnitList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/products/{id}/unit/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductUnitFromJSON));
    }

    /**
     * Отдаёт список продуктов, продукт - list, retrieve
     * Запрос на получение информации по упаковкам.
     */
    async catalogProductsUnitList(requestParameters: CatalogProductsUnitListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductUnit>> {
          try{
        const response = await this.catalogProductsUnitListRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на изменения региона у пользователя.
     */
    async catalogRegionsCreateRaw(requestParameters: CatalogRegionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponsePostFilial>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/regions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegionFilialPostRequestToJSON(requestParameters.regionFilialPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponsePostFilialFromJSON(jsonValue));
    }

    /**
     * Запрос на изменения региона у пользователя.
     */
    async catalogRegionsCreate(requestParameters: CatalogRegionsCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponsePostFilial> {
          try{
        const response = await this.catalogRegionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получения списка регионов.
     */
    async catalogRegionsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilialRegion>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/regions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilialRegionFromJSON));
    }

    /**
     * Запрос на получения списка регионов.
     */
    async catalogRegionsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilialRegion>> {
          try{
        const response = await this.catalogRegionsListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async catalogSiteListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SiteList>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/catalog/site/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SiteListFromJSON));
    }

    /**
     */
    async catalogSiteList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SiteList>> {
          try{
        const response = await this.catalogSiteListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
