/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CasCheckCodeRequest,
  ConfirmCode,
  ConfirmCodeRequest,
  SendCode,
  SendCodeRequest,
  Status,
} from '../models';
import {
    CasCheckCodeRequestFromJSON,
    CasCheckCodeRequestToJSON,
    ConfirmCodeFromJSON,
    ConfirmCodeToJSON,
    ConfirmCodeRequestFromJSON,
    ConfirmCodeRequestToJSON,
    SendCodeFromJSON,
    SendCodeToJSON,
    SendCodeRequestFromJSON,
    SendCodeRequestToJSON,
    StatusFromJSON,
    StatusToJSON,
} from '../models';

export interface VerificationCasCheckCodeCreateRequest {
    casCheckCodeRequest: CasCheckCodeRequest;
}

export interface VerificationConfirmCreateRequest {
    confirmCodeRequest: ConfirmCodeRequest;
}

export interface VerificationSendCreateRequest {
    sendCodeRequest: SendCodeRequest;
}

/**
 * 
 */
export class VerificationApi extends runtime.BaseAPI {

    /**
     * Используется внутри CAS при авторизации и регистрации.
     * Проверка актуальности кода верификации.
     */
    async verificationCasCheckCodeCreateRaw(requestParameters: VerificationCasCheckCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.casCheckCodeRequest === null || requestParameters.casCheckCodeRequest === undefined) {
            throw new runtime.RequiredError('casCheckCodeRequest','Required parameter requestParameters.casCheckCodeRequest was null or undefined when calling verificationCasCheckCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/verification/cas/check-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CasCheckCodeRequestToJSON(requestParameters.casCheckCodeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Используется внутри CAS при авторизации и регистрации.
     * Проверка актуальности кода верификации.
     */
    async verificationCasCheckCodeCreate(requestParameters: VerificationCasCheckCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verificationCasCheckCodeCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async verificationConfirmCreateRaw(requestParameters: VerificationConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfirmCode>> {
        if (requestParameters.confirmCodeRequest === null || requestParameters.confirmCodeRequest === undefined) {
            throw new runtime.RequiredError('confirmCodeRequest','Required parameter requestParameters.confirmCodeRequest was null or undefined when calling verificationConfirmCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/verification/confirm/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmCodeRequestToJSON(requestParameters.confirmCodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmCodeFromJSON(jsonValue));
    }

    /**
     */
    async verificationConfirmCreate(requestParameters: VerificationConfirmCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfirmCode> {
          try{
        const response = await this.verificationConfirmCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async verificationSendCreateRaw(requestParameters: VerificationSendCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendCode>> {
        if (requestParameters.sendCodeRequest === null || requestParameters.sendCodeRequest === undefined) {
            throw new runtime.RequiredError('sendCodeRequest','Required parameter requestParameters.sendCodeRequest was null or undefined when calling verificationSendCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/verification/send/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendCodeRequestToJSON(requestParameters.sendCodeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendCodeFromJSON(jsonValue));
    }

    /**
     */
    async verificationSendCreate(requestParameters: VerificationSendCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendCode> {
          try{
        const response = await this.verificationSendCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
