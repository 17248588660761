/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { ReviewAnswer } from './ReviewAnswer';
import {
    ReviewAnswerFromJSON,
    ReviewAnswerFromJSONTyped,
    ReviewAnswerToJSON,
} from './ReviewAnswer';
import type { Reviewer } from './Reviewer';
import {
    ReviewerFromJSON,
    ReviewerFromJSONTyped,
    ReviewerToJSON,
} from './Reviewer';

/**
 * Сериализация отзывов клиентов на товары
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    siteId?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    eatability?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    taste?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    packageQuality?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    petGameInvolvementRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    strengthRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    designRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    easeOfUseRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    materialQualityRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    materialFittingsQualityRate?: number;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    petUseComfortRate?: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    text?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Review
     */
    readonly timestamp: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Review
     */
    approved?: boolean;
    /**
     * 
     * @type {Reviewer}
     * @memberof Review
     */
    readonly user: Reviewer;
    /**
     * Средний рейтинг от пользователя по трем значениям
     * @type {number}
     * @memberof Review
     */
    readonly rating: number;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Review
     */
    readonly reviewAttachments: Array<Attachment>;
    /**
     * 
     * @type {Array<ReviewAnswer>}
     * @memberof Review
     */
    readonly reviewAnswers: Array<ReviewAnswer>;
}

/**
 * Check if a given object implements the Review interface.
 */
export function instanceOfReview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "reviewAttachments" in value;
    isInstance = isInstance && "reviewAnswers" in value;

    return isInstance;
}

export function ReviewFromJSON(json: any): Review {
    return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Review {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'siteId': !exists(json, 'site_id') ? undefined : json['site_id'],
        'productId': json['product_id'],
        'eatability': !exists(json, 'eatability') ? undefined : json['eatability'],
        'taste': !exists(json, 'taste') ? undefined : json['taste'],
        'packageQuality': !exists(json, 'package_quality') ? undefined : json['package_quality'],
        'petGameInvolvementRate': !exists(json, 'pet_game_involvement_rate') ? undefined : json['pet_game_involvement_rate'],
        'strengthRate': !exists(json, 'strength_rate') ? undefined : json['strength_rate'],
        'designRate': !exists(json, 'design_rate') ? undefined : json['design_rate'],
        'easeOfUseRate': !exists(json, 'ease_of_use_rate') ? undefined : json['ease_of_use_rate'],
        'materialQualityRate': !exists(json, 'material_quality_rate') ? undefined : json['material_quality_rate'],
        'materialFittingsQualityRate': !exists(json, 'material_fittings_quality_rate') ? undefined : json['material_fittings_quality_rate'],
        'petUseComfortRate': !exists(json, 'pet_use_comfort_rate') ? undefined : json['pet_use_comfort_rate'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'timestamp': (new Date(json['timestamp'])),
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'user': ReviewerFromJSON(json['user']),
        'rating': json['rating'],
        'reviewAttachments': ((json['review_attachments'] as Array<any>).map(AttachmentFromJSON)),
        'reviewAnswers': ((json['review_answers'] as Array<any>).map(ReviewAnswerFromJSON)),
    };
}

export function ReviewToJSON(value?: Review | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'site_id': value.siteId,
        'product_id': value.productId,
        'eatability': value.eatability,
        'taste': value.taste,
        'package_quality': value.packageQuality,
        'pet_game_involvement_rate': value.petGameInvolvementRate,
        'strength_rate': value.strengthRate,
        'design_rate': value.designRate,
        'ease_of_use_rate': value.easeOfUseRate,
        'material_quality_rate': value.materialQualityRate,
        'material_fittings_quality_rate': value.materialFittingsQualityRate,
        'pet_use_comfort_rate': value.petUseComfortRate,
        'text': value.text,
        'approved': value.approved,
    };
}

