/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderItem } from './OrderItem';
import {
    OrderItemFromJSON,
    OrderItemFromJSONTyped,
    OrderItemToJSON,
} from './OrderItem';
import type { PromoCode } from './PromoCode';
import {
    PromoCodeFromJSON,
    PromoCodeFromJSONTyped,
    PromoCodeToJSON,
} from './PromoCode';

/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    filial: string;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    filialId: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    orderId: number;
    /**
     * 
     * @type {boolean}
     * @memberof Cart
     */
    createOrder: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Cart
     */
    isChanged: boolean;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    bonusUsed: number;
    /**
     * 
     * @type {boolean}
     * @memberof Cart
     */
    hasAutoOrder: boolean;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    readonly totalProductCount: number;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Cart
     */
    orderItems: Array<OrderItem>;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    bonusAccrual: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    bonusWriteOff: number;
    /**
     * 
     * @type {PromoCode}
     * @memberof Cart
     */
    readonly promoCode: PromoCode;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    absoluteDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    readonly totalOrderPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    readonly totalOrderUserPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    readonly totalOrderPercentDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof Cart
     */
    readonly totalOrderDiscount: number;
}

/**
 * Check if a given object implements the Cart interface.
 */
export function instanceOfCart(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filial" in value;
    isInstance = isInstance && "filialId" in value;
    isInstance = isInstance && "orderId" in value;
    isInstance = isInstance && "createOrder" in value;
    isInstance = isInstance && "isChanged" in value;
    isInstance = isInstance && "bonusUsed" in value;
    isInstance = isInstance && "hasAutoOrder" in value;
    isInstance = isInstance && "totalProductCount" in value;
    isInstance = isInstance && "orderItems" in value;
    isInstance = isInstance && "bonusAccrual" in value;
    isInstance = isInstance && "bonusWriteOff" in value;
    isInstance = isInstance && "promoCode" in value;
    isInstance = isInstance && "absoluteDiscount" in value;
    isInstance = isInstance && "totalOrderPrice" in value;
    isInstance = isInstance && "totalOrderUserPrice" in value;
    isInstance = isInstance && "totalOrderPercentDiscount" in value;
    isInstance = isInstance && "totalOrderDiscount" in value;

    return isInstance;
}

export function CartFromJSON(json: any): Cart {
    return CartFromJSONTyped(json, false);
}

export function CartFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filial': json['filial'],
        'filialId': json['filial_id'],
        'orderId': json['order_id'],
        'createOrder': json['create_order'],
        'isChanged': json['is_changed'],
        'bonusUsed': json['bonus_used'],
        'hasAutoOrder': json['has_auto_order'],
        'totalProductCount': json['total_product_count'],
        'orderItems': ((json['order_items'] as Array<any>).map(OrderItemFromJSON)),
        'bonusAccrual': json['bonus_accrual'],
        'bonusWriteOff': json['bonus_write_off'],
        'promoCode': PromoCodeFromJSON(json['promo_code']),
        'absoluteDiscount': json['absolute_discount'],
        'totalOrderPrice': json['total_order_price'],
        'totalOrderUserPrice': json['total_order_user_price'],
        'totalOrderPercentDiscount': json['total_order_percent_discount'],
        'totalOrderDiscount': json['total_order_discount'],
    };
}

export function CartToJSON(value?: Cart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filial': value.filial,
        'filial_id': value.filialId,
        'order_id': value.orderId,
        'create_order': value.createOrder,
        'is_changed': value.isChanged,
        'bonus_used': value.bonusUsed,
        'has_auto_order': value.hasAutoOrder,
        'order_items': ((value.orderItems as Array<any>).map(OrderItemToJSON)),
        'bonus_accrual': value.bonusAccrual,
        'bonus_write_off': value.bonusWriteOff,
        'absolute_discount': value.absoluteDiscount,
    };
}

