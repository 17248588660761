/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChildProductCharacteristic } from './ChildProductCharacteristic';
import {
    ChildProductCharacteristicFromJSON,
    ChildProductCharacteristicFromJSONTyped,
    ChildProductCharacteristicToJSON,
} from './ChildProductCharacteristic';

/**
 * Сериализатор для характеристик продуктов
 * @export
 * @interface ProductCharacteristic
 */
export interface ProductCharacteristic {
    /**
     * 
     * @type {number}
     * @memberof ProductCharacteristic
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCharacteristic
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    tegTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<ChildProductCharacteristic>}
     * @memberof ProductCharacteristic
     */
    readonly children: Array<ChildProductCharacteristic> | null;
}

/**
 * Check if a given object implements the ProductCharacteristic interface.
 */
export function instanceOfProductCharacteristic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "children" in value;

    return isInstance;
}

export function ProductCharacteristicFromJSON(json: any): ProductCharacteristic {
    return ProductCharacteristicFromJSONTyped(json, false);
}

export function ProductCharacteristicFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCharacteristic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'tegTitle': !exists(json, 'teg_title') ? undefined : json['teg_title'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'children': (json['children'] === null ? null : (json['children'] as Array<any>).map(ChildProductCharacteristicFromJSON)),
    };
}

export function ProductCharacteristicToJSON(value?: ProductCharacteristic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'position': value.position,
        'teg_title': value.tegTitle,
        'slug': value.slug,
    };
}

