/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Filial
 */
export interface Filial {
    /**
     * 
     * @type {number}
     * @memberof Filial
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Filial
     */
    integrationId: string;
    /**
     * 
     * @type {string}
     * @memberof Filial
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filial
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filial
     */
    url?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Filial
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Filial
     */
    company?: number | null;
}

/**
 * Check if a given object implements the Filial interface.
 */
export function instanceOfFilial(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "integrationId" in value;

    return isInstance;
}

export function FilialFromJSON(json: any): Filial {
    return FilialFromJSONTyped(json, false);
}

export function FilialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Filial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'integrationId': json['integration_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'company': !exists(json, 'company') ? undefined : json['company'],
    };
}

export function FilialToJSON(value?: Filial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integration_id': value.integrationId,
        'name': value.name,
        'address': value.address,
        'url': value.url,
        'active': value.active,
        'company': value.company,
    };
}

