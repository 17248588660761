/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeMailRequest
 */
export interface ChangeMailRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeMailRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeMailRequest
     */
    emailVerificationId?: string | null;
}

/**
 * Check if a given object implements the ChangeMailRequest interface.
 */
export function instanceOfChangeMailRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ChangeMailRequestFromJSON(json: any): ChangeMailRequest {
    return ChangeMailRequestFromJSONTyped(json, false);
}

export function ChangeMailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeMailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'emailVerificationId': !exists(json, 'email_verification_id') ? undefined : json['email_verification_id'],
    };
}

export function ChangeMailRequestToJSON(value?: ChangeMailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'email_verification_id': value.emailVerificationId,
    };
}

