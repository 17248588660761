export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error /* , context */) => {
    // eslint-disable-next-line no-console
    console.error('Error: ', error)
  }
  nuxtApp.hook('app:error', (error) => {
    // eslint-disable-next-line no-console
    console.error('App error: ', error)
  })
  nuxtApp.hook('apollo:error', (error) => {
    // eslint-disable-next-line no-console
    console.error('Apollo Error: ', JSON.stringify(error, null, 2))
  })
})
