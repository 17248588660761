export default function useAccountsMeRetrieve() {
  const { accessToken, refreshToken } = useAuth()
  const { AuthApi, AccountApi } = useOpenApi()

  async function authCasCreate() {
    if (accessToken.value && refreshToken.value) {
      try {
        await AuthApi.authCasCreate({
          casAuthRequest: {
            access: accessToken.value,
            refresh: refreshToken.value,
          },
        })
      } catch (error) {} // eslint-disable-line no-empty
    }
  }

  async function accountsMeRetrieve() {
    await authCasCreate()
    const response = await AccountApi.accountsMeRetrieve()
    return response
  }

  return {
    authCasCreate,
    accountsMeRetrieve,
  }
}
