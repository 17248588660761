/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageS3 } from './ImageS3';
import {
    ImageS3FromJSON,
    ImageS3FromJSONTyped,
    ImageS3ToJSON,
} from './ImageS3';

/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {boolean}
     * @memberof ProductImage
     */
    main?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    url: string;
    /**
     * 
     * @type {Array<ImageS3>}
     * @memberof ProductImage
     */
    readonly imageS3: Array<ImageS3>;
}

/**
 * Check if a given object implements the ProductImage interface.
 */
export function instanceOfProductImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "imageS3" in value;

    return isInstance;
}

export function ProductImageFromJSON(json: any): ProductImage {
    return ProductImageFromJSONTyped(json, false);
}

export function ProductImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'main': !exists(json, 'main') ? undefined : json['main'],
        'url': json['url'],
        'imageS3': ((json['image_s3'] as Array<any>).map(ImageS3FromJSON)),
    };
}

export function ProductImageToJSON(value?: ProductImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'main': value.main,
        'url': value.url,
    };
}

