/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilialProdict
 */
export interface FilialProdict {
    /**
     * 
     * @type {string}
     * @memberof FilialProdict
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof FilialProdict
     */
    regionName: string;
    /**
     * 
     * @type {string}
     * @memberof FilialProdict
     */
    filial: string;
}

/**
 * Check if a given object implements the FilialProdict interface.
 */
export function instanceOfFilialProdict(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "regionName" in value;
    isInstance = isInstance && "filial" in value;

    return isInstance;
}

export function FilialProdictFromJSON(json: any): FilialProdict {
    return FilialProdictFromJSONTyped(json, false);
}

export function FilialProdictFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilialProdict {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['region'],
        'regionName': json['region_name'],
        'filial': json['filial'],
    };
}

export function FilialProdictToJSON(value?: FilialProdict | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'region_name': value.regionName,
        'filial': value.filial,
    };
}

