/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Children } from './Children';
import {
    ChildrenFromJSON,
    ChildrenFromJSONTyped,
    ChildrenToJSON,
} from './Children';

/**
 * 
 * @export
 * @interface CategoryChildren
 */
export interface CategoryChildren {
    /**
     * 
     * @type {string}
     * @memberof CategoryChildren
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryChildren
     */
    slug?: string | null;
    /**
     * 
     * @type {Array<Children>}
     * @memberof CategoryChildren
     */
    readonly children: Array<Children> | null;
}

/**
 * Check if a given object implements the CategoryChildren interface.
 */
export function instanceOfCategoryChildren(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "children" in value;

    return isInstance;
}

export function CategoryChildrenFromJSON(json: any): CategoryChildren {
    return CategoryChildrenFromJSONTyped(json, false);
}

export function CategoryChildrenFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryChildren {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'children': (json['children'] === null ? null : (json['children'] as Array<any>).map(ChildrenFromJSON)),
    };
}

export function CategoryChildrenToJSON(value?: CategoryChildren | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'slug': value.slug,
    };
}

