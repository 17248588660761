/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegionFilialPostRequest
 */
export interface RegionFilialPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RegionFilialPostRequest
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionFilialPostRequest
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionFilialPostRequest
     */
    templates?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionFilialPostRequest
     */
    allOrders?: string;
}

/**
 * Check if a given object implements the RegionFilialPostRequest interface.
 */
export function instanceOfRegionFilialPostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegionFilialPostRequestFromJSON(json: any): RegionFilialPostRequest {
    return RegionFilialPostRequestFromJSONTyped(json, false);
}

export function RegionFilialPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionFilialPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': !exists(json, 'region') ? undefined : json['region'],
        'orderId': !exists(json, 'order_id') ? undefined : json['order_id'],
        'templates': !exists(json, 'templates') ? undefined : json['templates'],
        'allOrders': !exists(json, 'all_orders') ? undefined : json['all_orders'],
    };
}

export function RegionFilialPostRequestToJSON(value?: RegionFilialPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'order_id': value.orderId,
        'templates': value.templates,
        'all_orders': value.allOrders,
    };
}

