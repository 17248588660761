<template>
  <section class="bg-btn-secondary w-full py-2.5 lg:hidden">
    <div class="container">
      <button
        class="text-primary flex w-full items-center text-xs transition lg:w-[120px]"
        @click="emit('showDialog')"
      >
        <Icon
          name="marker-pin"
          class="text-primary mr-2 h-[18px] w-[18px] shrink-0"
        />

        <span class="truncate">
          <span
            v-if="addressText"
            class="font-semibold"
            :title="addressFullText"
            >{{ addressText }}</span
          >

          <span v-else>Укажите адрес доставки</span>
        </span>

        <Icon name="expand-down" class="ml-1 h-[14px] w-[14px] shrink-0" />
      </button>
    </div>
  </section>
</template>

<script setup lang="ts">
defineProps<{
  addressText?: string
  addressFullText?: string
}>()

const emit = defineEmits<{
  (e: 'showDialog'): void
}>()
</script>
