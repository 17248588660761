import routerOptions0 from "/app/packages/shared/app/router.options.ts";
import routerOptions1 from "/app/node_modules/.pnpm/nuxt@3.9.3_@types+node@20.11.5_eslint@8.56.0_rollup@4.21.1_typescript@5.3.3_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions1,
...routerOptions0,
}