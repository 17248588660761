/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `F` - Женский
 * * `M` - Мужской
 * @export
 */
export const GenderEnum = {
    F: 'F',
    M: 'M'
} as const;
export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum];


export function GenderEnumFromJSON(json: any): GenderEnum {
    return GenderEnumFromJSONTyped(json, false);
}

export function GenderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenderEnum {
    return json as GenderEnum;
}

export function GenderEnumToJSON(value?: GenderEnum | null): any {
    return value as any;
}

