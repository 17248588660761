/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestDeliveryAddress,
  BadRequestPhoneNumber,
  BadRequestUser,
  BadRequestUserImage,
  ChangeMail,
  ChangeMailRequest,
  DeliveryAddress,
  DeliveryAddressRequest,
  FioRequest,
  FioSuggestions,
  ImageDelete,
  MergeUsers,
  MergeUsersRequest,
  PageNotFound,
  PatchedDeliveryAddressRequest,
  PatchedPetRequest,
  PatchedPhoneNumberRequest,
  Pet,
  PetRequest,
  PhoneNumber,
  PhoneNumberRequest,
  RegionStatus,
  Session,
  SpecialNeed,
  Status,
  User,
  UserImage,
  UserRequest,
} from '../models';
import {
    BadRequestDeliveryAddressFromJSON,
    BadRequestDeliveryAddressToJSON,
    BadRequestPhoneNumberFromJSON,
    BadRequestPhoneNumberToJSON,
    BadRequestUserFromJSON,
    BadRequestUserToJSON,
    BadRequestUserImageFromJSON,
    BadRequestUserImageToJSON,
    ChangeMailFromJSON,
    ChangeMailToJSON,
    ChangeMailRequestFromJSON,
    ChangeMailRequestToJSON,
    DeliveryAddressFromJSON,
    DeliveryAddressToJSON,
    DeliveryAddressRequestFromJSON,
    DeliveryAddressRequestToJSON,
    FioRequestFromJSON,
    FioRequestToJSON,
    FioSuggestionsFromJSON,
    FioSuggestionsToJSON,
    ImageDeleteFromJSON,
    ImageDeleteToJSON,
    MergeUsersFromJSON,
    MergeUsersToJSON,
    MergeUsersRequestFromJSON,
    MergeUsersRequestToJSON,
    PageNotFoundFromJSON,
    PageNotFoundToJSON,
    PatchedDeliveryAddressRequestFromJSON,
    PatchedDeliveryAddressRequestToJSON,
    PatchedPetRequestFromJSON,
    PatchedPetRequestToJSON,
    PatchedPhoneNumberRequestFromJSON,
    PatchedPhoneNumberRequestToJSON,
    PetFromJSON,
    PetToJSON,
    PetRequestFromJSON,
    PetRequestToJSON,
    PhoneNumberFromJSON,
    PhoneNumberToJSON,
    PhoneNumberRequestFromJSON,
    PhoneNumberRequestToJSON,
    RegionStatusFromJSON,
    RegionStatusToJSON,
    SessionFromJSON,
    SessionToJSON,
    SpecialNeedFromJSON,
    SpecialNeedToJSON,
    StatusFromJSON,
    StatusToJSON,
    UserFromJSON,
    UserToJSON,
    UserImageFromJSON,
    UserImageToJSON,
    UserRequestFromJSON,
    UserRequestToJSON,
} from '../models';

export interface AccountsChangeEmailUpdateRequest {
    changeMailRequest: ChangeMailRequest;
}

export interface AccountsFioSuggestionsCreateRequest {
    fioRequest: FioRequest;
}

export interface AccountsMeDeliveryAddressCreateRequest {
    deliveryAddressRequest: DeliveryAddressRequest;
}

export interface AccountsMeDeliveryAddressDestroyRequest {
    id: number;
}

export interface AccountsMeDeliveryAddressPartialUpdateRequest {
    id: number;
    patchedDeliveryAddressRequest?: PatchedDeliveryAddressRequest;
}

export interface AccountsMeDeliveryAddressRetrieveRequest {
    id: number;
}

export interface AccountsMeDeliveryAddressUpdateRequest {
    id: number;
    deliveryAddressRequest: DeliveryAddressRequest;
}

export interface AccountsMeImageCreateRequest {
    image: Blob;
}

export interface AccountsMePetsCreateRequest {
    petRequest: PetRequest;
}

export interface AccountsMePetsDestroyRequest {
    id: number;
}

export interface AccountsMePetsPartialUpdateRequest {
    id: number;
    patchedPetRequest?: PatchedPetRequest;
}

export interface AccountsMePetsRetrieveRequest {
    id: number;
}

export interface AccountsMePetsUpdateRequest {
    id: number;
    petRequest: PetRequest;
}

export interface AccountsMePhonenumbersCreateRequest {
    phoneNumberRequest: PhoneNumberRequest;
}

export interface AccountsMePhonenumbersDestroyRequest {
    id: number;
}

export interface AccountsMePhonenumbersPartialUpdateRequest {
    id: number;
    patchedPhoneNumberRequest?: PatchedPhoneNumberRequest;
}

export interface AccountsMePhonenumbersRetrieveRequest {
    id: number;
}

export interface AccountsMePhonenumbersUpdateRequest {
    id: number;
    phoneNumberRequest: PhoneNumberRequest;
}

export interface AccountsMeRegionCreateRequest {
    hTTPXFORWARDEDFOR?: string;
    rEMOTEADDR?: string;
}

export interface AccountsMeUpdateRequest {
    userRequest: UserRequest;
}

export interface AccountsMergeCreateRequest {
    mergeUsersRequest: MergeUsersRequest;
}

/**
 * 
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     * Метод для работы с пользователями
     */
    async accountsChangeEmailUpdateRaw(requestParameters: AccountsChangeEmailUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeMail>> {
        if (requestParameters.changeMailRequest === null || requestParameters.changeMailRequest === undefined) {
            throw new runtime.RequiredError('changeMailRequest','Required parameter requestParameters.changeMailRequest was null or undefined when calling accountsChangeEmailUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/change-email/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeMailRequestToJSON(requestParameters.changeMailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeMailFromJSON(jsonValue));
    }

    /**
     * Метод для работы с пользователями
     */
    async accountsChangeEmailUpdate(requestParameters: AccountsChangeEmailUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeMail> {
          try{
        const response = await this.accountsChangeEmailUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Метод для работы с пользователями
     * Запрос на отправку фио в дадату.
     */
    async accountsFioSuggestionsCreateRaw(requestParameters: AccountsFioSuggestionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FioSuggestions>>> {
        if (requestParameters.fioRequest === null || requestParameters.fioRequest === undefined) {
            throw new runtime.RequiredError('fioRequest','Required parameter requestParameters.fioRequest was null or undefined when calling accountsFioSuggestionsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/fio-suggestions/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FioRequestToJSON(requestParameters.fioRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FioSuggestionsFromJSON));
    }

    /**
     * Метод для работы с пользователями
     * Запрос на отправку фио в дадату.
     */
    async accountsFioSuggestionsCreate(requestParameters: AccountsFioSuggestionsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FioSuggestions>> {
          try{
        const response = await this.accountsFioSuggestionsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на создание сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressCreateRaw(requestParameters: AccountsMeDeliveryAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryAddress>> {
        if (requestParameters.deliveryAddressRequest === null || requestParameters.deliveryAddressRequest === undefined) {
            throw new runtime.RequiredError('deliveryAddressRequest','Required parameter requestParameters.deliveryAddressRequest was null or undefined when calling accountsMeDeliveryAddressCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/delivery_address/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliveryAddressRequestToJSON(requestParameters.deliveryAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryAddressFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressCreate(requestParameters: AccountsMeDeliveryAddressCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryAddress> {
          try{
        const response = await this.accountsMeDeliveryAddressCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на удаление сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressDestroyRaw(requestParameters: AccountsMeDeliveryAddressDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMeDeliveryAddressDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/delivery_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressDestroy(requestParameters: AccountsMeDeliveryAddressDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountsMeDeliveryAddressDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Запрос на получение списка сущностей (адрес доставки).
     */
    async accountsMeDeliveryAddressListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliveryAddress>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/delivery_address/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliveryAddressFromJSON));
    }

    /**
     * Запрос на получение списка сущностей (адрес доставки).
     */
    async accountsMeDeliveryAddressList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliveryAddress>> {
          try{
        const response = await this.accountsMeDeliveryAddressListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление полей сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressPartialUpdateRaw(requestParameters: AccountsMeDeliveryAddressPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMeDeliveryAddressPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/delivery_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedDeliveryAddressRequestToJSON(requestParameters.patchedDeliveryAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryAddressFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление полей сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressPartialUpdate(requestParameters: AccountsMeDeliveryAddressPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryAddress> {
          try{
        const response = await this.accountsMeDeliveryAddressPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на получение экземпляра сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressRetrieveRaw(requestParameters: AccountsMeDeliveryAddressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMeDeliveryAddressRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/delivery_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryAddressFromJSON(jsonValue));
    }

    /**
     * Запрос на получение экземпляра сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressRetrieve(requestParameters: AccountsMeDeliveryAddressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryAddress> {
          try{
        const response = await this.accountsMeDeliveryAddressRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на обновление сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressUpdateRaw(requestParameters: AccountsMeDeliveryAddressUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliveryAddress>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMeDeliveryAddressUpdate.');
        }

        if (requestParameters.deliveryAddressRequest === null || requestParameters.deliveryAddressRequest === undefined) {
            throw new runtime.RequiredError('deliveryAddressRequest','Required parameter requestParameters.deliveryAddressRequest was null or undefined when calling accountsMeDeliveryAddressUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/delivery_address/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeliveryAddressRequestToJSON(requestParameters.deliveryAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryAddressFromJSON(jsonValue));
    }

    /**
     * Запрос на обновление сущности (адрес доставки).
     */
    async accountsMeDeliveryAddressUpdate(requestParameters: AccountsMeDeliveryAddressUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliveryAddress> {
          try{
        const response = await this.accountsMeDeliveryAddressUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Метод для работы с пользователями
     * Запрос на добавление изображения пользователю.
     */
    async accountsMeImageCreateRaw(requestParameters: AccountsMeImageCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserImage>> {
        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling accountsMeImageCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/data/accounts/me/image/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserImageFromJSON(jsonValue));
    }

    /**
     * Метод для работы с пользователями
     * Запрос на добавление изображения пользователю.
     */
    async accountsMeImageCreate(requestParameters: AccountsMeImageCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserImage> {
          try{
        const response = await this.accountsMeImageCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Метод для работы с пользователями
     * Запрос на удаление изображения пользователя.
     */
    async accountsMeImageDeleteDestroyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/image-delete/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Метод для работы с пользователями
     * Запрос на удаление изображения пользователя.
     */
    async accountsMeImageDeleteDestroy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountsMeImageDeleteDestroyRaw(initOverrides);
    }

    /**
     */
    async accountsMePetsCreateRaw(requestParameters: AccountsMePetsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pet>> {
        if (requestParameters.petRequest === null || requestParameters.petRequest === undefined) {
            throw new runtime.RequiredError('petRequest','Required parameter requestParameters.petRequest was null or undefined when calling accountsMePetsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PetRequestToJSON(requestParameters.petRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PetFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePetsCreate(requestParameters: AccountsMePetsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pet> {
          try{
        const response = await this.accountsMePetsCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Этот метод не удаляет сущность в базе, а скрывает её
     * \"Удалить\" сущность
     */
    async accountsMePetsDestroyRaw(requestParameters: AccountsMePetsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePetsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Этот метод не удаляет сущность в базе, а скрывает её
     * \"Удалить\" сущность
     */
    async accountsMePetsDestroy(requestParameters: AccountsMePetsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountsMePetsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async accountsMePetsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Pet>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PetFromJSON));
    }

    /**
     */
    async accountsMePetsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Pet>> {
          try{
        const response = await this.accountsMePetsListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePetsPartialUpdateRaw(requestParameters: AccountsMePetsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePetsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedPetRequestToJSON(requestParameters.patchedPetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PetFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePetsPartialUpdate(requestParameters: AccountsMePetsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pet> {
          try{
        const response = await this.accountsMePetsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePetsRetrieveRaw(requestParameters: AccountsMePetsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePetsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PetFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePetsRetrieve(requestParameters: AccountsMePetsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pet> {
          try{
        const response = await this.accountsMePetsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePetsSpecialNeedsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SpecialNeed>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/special-needs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialNeedFromJSON));
    }

    /**
     */
    async accountsMePetsSpecialNeedsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SpecialNeed>> {
          try{
        const response = await this.accountsMePetsSpecialNeedsListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePetsUpdateRaw(requestParameters: AccountsMePetsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePetsUpdate.');
        }

        if (requestParameters.petRequest === null || requestParameters.petRequest === undefined) {
            throw new runtime.RequiredError('petRequest','Required parameter requestParameters.petRequest was null or undefined when calling accountsMePetsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/pets/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PetRequestToJSON(requestParameters.petRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PetFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePetsUpdate(requestParameters: AccountsMePetsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pet> {
          try{
        const response = await this.accountsMePetsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на создание сущности (номер телефона).
     */
    async accountsMePhonenumbersCreateRaw(requestParameters: AccountsMePhonenumbersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhoneNumber>> {
        if (requestParameters.phoneNumberRequest === null || requestParameters.phoneNumberRequest === undefined) {
            throw new runtime.RequiredError('phoneNumberRequest','Required parameter requestParameters.phoneNumberRequest was null or undefined when calling accountsMePhonenumbersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/phonenumbers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneNumberRequestToJSON(requestParameters.phoneNumberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneNumberFromJSON(jsonValue));
    }

    /**
     * Запрос на создание сущности (номер телефона).
     */
    async accountsMePhonenumbersCreate(requestParameters: AccountsMePhonenumbersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhoneNumber> {
          try{
        const response = await this.accountsMePhonenumbersCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на удаление сущности (номер телефона).
     */
    async accountsMePhonenumbersDestroyRaw(requestParameters: AccountsMePhonenumbersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePhonenumbersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/phonenumbers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запрос на удаление сущности (номер телефона).
     */
    async accountsMePhonenumbersDestroy(requestParameters: AccountsMePhonenumbersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountsMePhonenumbersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async accountsMePhonenumbersListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PhoneNumber>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/phonenumbers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneNumberFromJSON));
    }

    /**
     */
    async accountsMePhonenumbersList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PhoneNumber>> {
          try{
        const response = await this.accountsMePhonenumbersListRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePhonenumbersPartialUpdateRaw(requestParameters: AccountsMePhonenumbersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhoneNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePhonenumbersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/phonenumbers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedPhoneNumberRequestToJSON(requestParameters.patchedPhoneNumberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneNumberFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePhonenumbersPartialUpdate(requestParameters: AccountsMePhonenumbersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhoneNumber> {
          try{
        const response = await this.accountsMePhonenumbersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePhonenumbersRetrieveRaw(requestParameters: AccountsMePhonenumbersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhoneNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePhonenumbersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/phonenumbers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneNumberFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePhonenumbersRetrieve(requestParameters: AccountsMePhonenumbersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhoneNumber> {
          try{
        const response = await this.accountsMePhonenumbersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMePhonenumbersUpdateRaw(requestParameters: AccountsMePhonenumbersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhoneNumber>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountsMePhonenumbersUpdate.');
        }

        if (requestParameters.phoneNumberRequest === null || requestParameters.phoneNumberRequest === undefined) {
            throw new runtime.RequiredError('phoneNumberRequest','Required parameter requestParameters.phoneNumberRequest was null or undefined when calling accountsMePhonenumbersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/phonenumbers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneNumberRequestToJSON(requestParameters.phoneNumberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneNumberFromJSON(jsonValue));
    }

    /**
     */
    async accountsMePhonenumbersUpdate(requestParameters: AccountsMePhonenumbersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhoneNumber> {
          try{
        const response = await this.accountsMePhonenumbersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Запрос на определение региона.
     */
    async accountsMeRegionCreateRaw(requestParameters: AccountsMeRegionCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegionStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.hTTPXFORWARDEDFOR !== undefined && requestParameters.hTTPXFORWARDEDFOR !== null) {
            headerParameters['HTTP_X_FORWARDED_FOR'] = String(requestParameters.hTTPXFORWARDEDFOR);
        }

        if (requestParameters.rEMOTEADDR !== undefined && requestParameters.rEMOTEADDR !== null) {
            headerParameters['REMOTE_ADDR'] = String(requestParameters.rEMOTEADDR);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/region/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegionStatusFromJSON(jsonValue));
    }

    /**
     * Запрос на определение региона.
     */
    async accountsMeRegionCreate(requestParameters: AccountsMeRegionCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegionStatus> {
          try{
        const response = await this.accountsMeRegionCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Если пользователь авторизован то возвращает его данные, иначе возвращает регион и ссесию.
     * Запрос на получение данных текущего пользователя.
     */
    async accountsMeRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Если пользователь авторизован то возвращает его данные, иначе возвращает регион и ссесию.
     * Запрос на получение данных текущего пользователя.
     */
    async accountsMeRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
          try{
        const response = await this.accountsMeRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Если пользователь авторизован то возвращает его данные, иначе возвращает регион и ссесию.
     * Запрос на обновление данных текущего пользователя.
     */
    async accountsMeUpdateRaw(requestParameters: AccountsMeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userRequest === null || requestParameters.userRequest === undefined) {
            throw new runtime.RequiredError('userRequest','Required parameter requestParameters.userRequest was null or undefined when calling accountsMeUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/me/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserRequestToJSON(requestParameters.userRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Если пользователь авторизован то возвращает его данные, иначе возвращает регион и ссесию.
     * Запрос на обновление данных текущего пользователя.
     */
    async accountsMeUpdate(requestParameters: AccountsMeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
          try{
        const response = await this.accountsMeUpdateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     */
    async accountsMergeCreateRaw(requestParameters: AccountsMergeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MergeUsers>> {
        if (requestParameters.mergeUsersRequest === null || requestParameters.mergeUsersRequest === undefined) {
            throw new runtime.RequiredError('mergeUsersRequest','Required parameter requestParameters.mergeUsersRequest was null or undefined when calling accountsMergeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeUsersRequestToJSON(requestParameters.mergeUsersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MergeUsersFromJSON(jsonValue));
    }

    /**
     */
    async accountsMergeCreate(requestParameters: AccountsMergeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MergeUsers> {
          try{
        const response = await this.accountsMergeCreateRaw(requestParameters, initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

    /**
     * Метод для работы с пользователями
     */
    async accountsSessionRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Session>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/data/accounts/session/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionFromJSON(jsonValue));
    }

    /**
     * Метод для работы с пользователями
     */
    async accountsSessionRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Session> {
          try{
        const response = await this.accountsSessionRetrieveRaw(initOverrides);
        return await response.value();
            } catch (e) {
            console.error(e)
            throw e
            }
    }

}
