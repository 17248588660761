import { default as indexPhOQ7NblAtMeta } from "/app/apps/monge/pages/about/index.vue?macro=true";
import { default as _91slug_93kq7G5JAHsgMeta } from "/app/packages/shared/pages/articles/[slug].vue?macro=true";
import { default as indexe0DfkDOzziMeta } from "/app/packages/shared/pages/articles/index.vue?macro=true";
import { default as indexzxNcuLnxHFMeta } from "/app/packages/shared/pages/breeder/index.vue?macro=true";
import { default as indexFQAfWps8ceMeta } from "/app/apps/monge/pages/bwild/index.vue?macro=true";
import { default as indexZkZuMunV4SMeta } from "/app/packages/shared/pages/cart/index.vue?macro=true";
import { default as _91_91characteristic_93_93cQ5fMQzHHaMeta } from "/app/packages/shared/pages/catalog/[[characteristic]].vue?macro=true";
import { default as _91_91characteristic_93_938mZS5AdUoyMeta } from "/app/apps/monge/pages/catalog/bwild/[[characteristic]].vue?macro=true";
import { default as _91_91characteristic_93_93j1TnqSyDrMMeta } from "/app/apps/monge/pages/catalog/daily/[[characteristic]].vue?macro=true";
import { default as _91_91characteristic_93_93Vlm0fH8VT3Meta } from "/app/apps/monge/pages/catalog/giftshop/[[characteristic]].vue?macro=true";
import { default as _91_91characteristic_93_93AzsjwrWrITMeta } from "/app/apps/monge/pages/catalog/speciality/[[characteristic]].vue?macro=true";
import { default as index5BxOKWrrwLMeta } from "/app/packages/shared/pages/checkout/index.vue?macro=true";
import { default as indexZelrK6uQ8pMeta } from "/app/packages/shared/pages/compare/index.vue?macro=true";
import { default as index5jMQOVcV9eMeta } from "/app/packages/shared/pages/contacts/index.vue?macro=true";
import { default as indexJxH4pwJLOmMeta } from "/app/packages/shared/pages/cooperation/index.vue?macro=true";
import { default as indexXOazGkcHjEMeta } from "/app/packages/shared/pages/favorites/index.vue?macro=true";
import { default as indexNoYJEWt5xWMeta } from "/app/apps/monge/pages/gift/index.vue?macro=true";
import { default as index8w8r4REMsaMeta } from "/app/apps/monge/pages/giftshop/index.vue?macro=true";
import { default as indexfdQVhUEITlMeta } from "/app/apps/monge/pages/index.vue?macro=true";
import { default as indexy5IKZ4fsmnMeta } from "/app/apps/monge/pages/insurance/index.vue?macro=true";
import { default as indexxNtGtwmwAWMeta } from "/app/packages/shared/pages/login/index.vue?macro=true";
import { default as indexWVNOrseHPWMeta } from "/app/packages/shared/pages/loyalty/index.vue?macro=true";
import { default as indexkMJLkc8ib7Meta } from "/app/packages/shared/pages/map-vetclinics/index.vue?macro=true";
import { default as _91type_93wW49Casd08Meta } from "/app/packages/shared/pages/map/[type].vue?macro=true";
import { default as indexcWTqEM3Dx6Meta } from "/app/packages/shared/pages/map/index.vue?macro=true";
import { default as indexkmO9kQbm0tMeta } from "/app/apps/monge/pages/monoprotein/index.vue?macro=true";
import { default as mrkranchSQ1ClM4bf0Meta } from "/app/apps/monge/pages/mrkranch.vue?macro=true";
import { default as indexsaIBqPZ8z2Meta } from "/app/packages/shared/pages/notifications/index.vue?macro=true";
import { default as _91id_930nL7iI52drMeta } from "/app/packages/shared/pages/order/failure/[id].vue?macro=true";
import { default as _91id_93KAHUMZBcljMeta } from "/app/packages/shared/pages/order/success/[id].vue?macro=true";
import { default as _91id_93mMi6K6inCWMeta } from "/app/packages/shared/pages/orders/[id].vue?macro=true";
import { default as indexwtGd9CMJbxMeta } from "/app/packages/shared/pages/orders/autoorders/index.vue?macro=true";
import { default as indexavp2bQEX88Meta } from "/app/packages/shared/pages/orders/index.vue?macro=true";
import { default as editbeJMu8I6dZMeta } from "/app/packages/shared/pages/orders/templates/[id]/edit.vue?macro=true";
import { default as indexCXWmcg01ZGMeta } from "/app/packages/shared/pages/orders/templates/[id]/index.vue?macro=true";
import { default as indexI9T9RteveYMeta } from "/app/packages/shared/pages/password-reset/index.vue?macro=true";
import { default as indexcQzAKxw9XYMeta } from "/app/packages/shared/pages/password-restore/index.vue?macro=true";
import { default as _91id_93KawwNGPzSGMeta } from "/app/packages/shared/pages/product/[id].vue?macro=true";
import { default as index9VzbQdJP1MMeta } from "/app/packages/shared/pages/profile/edit/index.vue?macro=true";
import { default as indexKl1ZCET1ZrMeta } from "/app/packages/shared/pages/profile/index.vue?macro=true";
import { default as _91id_93fQcuKRhMs3Meta } from "/app/packages/shared/pages/profile/pets/[id].vue?macro=true";
import { default as indexZmtb5tgICTMeta } from "/app/packages/shared/pages/profile/pets/create/index.vue?macro=true";
import { default as result3RuHbZwIsmMeta } from "/app/packages/shared/pages/profile/pets/create/result.vue?macro=true";
import { default as indexo1JUm8I36cMeta } from "/app/packages/shared/pages/profile/pets/index.vue?macro=true";
import { default as cookieWfZbBgpdv4Meta } from "/app/packages/shared/pages/rules/cookie.vue?macro=true";
import { default as policyVoBPIbsNxDMeta } from "/app/packages/shared/pages/rules/policy.vue?macro=true";
import { default as recomendationZq345NoMFeMeta } from "/app/packages/shared/pages/rules/recomendation.vue?macro=true";
import { default as indexNtVTfXJMIEMeta } from "/app/packages/shared/pages/signup/index.vue?macro=true";
export default [
  {
    name: indexPhOQ7NblAtMeta?.name ?? "about",
    path: indexPhOQ7NblAtMeta?.path ?? "/about",
    meta: indexPhOQ7NblAtMeta || {},
    alias: indexPhOQ7NblAtMeta?.alias || [],
    redirect: indexPhOQ7NblAtMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kq7G5JAHsgMeta?.name ?? "articles-slug",
    path: _91slug_93kq7G5JAHsgMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93kq7G5JAHsgMeta || {},
    alias: _91slug_93kq7G5JAHsgMeta?.alias || [],
    redirect: _91slug_93kq7G5JAHsgMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexe0DfkDOzziMeta?.name ?? "articles",
    path: indexe0DfkDOzziMeta?.path ?? "/articles",
    meta: indexe0DfkDOzziMeta || {},
    alias: indexe0DfkDOzziMeta?.alias || [],
    redirect: indexe0DfkDOzziMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexzxNcuLnxHFMeta?.name ?? "breeder",
    path: indexzxNcuLnxHFMeta?.path ?? "/breeder",
    meta: indexzxNcuLnxHFMeta || {},
    alias: indexzxNcuLnxHFMeta?.alias || [],
    redirect: indexzxNcuLnxHFMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/breeder/index.vue").then(m => m.default || m)
  },
  {
    name: indexFQAfWps8ceMeta?.name ?? "bwild",
    path: indexFQAfWps8ceMeta?.path ?? "/bwild",
    meta: indexFQAfWps8ceMeta || {},
    alias: indexFQAfWps8ceMeta?.alias || [],
    redirect: indexFQAfWps8ceMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/bwild/index.vue").then(m => m.default || m)
  },
  {
    name: indexZkZuMunV4SMeta?.name ?? "cart",
    path: indexZkZuMunV4SMeta?.path ?? "/cart",
    meta: indexZkZuMunV4SMeta || {},
    alias: indexZkZuMunV4SMeta?.alias || [],
    redirect: indexZkZuMunV4SMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91characteristic_93_93cQ5fMQzHHaMeta?.name ?? "catalog-characteristic",
    path: _91_91characteristic_93_93cQ5fMQzHHaMeta?.path ?? "/catalog/:characteristic?",
    meta: _91_91characteristic_93_93cQ5fMQzHHaMeta || {},
    alias: _91_91characteristic_93_93cQ5fMQzHHaMeta?.alias || [],
    redirect: _91_91characteristic_93_93cQ5fMQzHHaMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/catalog/[[characteristic]].vue").then(m => m.default || m)
  },
  {
    name: _91_91characteristic_93_938mZS5AdUoyMeta?.name ?? "catalog-bwild-characteristic",
    path: _91_91characteristic_93_938mZS5AdUoyMeta?.path ?? "/catalog/bwild/:characteristic?",
    meta: _91_91characteristic_93_938mZS5AdUoyMeta || {},
    alias: _91_91characteristic_93_938mZS5AdUoyMeta?.alias || [],
    redirect: _91_91characteristic_93_938mZS5AdUoyMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/catalog/bwild/[[characteristic]].vue").then(m => m.default || m)
  },
  {
    name: _91_91characteristic_93_93j1TnqSyDrMMeta?.name ?? "catalog-daily-characteristic",
    path: _91_91characteristic_93_93j1TnqSyDrMMeta?.path ?? "/catalog/daily/:characteristic?",
    meta: _91_91characteristic_93_93j1TnqSyDrMMeta || {},
    alias: _91_91characteristic_93_93j1TnqSyDrMMeta?.alias || [],
    redirect: _91_91characteristic_93_93j1TnqSyDrMMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/catalog/daily/[[characteristic]].vue").then(m => m.default || m)
  },
  {
    name: _91_91characteristic_93_93Vlm0fH8VT3Meta?.name ?? "catalog-giftshop-characteristic",
    path: _91_91characteristic_93_93Vlm0fH8VT3Meta?.path ?? "/catalog/giftshop/:characteristic?",
    meta: _91_91characteristic_93_93Vlm0fH8VT3Meta || {},
    alias: _91_91characteristic_93_93Vlm0fH8VT3Meta?.alias || [],
    redirect: _91_91characteristic_93_93Vlm0fH8VT3Meta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/catalog/giftshop/[[characteristic]].vue").then(m => m.default || m)
  },
  {
    name: _91_91characteristic_93_93AzsjwrWrITMeta?.name ?? "catalog-speciality-characteristic",
    path: _91_91characteristic_93_93AzsjwrWrITMeta?.path ?? "/catalog/speciality/:characteristic?",
    meta: _91_91characteristic_93_93AzsjwrWrITMeta || {},
    alias: _91_91characteristic_93_93AzsjwrWrITMeta?.alias || [],
    redirect: _91_91characteristic_93_93AzsjwrWrITMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/catalog/speciality/[[characteristic]].vue").then(m => m.default || m)
  },
  {
    name: index5BxOKWrrwLMeta?.name ?? "checkout",
    path: index5BxOKWrrwLMeta?.path ?? "/checkout",
    meta: index5BxOKWrrwLMeta || {},
    alias: index5BxOKWrrwLMeta?.alias || [],
    redirect: index5BxOKWrrwLMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexZelrK6uQ8pMeta?.name ?? "compare",
    path: indexZelrK6uQ8pMeta?.path ?? "/compare",
    meta: indexZelrK6uQ8pMeta || {},
    alias: indexZelrK6uQ8pMeta?.alias || [],
    redirect: indexZelrK6uQ8pMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/compare/index.vue").then(m => m.default || m)
  },
  {
    name: index5jMQOVcV9eMeta?.name ?? "contacts",
    path: index5jMQOVcV9eMeta?.path ?? "/contacts",
    meta: index5jMQOVcV9eMeta || {},
    alias: index5jMQOVcV9eMeta?.alias || [],
    redirect: index5jMQOVcV9eMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexJxH4pwJLOmMeta?.name ?? "cooperation",
    path: indexJxH4pwJLOmMeta?.path ?? "/cooperation",
    meta: indexJxH4pwJLOmMeta || {},
    alias: indexJxH4pwJLOmMeta?.alias || [],
    redirect: indexJxH4pwJLOmMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/cooperation/index.vue").then(m => m.default || m)
  },
  {
    name: indexXOazGkcHjEMeta?.name ?? "favorites",
    path: indexXOazGkcHjEMeta?.path ?? "/favorites",
    meta: indexXOazGkcHjEMeta || {},
    alias: indexXOazGkcHjEMeta?.alias || [],
    redirect: indexXOazGkcHjEMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexNoYJEWt5xWMeta?.name ?? "gift",
    path: indexNoYJEWt5xWMeta?.path ?? "/gift",
    meta: indexNoYJEWt5xWMeta || {},
    alias: indexNoYJEWt5xWMeta?.alias || [],
    redirect: indexNoYJEWt5xWMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/gift/index.vue").then(m => m.default || m)
  },
  {
    name: index8w8r4REMsaMeta?.name ?? "giftshop",
    path: index8w8r4REMsaMeta?.path ?? "/giftshop",
    meta: index8w8r4REMsaMeta || {},
    alias: index8w8r4REMsaMeta?.alias || [],
    redirect: index8w8r4REMsaMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/giftshop/index.vue").then(m => m.default || m)
  },
  {
    name: indexfdQVhUEITlMeta?.name ?? "index",
    path: indexfdQVhUEITlMeta?.path ?? "/",
    meta: indexfdQVhUEITlMeta || {},
    alias: indexfdQVhUEITlMeta?.alias || [],
    redirect: indexfdQVhUEITlMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexy5IKZ4fsmnMeta?.name ?? "insurance",
    path: indexy5IKZ4fsmnMeta?.path ?? "/insurance",
    meta: indexy5IKZ4fsmnMeta || {},
    alias: indexy5IKZ4fsmnMeta?.alias || [],
    redirect: indexy5IKZ4fsmnMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexxNtGtwmwAWMeta?.name ?? "login",
    path: indexxNtGtwmwAWMeta?.path ?? "/login",
    meta: indexxNtGtwmwAWMeta || {},
    alias: indexxNtGtwmwAWMeta?.alias || [],
    redirect: indexxNtGtwmwAWMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexWVNOrseHPWMeta?.name ?? "loyalty",
    path: indexWVNOrseHPWMeta?.path ?? "/loyalty",
    meta: indexWVNOrseHPWMeta || {},
    alias: indexWVNOrseHPWMeta?.alias || [],
    redirect: indexWVNOrseHPWMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/loyalty/index.vue").then(m => m.default || m)
  },
  {
    name: indexkMJLkc8ib7Meta?.name ?? "map-vetclinics",
    path: indexkMJLkc8ib7Meta?.path ?? "/map-vetclinics",
    meta: indexkMJLkc8ib7Meta || {},
    alias: indexkMJLkc8ib7Meta?.alias || [],
    redirect: indexkMJLkc8ib7Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/map-vetclinics/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93wW49Casd08Meta?.name ?? "map-type",
    path: _91type_93wW49Casd08Meta?.path ?? "/map/:type()",
    meta: _91type_93wW49Casd08Meta || {},
    alias: _91type_93wW49Casd08Meta?.alias || [],
    redirect: _91type_93wW49Casd08Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/map/[type].vue").then(m => m.default || m)
  },
  {
    name: indexcWTqEM3Dx6Meta?.name ?? "map",
    path: indexcWTqEM3Dx6Meta?.path ?? "/map",
    meta: indexcWTqEM3Dx6Meta || {},
    alias: indexcWTqEM3Dx6Meta?.alias || [],
    redirect: indexcWTqEM3Dx6Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/map/index.vue").then(m => m.default || m)
  },
  {
    name: indexkmO9kQbm0tMeta?.name ?? "monoprotein",
    path: indexkmO9kQbm0tMeta?.path ?? "/monoprotein",
    meta: indexkmO9kQbm0tMeta || {},
    alias: indexkmO9kQbm0tMeta?.alias || [],
    redirect: indexkmO9kQbm0tMeta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/monoprotein/index.vue").then(m => m.default || m)
  },
  {
    name: mrkranchSQ1ClM4bf0Meta?.name ?? "mrkranch",
    path: mrkranchSQ1ClM4bf0Meta?.path ?? "/mrkranch",
    meta: mrkranchSQ1ClM4bf0Meta || {},
    alias: mrkranchSQ1ClM4bf0Meta?.alias || [],
    redirect: mrkranchSQ1ClM4bf0Meta?.redirect || undefined,
    component: () => import("/app/apps/monge/pages/mrkranch.vue").then(m => m.default || m)
  },
  {
    name: indexsaIBqPZ8z2Meta?.name ?? "notifications",
    path: indexsaIBqPZ8z2Meta?.path ?? "/notifications",
    meta: indexsaIBqPZ8z2Meta || {},
    alias: indexsaIBqPZ8z2Meta?.alias || [],
    redirect: indexsaIBqPZ8z2Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930nL7iI52drMeta?.name ?? "order-failure-id",
    path: _91id_930nL7iI52drMeta?.path ?? "/order/failure/:id()",
    meta: _91id_930nL7iI52drMeta || {},
    alias: _91id_930nL7iI52drMeta?.alias || [],
    redirect: _91id_930nL7iI52drMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/order/failure/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KAHUMZBcljMeta?.name ?? "order-success-id",
    path: _91id_93KAHUMZBcljMeta?.path ?? "/order/success/:id()",
    meta: _91id_93KAHUMZBcljMeta || {},
    alias: _91id_93KAHUMZBcljMeta?.alias || [],
    redirect: _91id_93KAHUMZBcljMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/order/success/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93mMi6K6inCWMeta?.name ?? "orders-id",
    path: _91id_93mMi6K6inCWMeta?.path ?? "/orders/:id()",
    meta: _91id_93mMi6K6inCWMeta || {},
    alias: _91id_93mMi6K6inCWMeta?.alias || [],
    redirect: _91id_93mMi6K6inCWMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwtGd9CMJbxMeta?.name ?? "orders-autoorders",
    path: indexwtGd9CMJbxMeta?.path ?? "/orders/autoorders",
    meta: indexwtGd9CMJbxMeta || {},
    alias: indexwtGd9CMJbxMeta?.alias || [],
    redirect: indexwtGd9CMJbxMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/orders/autoorders/index.vue").then(m => m.default || m)
  },
  {
    name: indexavp2bQEX88Meta?.name ?? "orders",
    path: indexavp2bQEX88Meta?.path ?? "/orders",
    meta: indexavp2bQEX88Meta || {},
    alias: indexavp2bQEX88Meta?.alias || [],
    redirect: indexavp2bQEX88Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: editbeJMu8I6dZMeta?.name ?? "orders-templates-id-edit",
    path: editbeJMu8I6dZMeta?.path ?? "/orders/templates/:id()/edit",
    meta: editbeJMu8I6dZMeta || {},
    alias: editbeJMu8I6dZMeta?.alias || [],
    redirect: editbeJMu8I6dZMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/orders/templates/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCXWmcg01ZGMeta?.name ?? "orders-templates-id",
    path: indexCXWmcg01ZGMeta?.path ?? "/orders/templates/:id()",
    meta: indexCXWmcg01ZGMeta || {},
    alias: indexCXWmcg01ZGMeta?.alias || [],
    redirect: indexCXWmcg01ZGMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/orders/templates/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexI9T9RteveYMeta?.name ?? "password-reset",
    path: indexI9T9RteveYMeta?.path ?? "/password-reset",
    meta: indexI9T9RteveYMeta || {},
    alias: indexI9T9RteveYMeta?.alias || [],
    redirect: indexI9T9RteveYMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexcQzAKxw9XYMeta?.name ?? "password-restore",
    path: indexcQzAKxw9XYMeta?.path ?? "/password-restore",
    meta: indexcQzAKxw9XYMeta || {},
    alias: indexcQzAKxw9XYMeta?.alias || [],
    redirect: indexcQzAKxw9XYMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/password-restore/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KawwNGPzSGMeta?.name ?? "product-id",
    path: _91id_93KawwNGPzSGMeta?.path ?? "/product/:id()",
    meta: _91id_93KawwNGPzSGMeta || {},
    alias: _91id_93KawwNGPzSGMeta?.alias || [],
    redirect: _91id_93KawwNGPzSGMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: index9VzbQdJP1MMeta?.name ?? "profile-edit",
    path: index9VzbQdJP1MMeta?.path ?? "/profile/edit",
    meta: index9VzbQdJP1MMeta || {},
    alias: index9VzbQdJP1MMeta?.alias || [],
    redirect: index9VzbQdJP1MMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/profile/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexKl1ZCET1ZrMeta?.name ?? "profile",
    path: indexKl1ZCET1ZrMeta?.path ?? "/profile",
    meta: indexKl1ZCET1ZrMeta || {},
    alias: indexKl1ZCET1ZrMeta?.alias || [],
    redirect: indexKl1ZCET1ZrMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fQcuKRhMs3Meta?.name ?? "profile-pets-id",
    path: _91id_93fQcuKRhMs3Meta?.path ?? "/profile/pets/:id()",
    meta: _91id_93fQcuKRhMs3Meta || {},
    alias: _91id_93fQcuKRhMs3Meta?.alias || [],
    redirect: _91id_93fQcuKRhMs3Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/profile/pets/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZmtb5tgICTMeta?.name ?? "profile-pets-create",
    path: indexZmtb5tgICTMeta?.path ?? "/profile/pets/create",
    meta: indexZmtb5tgICTMeta || {},
    alias: indexZmtb5tgICTMeta?.alias || [],
    redirect: indexZmtb5tgICTMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/profile/pets/create/index.vue").then(m => m.default || m)
  },
  {
    name: result3RuHbZwIsmMeta?.name ?? "profile-pets-create-result",
    path: result3RuHbZwIsmMeta?.path ?? "/profile/pets/create/result",
    meta: result3RuHbZwIsmMeta || {},
    alias: result3RuHbZwIsmMeta?.alias || [],
    redirect: result3RuHbZwIsmMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/profile/pets/create/result.vue").then(m => m.default || m)
  },
  {
    name: indexo1JUm8I36cMeta?.name ?? "profile-pets",
    path: indexo1JUm8I36cMeta?.path ?? "/profile/pets",
    meta: indexo1JUm8I36cMeta || {},
    alias: indexo1JUm8I36cMeta?.alias || [],
    redirect: indexo1JUm8I36cMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/profile/pets/index.vue").then(m => m.default || m)
  },
  {
    name: cookieWfZbBgpdv4Meta?.name ?? "rules-cookie",
    path: cookieWfZbBgpdv4Meta?.path ?? "/rules/cookie",
    meta: cookieWfZbBgpdv4Meta || {},
    alias: cookieWfZbBgpdv4Meta?.alias || [],
    redirect: cookieWfZbBgpdv4Meta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/rules/cookie.vue").then(m => m.default || m)
  },
  {
    name: policyVoBPIbsNxDMeta?.name ?? "rules-policy",
    path: policyVoBPIbsNxDMeta?.path ?? "/rules/policy",
    meta: policyVoBPIbsNxDMeta || {},
    alias: policyVoBPIbsNxDMeta?.alias || [],
    redirect: policyVoBPIbsNxDMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/rules/policy.vue").then(m => m.default || m)
  },
  {
    name: recomendationZq345NoMFeMeta?.name ?? "rules-recomendation",
    path: recomendationZq345NoMFeMeta?.path ?? "/rules/recomendation",
    meta: recomendationZq345NoMFeMeta || {},
    alias: recomendationZq345NoMFeMeta?.alias || [],
    redirect: recomendationZq345NoMFeMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/rules/recomendation.vue").then(m => m.default || m)
  },
  {
    name: indexNtVTfXJMIEMeta?.name ?? "signup",
    path: indexNtVTfXJMIEMeta?.path ?? "/signup",
    meta: indexNtVTfXJMIEMeta || {},
    alias: indexNtVTfXJMIEMeta?.alias || [],
    redirect: indexNtVTfXJMIEMeta?.redirect || undefined,
    component: () => import("/app/packages/shared/pages/signup/index.vue").then(m => m.default || m)
  }
]