/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CasCheckCodeRequest
 */
export interface CasCheckCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof CasCheckCodeRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CasCheckCodeRequest
     */
    code: string;
}

/**
 * Check if a given object implements the CasCheckCodeRequest interface.
 */
export function instanceOfCasCheckCodeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function CasCheckCodeRequestFromJSON(json: any): CasCheckCodeRequest {
    return CasCheckCodeRequestFromJSONTyped(json, false);
}

export function CasCheckCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CasCheckCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'code': json['code'],
    };
}

export function CasCheckCodeRequestToJSON(value?: CasCheckCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'code': value.code,
    };
}

