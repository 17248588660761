/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderItem1c
 */
export interface OrderItem1c {
    /**
     * 
     * @type {string}
     * @memberof OrderItem1c
     */
    readonly productIntegrationId: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem1c
     */
    readonly quantity: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem1c
     */
    readonly price: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem1c
     */
    readonly automaticDiscount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem1c
     */
    readonly totalAmount: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem1c
     */
    readonly manualDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem1c
     */
    readonly manualDiscountSum: number;
}

/**
 * Check if a given object implements the OrderItem1c interface.
 */
export function instanceOfOrderItem1c(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productIntegrationId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "automaticDiscount" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "manualDiscount" in value;
    isInstance = isInstance && "manualDiscountSum" in value;

    return isInstance;
}

export function OrderItem1cFromJSON(json: any): OrderItem1c {
    return OrderItem1cFromJSONTyped(json, false);
}

export function OrderItem1cFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderItem1c {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productIntegrationId': json['ProductIntegrationId'],
        'quantity': json['Quantity'],
        'price': json['Price'],
        'automaticDiscount': json['AutomaticDiscount'],
        'totalAmount': json['TotalAmount'],
        'manualDiscount': json['ManualDiscount'],
        'manualDiscountSum': json['ManualDiscountSum'],
    };
}

export function OrderItem1cToJSON(value?: OrderItem1c | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

