/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewedProducts
 */
export interface ViewedProducts {
    /**
     * 
     * @type {number}
     * @memberof ViewedProducts
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof ViewedProducts
     */
    readonly date: Date;
    /**
     * 
     * @type {number}
     * @memberof ViewedProducts
     */
    userActivity: number;
    /**
     * 
     * @type {number}
     * @memberof ViewedProducts
     */
    site: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ViewedProducts
     */
    products?: Array<number>;
}

/**
 * Check if a given object implements the ViewedProducts interface.
 */
export function instanceOfViewedProducts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "userActivity" in value;
    isInstance = isInstance && "site" in value;

    return isInstance;
}

export function ViewedProductsFromJSON(json: any): ViewedProducts {
    return ViewedProductsFromJSONTyped(json, false);
}

export function ViewedProductsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewedProducts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'userActivity': json['user_activity'],
        'site': json['site'],
        'products': !exists(json, 'products') ? undefined : json['products'],
    };
}

export function ViewedProductsToJSON(value?: ViewedProducts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_activity': value.userActivity,
        'site': value.site,
        'products': value.products,
    };
}

