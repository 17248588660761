/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductCatalog } from './ProductCatalog';
import {
    ProductCatalogFromJSON,
    ProductCatalogFromJSONTyped,
    ProductCatalogToJSON,
} from './ProductCatalog';

/**
 * 
 * @export
 * @interface PaginatedProductCatalogList
 */
export interface PaginatedProductCatalogList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProductCatalogList
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProductCatalogList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProductCatalogList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<ProductCatalog>}
     * @memberof PaginatedProductCatalogList
     */
    results?: Array<ProductCatalog>;
}

/**
 * Check if a given object implements the PaginatedProductCatalogList interface.
 */
export function instanceOfPaginatedProductCatalogList(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaginatedProductCatalogListFromJSON(json: any): PaginatedProductCatalogList {
    return PaginatedProductCatalogListFromJSONTyped(json, false);
}

export function PaginatedProductCatalogListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedProductCatalogList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(ProductCatalogFromJSON)),
    };
}

export function PaginatedProductCatalogListToJSON(value?: PaginatedProductCatalogList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(ProductCatalogToJSON)),
    };
}

