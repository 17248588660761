/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ContactIntegrationId str (50)
 * MobilePhone str (250)
 * @export
 * @interface PhoneNumberTo1c
 */
export interface PhoneNumberTo1c {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberTo1c
     */
    readonly contactIntegrationId: string | null;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberTo1c
     */
    readonly mobilePhone: string;
}

/**
 * Check if a given object implements the PhoneNumberTo1c interface.
 */
export function instanceOfPhoneNumberTo1c(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactIntegrationId" in value;
    isInstance = isInstance && "mobilePhone" in value;

    return isInstance;
}

export function PhoneNumberTo1cFromJSON(json: any): PhoneNumberTo1c {
    return PhoneNumberTo1cFromJSONTyped(json, false);
}

export function PhoneNumberTo1cFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumberTo1c {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactIntegrationId': json['ContactIntegrationId'],
        'mobilePhone': json['MobilePhone'],
    };
}

export function PhoneNumberTo1cToJSON(value?: PhoneNumberTo1c | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

