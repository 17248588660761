<template>
  <div
    :class="{
      'flex h-6 items-center space-x-1.5': true,
      'text-[10px]': small,
      'text-success': status === 'in_stock',
      'text-warning': status === 'half',
      'text-danger': status === 'not_available',
    }"
  >
    <template v-if="status">
      <div v-if="point" class="h-1.5 w-1.5 rounded-full bg-current" />
      <div>{{ te(`product.${status}`) ? t(`product.${status}`) : '' }}</div>
    </template>
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    status?: 'in_stock' | 'half' | 'not_available' | null
    small?: boolean
    point?: boolean
  }>(),
  {
    small: false,
    point: false,
  },
)

const { t, te } = useI18n()
</script>
