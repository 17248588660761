/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ONLINE_SHOP` - Интернет магазины
 * * `OFFLINE_SHOP` - Оффлайн магазины
 * * `VET_CLINIC` - Вет.клиники
 * * `GROOMING_SALON` - Груминг-салон
 * @export
 */
export const StoreTypeEnum = {
    OnlineShop: 'ONLINE_SHOP',
    OfflineShop: 'OFFLINE_SHOP',
    VetClinic: 'VET_CLINIC',
    GroomingSalon: 'GROOMING_SALON'
} as const;
export type StoreTypeEnum = typeof StoreTypeEnum[keyof typeof StoreTypeEnum];


export function StoreTypeEnumFromJSON(json: any): StoreTypeEnum {
    return StoreTypeEnumFromJSONTyped(json, false);
}

export function StoreTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreTypeEnum {
    return json as StoreTypeEnum;
}

export function StoreTypeEnumToJSON(value?: StoreTypeEnum | null): any {
    return value as any;
}

