/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `large` - large
 * * `medium` - medium
 * * `small` - small
 * @export
 */
export const SizeEnum = {
    Large: 'large',
    Medium: 'medium',
    Small: 'small'
} as const;
export type SizeEnum = typeof SizeEnum[keyof typeof SizeEnum];


export function SizeEnumFromJSON(json: any): SizeEnum {
    return SizeEnumFromJSONTyped(json, false);
}

export function SizeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SizeEnum {
    return json as SizeEnum;
}

export function SizeEnumToJSON(value?: SizeEnum | null): any {
    return value as any;
}

