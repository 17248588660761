/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockProductFormRequest
 */
export interface StockProductFormRequest {
    /**
     * 
     * @type {string}
     * @memberof StockProductFormRequest
     */
    formName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockProductFormRequest
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof StockProductFormRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockProductFormRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockProductFormRequest
     */
    product: number;
}

/**
 * Check if a given object implements the StockProductFormRequest interface.
 */
export function instanceOfStockProductFormRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "product" in value;

    return isInstance;
}

export function StockProductFormRequestFromJSON(json: any): StockProductFormRequest {
    return StockProductFormRequestFromJSONTyped(json, false);
}

export function StockProductFormRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockProductFormRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formName': !exists(json, 'form_name') ? undefined : json['form_name'],
        'fullName': json['full_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'product': json['product'],
    };
}

export function StockProductFormRequestToJSON(value?: StockProductFormRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form_name': value.formName,
        'full_name': value.fullName,
        'email': value.email,
        'phone': value.phone,
        'product': value.product,
    };
}

