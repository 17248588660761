<template>
  <div class="item-slider relative">
    <ClientOnly>
      <div
        v-if="!hideHeader"
        class="mb-4 flex items-center justify-between lg:mb-6 lg:min-h-[52px]"
      >
        <slot name="title">
          <h2 class="text-heading-2 text-primary font-semibold leading-[38px]">
            {{ title }}
          </h2>
        </slot>

        <div
          v-if="items && items.length > slidesPerView"
          class="hidden space-x-3 lg:flex"
        >
          <Btn
            lg
            primary
            :class="[
              {
                'bg-[#83BB26] hover:!bg-[#02441E] disabled:!border-[#83BB26] disabled:text-[#83BB26] disabled:!ring-[#83BB26]':
                  color === 'green',
                'bg-[#ED008C] hover:!bg-[#d4027e] disabled:!border-[#ED008C] disabled:text-[#ED008C] disabled:!ring-[#ED008C]':
                  color === 'pink',
              },
            ]"
            class="disabled:text-primary disabled:ring-primary p-3.5 disabled:!bg-transparent disabled:opacity-100 disabled:ring-1 disabled:ring-inset"
            :disabled="!activeIndex"
            @click="swiperInstance?.slidePrev()"
          >
            <Icon name="expand-left" class="h-6 w-6" />
          </Btn>

          <Btn
            lg
            primary
            :class="[
              {
                'bg-[#83BB26] hover:!bg-[#02441E] disabled:!border-[#83BB26] disabled:text-[#83BB26] disabled:!ring-[#83BB26]':
                  color === 'green',
                'bg-[#ED008C] hover:!bg-[#d4027e] disabled:!border-[#ED008C] disabled:text-[#ED008C] disabled:!ring-[#ED008C]':
                  color === 'pink',
              },
            ]"
            class="disabled:text-primary disabled:ring-primary p-3.5 disabled:!bg-transparent disabled:opacity-100 disabled:ring-1 disabled:ring-inset"
            :disabled="activeIndex + slidesPerView === items.length"
            @click="swiperInstance?.slideNext()"
          >
            <Icon name="expand-right" class="h-6 w-6" />
          </Btn>
        </div>
      </div>
    </ClientOnly>

    <slot name="top" />

    <div class="-mx-4 lg:-mx-7 xl:mx-0">
      <Skeleton v-if="isLoading" list class="rounded-[16px] p-6 shadow" />
      <Swiper
        v-else
        :loop="loop"
        :modules="[Autoplay, Navigation]"
        :autoplay="{
          delay: 3000,
          disableOnInteraction: false,
        }"
        navigation
        slides-per-view="auto"
        :space-between="12"
        :breakpoints="{
          300: {
            slidesPerView: slidesPerViewMobile,
            spaceBetween: 14,
          },
          768: {
            slidesPerView: slidesPerView,
            spaceBetween: 28,
          },
        }"
        class="relative !px-4 lg:!px-7 xl:!px-0"
        @slide-change="onSlideChange"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="(item, i) in items" :key="i" :class="slideClass">
          <slot :index="i" :item="item" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps({
  title: String,
  hideHeader: Boolean,
  uniqueClass: {
    type: String,
    required: true,
  },
  slideClass: String,
  items: {
    type: Array,
    default: () => [],
  },
  slidesPerView: {
    type: Number,
    default: 4,
  },
  slidesPerViewMobile: {
    type: Number,
    default: 2.2,
  },
  color: {
    type: String,
    default: 'blue',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
})

const swiperInstance = ref()
const activeIndex = ref()

function onSlideChange(swiper) {
  activeIndex.value = swiper.activeIndex
}

function onSwiper(swiper) {
  swiperInstance.value = swiper
}

defineExpose({
  swiperInstance,
})
</script>

<style scoped>
.swiper-backface-hidden .swiper-slide {
  transform: none !important;
}
</style>
