<template>
  <HeadlessTransitionRoot appear as="template" :show="isOpen">
    <HeadlessDialog
      class="fixed inset-0 z-[9999] w-screen overflow-y-auto"
      :style="zIndex ? { zIndex } : undefined"
      :initial-focus="initialFocus"
      @close="manualClose || close()"
    >
      <div
        :class="[
          'flex items-center justify-center',
          fullscreenOnMobile
            ? 'h-full lg:h-auto lg:min-h-screen'
            : 'min-h-screen px-4',
        ]"
      >
        <HeadlessTransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          leave="duration-200 ease-in"
          leave-to="opacity-0"
        >
          <HeadlessDialogOverlay
            :class="[
              'fixed inset-0 bg-[#777777]/50 backdrop-blur-lg',
              { 'hidden lg:block': fullscreenOnMobile },
            ]"
          />
        </HeadlessTransitionChild>
        <HeadlessTransitionChild
          as="template"
          :enter="
            fullscreenOnMobile
              ? 'duration-300 ease-in lg:ease-out'
              : 'duration-300 ease-out'
          "
          :enter-from="
            fullscreenOnMobile
              ? 'translate-y-full lg:translate-y-0 lg:opacity-0 lg:scale-95'
              : 'opacity-0 scale-95'
          "
          :leave="
            fullscreenOnMobile
              ? 'duration-200 ease-out lg:ease-in'
              : 'duration-200 ease-in'
          "
          :leave-to="
            fullscreenOnMobile
              ? 'translate-y-full lg:translate-y-0 lg:opacity-0 lg:scale-95'
              : 'opacity-0 scale-95'
          "
          @before-enter="emit('before-enter')"
          @after-enter="emit('after-enter')"
          @before-leave="emit('before-leave')"
          @after-leave="emit('after-leave')"
        >
          <HeadlessDialogPanel
            :class="[
              'relative mx-auto w-full bg-white py-6 lg:my-6 lg:px-6 lg:py-8',
              fullscreenOnMobile
                ? 'lg:shadow-small h-full overflow-y-auto lg:max-w-[477px] lg:rounded-2xl'
                : 'shadow-small my-4 max-w-[477px] rounded-2xl',
            ]"
            v-bind="$attrs"
          >
            <div
              v-if="title || closable"
              class="mb-11 flex items-center justify-between gap-x-3"
            >
              <HeadlessDialogTitle
                v-if="title"
                as="div"
                class="text-heading-4 lg:text-heading-3 font-medium"
                :class="titleClass"
              >
                {{ title }}
              </HeadlessDialogTitle>
              <Btn
                v-if="closable"
                class="hover:text-primary-hover"
                tabindex="-1"
                @click="close"
              >
                <Icon name="close" class="h-6 w-6 lg:h-8 lg:w-8" />
              </Btn>
            </div>
            <slot />
          </HeadlessDialogPanel>
        </HeadlessTransitionChild>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>

  <slot name="activator" :on-click="toggle" />
</template>

<script lang="ts">
export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Dialog',
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: boolean
  initialFocus?: HTMLElement
  title?: string
  titleClass?: string
  closable?: boolean
  fullscreenOnMobile?: boolean
  zIndex?: number | string
  manualClose?: boolean
}>()

const emit = defineEmits([
  'update:modelValue',
  'close',
  'before-enter',
  'after-enter',
  'before-leave',
  'after-leave',
])

const isOpen = useVModel(props, 'modelValue', emit, { passive: true })

function close() {
  isOpen.value = false

  emit('close')
}

function toggle() {
  isOpen.value = !isOpen.value
}
</script>
