/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    verificationId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    username: string;
}

/**
 * Check if a given object implements the AuthRequest interface.
 */
export function instanceOfAuthRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "verificationId" in value;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function AuthRequestFromJSON(json: any): AuthRequest {
    return AuthRequestFromJSONTyped(json, false);
}

export function AuthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationId': json['verification_id'],
        'username': json['username'],
    };
}

export function AuthRequestToJSON(value?: AuthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verification_id': value.verificationId,
        'username': value.username,
    };
}

