/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliveryAddressRequest
 */
export interface DeliveryAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    address: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryAddressRequest
     */
    main?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryAddressRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    sessionKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    regionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    settlement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    house?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    block?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    apartment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    intercom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    entrance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    floor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    zipcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    dadata?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    fias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    cityFiasId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    lon?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    lat?: string | null;
    /**
     * IN_MKAD — внутри МКАД (Москва), OUT_MKAD — за МКАД (Москва и область), IN_KAD — внутри КАД (Санкт-Петербург), OUT_KAD — за КАД (Санкт-Петербург и область), пусто — в остальных случаях
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    beltwayHit?: string | null;
    /**
     * Заполнено, только если beltway_hit = OUT_MKAD или OUT_KAD, иначе пустое
     * @type {number}
     * @memberof DeliveryAddressRequest
     */
    beltwayDistance?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    stead?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryAddressRequest
     */
    steadType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryAddressRequest
     */
    isPrivateHouse?: boolean;
}

/**
 * Check if a given object implements the DeliveryAddressRequest interface.
 */
export function instanceOfDeliveryAddressRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function DeliveryAddressRequestFromJSON(json: any): DeliveryAddressRequest {
    return DeliveryAddressRequestFromJSONTyped(json, false);
}

export function DeliveryAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'main': !exists(json, 'main') ? undefined : json['main'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'sessionKey': !exists(json, 'session_key') ? undefined : json['session_key'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'settlement': !exists(json, 'settlement') ? undefined : json['settlement'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'house': !exists(json, 'house') ? undefined : json['house'],
        'block': !exists(json, 'block') ? undefined : json['block'],
        'apartment': !exists(json, 'apartment') ? undefined : json['apartment'],
        'intercom': !exists(json, 'intercom') ? undefined : json['intercom'],
        'entrance': !exists(json, 'entrance') ? undefined : json['entrance'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'dadata': !exists(json, 'dadata') ? undefined : json['dadata'],
        'fias': !exists(json, 'fias') ? undefined : json['fias'],
        'cityFiasId': !exists(json, 'city_fias_id') ? undefined : json['city_fias_id'],
        'lon': !exists(json, 'lon') ? undefined : json['lon'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'beltwayHit': !exists(json, 'beltway_hit') ? undefined : json['beltway_hit'],
        'beltwayDistance': !exists(json, 'beltway_distance') ? undefined : json['beltway_distance'],
        'stead': !exists(json, 'stead') ? undefined : json['stead'],
        'steadType': !exists(json, 'stead_type') ? undefined : json['stead_type'],
        'isPrivateHouse': !exists(json, 'is_private_house') ? undefined : json['is_private_house'],
    };
}

export function DeliveryAddressRequestToJSON(value?: DeliveryAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'main': value.main,
        'active': value.active,
        'session_key': value.sessionKey,
        'country': value.country,
        'region': value.region,
        'region_name': value.regionName,
        'city': value.city,
        'settlement': value.settlement,
        'street': value.street,
        'house': value.house,
        'block': value.block,
        'apartment': value.apartment,
        'intercom': value.intercom,
        'entrance': value.entrance,
        'floor': value.floor,
        'comment': value.comment,
        'zipcode': value.zipcode,
        'dadata': value.dadata,
        'fias': value.fias,
        'city_fias_id': value.cityFiasId,
        'lon': value.lon,
        'lat': value.lat,
        'beltway_hit': value.beltwayHit,
        'beltway_distance': value.beltwayDistance,
        'stead': value.stead,
        'stead_type': value.steadType,
        'is_private_house': value.isPrivateHouse,
    };
}

