/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBonus
 */
export interface GetBonus {
    /**
     * 
     * @type {number}
     * @memberof GetBonus
     */
    bonuses: number;
}

/**
 * Check if a given object implements the GetBonus interface.
 */
export function instanceOfGetBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bonuses" in value;

    return isInstance;
}

export function GetBonusFromJSON(json: any): GetBonus {
    return GetBonusFromJSONTyped(json, false);
}

export function GetBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bonuses': json['bonuses'],
    };
}

export function GetBonusToJSON(value?: GetBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bonuses': value.bonuses,
    };
}

