/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReviewRatingParams
 */
export interface ReviewRatingParams {
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    eatability: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    taste: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    packageQuality: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    petGameInvolvementRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    strengthRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    designRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    easeOfUseRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    materialQualityRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    materialFittingsQualityRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    petUseComfortRate: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewRatingParams
     */
    rating: number;
}

/**
 * Check if a given object implements the ReviewRatingParams interface.
 */
export function instanceOfReviewRatingParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eatability" in value;
    isInstance = isInstance && "taste" in value;
    isInstance = isInstance && "packageQuality" in value;
    isInstance = isInstance && "petGameInvolvementRate" in value;
    isInstance = isInstance && "strengthRate" in value;
    isInstance = isInstance && "designRate" in value;
    isInstance = isInstance && "easeOfUseRate" in value;
    isInstance = isInstance && "materialQualityRate" in value;
    isInstance = isInstance && "materialFittingsQualityRate" in value;
    isInstance = isInstance && "petUseComfortRate" in value;
    isInstance = isInstance && "rating" in value;

    return isInstance;
}

export function ReviewRatingParamsFromJSON(json: any): ReviewRatingParams {
    return ReviewRatingParamsFromJSONTyped(json, false);
}

export function ReviewRatingParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewRatingParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eatability': json['eatability'],
        'taste': json['taste'],
        'packageQuality': json['package_quality'],
        'petGameInvolvementRate': json['pet_game_involvement_rate'],
        'strengthRate': json['strength_rate'],
        'designRate': json['design_rate'],
        'easeOfUseRate': json['ease_of_use_rate'],
        'materialQualityRate': json['material_quality_rate'],
        'materialFittingsQualityRate': json['material_fittings_quality_rate'],
        'petUseComfortRate': json['pet_use_comfort_rate'],
        'rating': json['rating'],
    };
}

export function ReviewRatingParamsToJSON(value?: ReviewRatingParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eatability': value.eatability,
        'taste': value.taste,
        'package_quality': value.packageQuality,
        'pet_game_involvement_rate': value.petGameInvolvementRate,
        'strength_rate': value.strengthRate,
        'design_rate': value.designRate,
        'ease_of_use_rate': value.easeOfUseRate,
        'material_quality_rate': value.materialQualityRate,
        'material_fittings_quality_rate': value.materialFittingsQualityRate,
        'pet_use_comfort_rate': value.petUseComfortRate,
        'rating': value.rating,
    };
}

