/* tslint:disable */
/* eslint-disable */
/**
 * Valta API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneNumberRequest
 */
export interface PhoneNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberRequest
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberRequest
     */
    verificationId: string;
}

/**
 * Check if a given object implements the PhoneNumberRequest interface.
 */
export function instanceOfPhoneNumberRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "verificationId" in value;

    return isInstance;
}

export function PhoneNumberRequestFromJSON(json: any): PhoneNumberRequest {
    return PhoneNumberRequestFromJSONTyped(json, false);
}

export function PhoneNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': json['phone'],
        'verificationId': json['verification_id'],
    };
}

export function PhoneNumberRequestToJSON(value?: PhoneNumberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'verification_id': value.verificationId,
    };
}

